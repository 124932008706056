import { AnimatePresence } from 'framer-motion';
import { PablockSDK } from 'pablock-sdk';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
/* root imports */
import { useModal } from 'react-simple-modal-provider';
import updateAbi from '../../../../abi/update';
import { Form, InnerPage, Tooltip } from '../../../../components';
import { useValidation } from '../../../../hooks';
import { IconHistory, IconLoading } from '../../../../icons';
import {
  NFT_ATTRIBUTES,
  NFT_FUNCTIONALITIES,
  apiEndpoint,
  freeIpfsGateway,
  pablockApiKey,
  pablockConfig,
} from '../../../../imports/constants';
import type { UserState } from '../../../../imports/types';
import {
  capitalizeFirstLetter,
  delayed,
  formatIPFSUri,
  formatImage,
  urlToObject,
} from '../../../../imports/utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setUpdateRequestId } from '../../../../redux/operations/operations.slice';
/* tokenCreator imports */
import { useLoadingStatusContext } from '../../../../context';
import { useLoading } from '../../../../context/LoadingContext';
import { storeAttributedData } from '../../../../imports/storage';
import { getContract } from '../../api';
import { uploadNftsIpfs } from '../../api/ipfs';
import { NftHistoryModal, UpdateNftsStep } from '../../components';
import type { Contract, Nft } from '../../imports/types';
import { isUrl, nftsAreEqual } from '../../imports/utils';
import { bcodeSDK } from '../../../../imports/bcodeSDK';

const UpdateCollection = () => {
  const { t: generalT } = useTranslation();
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { required, validateFileImage } = useValidation();
  const [nfts, setNfts] = useState<Contract['nfts']>([]);
  const { dispatch: loadingContext, state } = useLoadingStatusContext();
  const { open: openLoadingModal } = useModal('LoadingModal');
  const dispatch = useAppDispatch();
  const { workspace } = useAppSelector((state) => state.team);
  const [contract, setContract] = useState<Contract | undefined>();
  const { id: contractId, tokenId } = useParams();
  const { isLoading, setLoading } = useLoading();
  const {
    wallet: { privateKey },
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const contractForm = {
    initialValues: {
      nfts,
    },
    validationSchema: yup.object({
      nfts: yup.array().of(
        yup.object().shape({
          name: required(yup.string()),
          description: required(yup.string()),
          quantity: required(yup.number().min(0)),
          tags: yup.array().of(yup.string()).max(3),
          external_url: yup.string(),
          isUnlimited: required(yup.boolean()),
          image: yup.array().of(validateFileImage()).min(1, generalT('form_validation.required')),
          // attributes: yup
          //   .array()
          //   .of(
          //     yup
          //       .object()
          //       .shape({ trait_type: required(yup.string()), value: required(yup.string()) })
          //   ),
          attributes: yup.array().of(
            yup.object().shape({
              private: yup.boolean(),
              trait_type: required(yup.string()),
              type: required(yup.string()),
              file: yup.array(),
              // value: yup.string().when(['file', 'type'], {
              //   is: (file: any, type: string) =>
              //     !file?.[0]?.name && ['text', 'partner'].includes(type),
              //   then: yup.string().when('type', {
              //     is: 'partner',
              //     then: yup
              //       .string()
              //       .test(
              //         'check-non-empty',
              //         t('form_validation.required'),
              //         (url) => !!url && isUrl(url)
              //       ),
              //     otherwise: yup.string().required(t('form_validation.required')),
              //   }),
              // }),
              // value: yup.mixed().when(['file', 'type'], {
              //   is: (file: any, type: string) =>
              //     !file?.[0]?.name && ['text', 'partner'].includes(type),
              //   then: yup.lazy((value: any) => {
              //     // if (value && typeof value === 'object') {
              //     //    return yup.object();
              //     // }
              //     if (Array.isArray(value) && value.length > 0) {
              //       return yup.array().of(yup.object()).required(t('form_validation.required'));
              //     }

              //     return yup.string().when('type', {
              //       is: 'partner',
              //       then: yup
              //         .string()
              //         .test(
              //           'check-non-empty',
              //           t('form_validation.required'),
              //           (url) => !!url && isUrl(url)
              //         ),
              //       otherwise: yup.string().required(t('form_validation.required')),
              //     });
              //   }),
              // }),
              value: yup.mixed().when(['file', 'type'], {
                is: (file: any, type: string) =>
                  !file?.[0]?.name && ['text', 'partner', 'notarizations'].includes(type),
                then: yup.lazy((value: any) => {
                  if (Array.isArray(value) && value.length > 0) {
                    return yup.array().min(1, t('form_validation.required'));
                  }
                  return yup.string().when('type', {
                    is: 'partner',
                    then: yup
                      .string()
                      .test(
                        'check-non-empty',
                        t('form_validation.required'),
                        (url) => !!url && isUrl(url)
                      ),
                    otherwise: yup.string().required(t('form_validation.required')),
                  });
                }),
              }),
              link: yup
                .string()
                .ensure()
                .when(['file', 'type'], {
                  is: (file: any, type: string) => !file?.[0]?.name && type === 'partner',
                  then: yup
                    .string()
                    .test(
                      'check-non-empty',
                      generalT('form_validation.required'),
                      (url) => !!url && isUrl(url)
                    ),
                }),
            })
          ),
        })
      ),
    }),
  };

  const handleLoadingModal = () => {
    openLoadingModal({ goTo: navigate, type: 'contract' });
  };

  //TODO: need to optimize and update only the db when the changes are only on private attributes
  const handleUpdate: SubmitHandler<typeof contractForm.initialValues> = async (values) => {
    const oldNfts = nfts;
    let newNfts = values.nfts;

    // Check if there are some changes and saves the ids that changes
    const modifiedNftIds = newNfts.reduce((acc: number[], newNft, i) => {
      // Check if are equal
      const equal = nftsAreEqual(newNft, oldNfts[i]);

      // If they are not equal, push the id to the accumulator array
      if (!equal) {
        acc.push(newNft.id);
      }

      return acc;
    }, []); // Initial value of the accumulator is an empty array

    if (modifiedNftIds.length <= 0) {
      toast.warn(t('update.nothing_updated') as string);
      return;
    }

    if (!contract) {
      toast.warn(t('update.nothing_updated') as string);
      return;
    }

    try {
      loadingContext({
        type: 'SET_PENDING',
        payload: {
          title: t('update.pending'),
        },
      });

      handleLoadingModal();

      if (workspace?.id && contract) {
        newNfts = await Promise.all(
          storeAttributedData(
            newNfts,
            { name: contract?.name, contractId: contract?.id },
            workspace?.id
          )
        );
      }

      /**
       * Upload token metadata and image to IPFS, but only the public nft attributes
       */

      const newNftsVar = newNfts.map((nft: any) => ({
        ...nft,
        attributes: nft.attributes?.filter(
          (attribute: any) => !attribute.private && NFT_ATTRIBUTES.includes(attribute.type)
        ),
      }));
      const { metadataCid, imagesCid } = await uploadNftsIpfs(
        newNftsVar,
        contract!.id,
        workspace?.id || ''
      );

      // Get the updated data of the nfts that has changed
      const modifiedNfts = newNfts.filter((newNft) => modifiedNftIds.includes(newNft.id));

      const modifiedNftsWithNewMetadata = modifiedNfts.map((nft, index) => {
        const imageName = formatImage((nft.image as File[]).at(0)?.name ?? '');
        const oldNft = oldNfts.find((oldNft) => oldNft.id === nft.id);
        if (oldNft == null) {
          throw new Error('No old nft found');
        }

        return {
          ...nft,
          image: `${freeIpfsGateway}/${imagesCid}/${imageName}`,
        };
      });

      try {
        if (!bcodeSDK.isInitialized()) {
          await bcodeSDK.init();
        }

        bcodeSDK.setPrivateKey(privateKey);

        const metaTx = await bcodeSDK.prepareTransaction(
          {
            address: contract!.address,
            abi: updateAbi,
            name: capitalizeFirstLetter(contract!.name).replace(/\s/g, ''),
            version: '1.0',
          },
          'updateUri',
          [`${freeIpfsGateway}/${metadataCid}/`]
        );

        const requestId: string = await bcodeSDK.executeAsyncTransaction(metaTx, {
          webhookUrl: `${apiEndpoint}/updateFunctionReceipt`,
          verbose: true,
          metadata: {
            contractId: contract!.id,
            newMetadataUri: metadataCid,
            nfts: modifiedNftsWithNewMetadata,
          },
        });

        dispatch(setUpdateRequestId(requestId));
        // dispatch(updateContract());
      } catch (e) {
        console.log(e);
      }

      loadingContext({
        type: 'SET_SUCCESS',
        payload: {
          title: t('update.success'),
        },
      });
    } catch (err) {
      console.error(err);
      loadingContext({
        type: 'SET_ERROR',
        payload: {
          title: t('update.failed'),
        },
      });
      delayed(() => {
        navigate(`/nft/collection/${contractId}`);
        loadingContext({
          type: 'RESET_STATE',
        });
      }, 2500);
    }
  };

  const renderHistory = (nft: Nft): JSX.Element => {
    const [history, setHistory] = useState(false);
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const listener = (event: any) => {
        if (!container.current?.contains(event.target)) {
          setHistory(false);
        }
      };

      window.addEventListener('click', listener);

      return () => window.removeEventListener('click', listener);
    }, []);
    return (
      <div className="relative" ref={container}>
        <AnimatePresence>
          {history && <NftHistoryModal nft={nft} contract={contract!} />}
        </AnimatePresence>

        <Tooltip content={t('update.history.tooltip')}>
          <IconHistory
            width={24}
            height={24}
            className="cursor-pointer fill-secondary-500"
            onClick={() => setHistory(!history)}
          />
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    setLoading({ loading: true });
    (async () => {
      try {
        if (contractId) {
          const contract = await getContract(contractId);
          setContract(contract);
          if (tokenId) {
            const nft: Nft | undefined = contract.nfts.find(
              (nft) => nft.id === parseInt(tokenId, 10)
            );
            if (nft) {
              const image = nft?.image as string;
              const name = image.split('/').at(-1);
              const imageBlob = await urlToObject(formatIPFSUri(image), name);
              const nftWithBlob = {
                ...nft,
                image: [imageBlob],
              };
              setNfts([nftWithBlob]);
            }
          } else {
            const nfts: Nft[] = await Promise.all(
              contract.nfts.map(async (nft: Nft) => {
                const image = nft.image as string;
                const name = image.split('/').at(-1);
                const imageBlob = await urlToObject(formatIPFSUri(image), name);
                return {
                  ...nft,
                  image: [imageBlob],
                };
              })
            );
            setNfts([...nfts.sort((a, b) => a.id - b.id)]);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading({ loading: false });
      }
    })();
  }, []);

  return (
    <InnerPage>
      {isLoading ? (
        <div className="flex h-screen grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : nfts.length ? (
        <Form
          validationSchema={contractForm.validationSchema}
          initialValues={contractForm.initialValues}
        >
          {({
            errors,
            handleSubmit,
            watch,
            resetField,
            fields,
            control,
            register,
            setValue,
            dirtyFields,
          }) => {
            const values = watch();

            return (
              <UpdateNftsStep
                title={t('update.title')}
                ctaLabel={t('update.title')}
                errors={errors}
                handleSubmit={handleSubmit}
                resetField={resetField}
                values={values}
                control={control}
                register={register}
                setValue={setValue}
                onBack={() => navigate(-1)}
                isLoading={false}
                watch={watch}
                action={() => handleUpdate(values)}
                topBarElements={renderHistory}
                quantityModifiable={false}
                dirtyFields={dirtyFields}
                fields={fields}
                contract={contract}
                tokenId={tokenId}
              />
            );
          }}
        </Form>
      ) : (
        ''
      )}
    </InnerPage>
  );
};

export default UpdateCollection;
