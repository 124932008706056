import { ReactNode, useEffect, useState, BaseSyntheticEvent } from 'react';
import { UseFormRegisterReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  CustomTooltip,
  Field,
  FileUploader,
  Icon,
  Input,
  TagInput,
  Textarea,
  TextareaQuill,
  Typography,
} from '../../../../components';
import { ExportedProps } from '../../../../components/form/Form';
import { IconClose, IconCopy, IconTrash } from '../../../../icons';
import { getRemoteConfigValue } from '../../../../imports/remoteConfig';
import { renameFile } from '../../../../imports/utils';
import { Nft, Template } from '../../imports/types';
import NftPreview from './NftPreview';
import CustomFields from './utils/CustomFields';
import { useAppSelector } from '../../../../redux/hooks';
import i18n from '../../../../i18n';

interface INftsStep
  extends Omit<ExportedProps, 'trigger' | 'reset' | 'getValues' | 'clearErrors' | 'isValid'> {
  isLoading: boolean;
  watch: any;
  contractsLeft: number;
  values: any;
  onBack: () => void;
  topBarElements?: (nft: Nft) => ReactNode;
  quantityModifiable?: boolean;
  title: string;
  ctaLabel: string;
  templates?: Template[];
  onCompleted: () => void;
  toggleIsBillingOverlayOpen: () => void;
}

interface INftComponentProps
  extends Omit<
    INftsStep,
    | 'title'
    | 'onCompleted'
    | 'isLoading'
    | 'ctaLabel'
    | 'toggleIsBillingOverlayOpen'
    | 'handleSubmit'
    | 'onBack'
    | 'contractsLeft'
  > {
  nft: any;
  index: number;
  values: any;
  doesExpire: boolean;
  // handleDuplicate: (category: any, howMany: any) => void;
}

const NftComponent: React.FC<INftComponentProps> = ({
  nft,
  index,
  register,
  setValue,
  values,
  watch,
  control,
  templates,
  quantityModifiable,
  errors,
  resetField,
  dirtyFields,
  doesExpire,
  // handleDuplicate,
}) => {
  const {
    doesTransfer,
    expiration,
    maxTokensPerUser,
    dropDate,
    chain,
    nfts,
    numberOfCategories,
    // template,
    hasGame,
  } = values;
  const template = useAppSelector((state) => state.template);
  const language = i18n.language;
  const { t } = useTranslation(['tokenCreator']);
  const [isImageValid, setIsImageValid] = useState<boolean>(true);
  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');
  const templateSelected = templates?.find((temp) => temp.name === template.name);
  const [image, setImage] = useState<File[]>([]);

  const isFileTypeImage = ({ type }: File) => {
    const [key, value] = type.split('/');
    const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');

    if (allowedFileType[key + '/*']) {
      return allowedFileType[key + '/*'].includes('.' + value);
    }
    return false;
  };

  /* THIS CAUSE PROBLEM TO SAVE IMAGE WHEN WE CHANGE PAGE AND COME BACK  */
  useEffect(() => {
    setValue(`nfts[${index}].image`, image);
  }, [image, setValue, index]);

  const { fields: field } = useFieldArray({
    control,
    name: `nfts`,
  });

  const { id, ...fieldsToRegister } = nft as Nft;

  const nftFields: { [key: string]: UseFormRegisterReturn } = Object.keys(fieldsToRegister).reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: register(`nfts[${index}].${currentValue}`),
    }),
    {}
  );

  const handleDeleteCategory = (index: number) => {
    if (nfts.length > 1) {
      const updatedNfts = nfts.filter((_: any, i: number) => i !== index);

      updatedNfts.forEach((updatedNft: { [x: string]: any }, i: any) => {
        Object.keys(fieldsToRegister).forEach((field) => {
          setValue(`nfts[${i}].${field}`, updatedNft[field]);
          setImage(updatedNft.image);
        });
      });

      setValue('nfts', updatedNfts);
      setValue('numberOfCategories', numberOfCategories - 1);
    }
  };

  const handleDuplicateCategory = (category: any, howMany: any) => {
    const newNfts = nfts;
    for (let i = 0; i < howMany; i += 1) {
      newNfts.push({ ...category });
    }
    setValue('nfts', newNfts);
    setValue('numberOfCategories', numberOfCategories + parseInt(howMany, 10));
  };
  const { open: openModalDuplicate } = useModal('DuplicateCategoryModal');
  const handleOpenDuplicateCategory = (index: number) => {
    openModalDuplicate({ category: nfts[index], duplicate: handleDuplicateCategory });
  };
  const handleFileChange = (files: File[]) => {
    setImage(files);
    files.forEach((file) => {
      if (isFileTypeImage(file)) {
        setValue(
          `nfts[${index}].image`,
          image.map((img) => renameFile(img, img.name.replace(' ', '_')))
        );
        setIsImageValid(true);
      } else {
        setValue(`nfts[${index}].image`, []);
        setIsImageValid(false);
        toast.error(`${t('create_contract.errors.file_format')}`);
      }
    });
  };

  return (
    <>
      <Field
        label={`${template.labels?.category[language] || t('category')}
             ${index + 1}`}
        labelElementRight={
          <CustomTooltip>
            <Typography size="xs">{t('create_contract.preview_notes')}</Typography>
          </CustomTooltip>
        }
        className="mb-6"
        error={errors.numberOfCategories?.message}
      >
        <div
          id={`container-${index}`}
          className="border-grey2 grid max-h-[1400px] grid-cols-2 gap-y-2 rounded-md border p-4"
        >
          {/* left column */}
          <div className="mx-auto max-h-[1400px] w-4/5" id={`left-${index}`}>
            <NftPreview
              index={index}
              nft={nft}
              nfts={nfts}
              values={values}
              doesTransfer={doesTransfer}
              maxTokensPerUser={maxTokensPerUser}
              chain={chain}
              doesExpire={doesExpire}
              expiration={expiration}
              dropDate={dropDate}
              hasGame={hasGame}
            />
          </div>
          {/* right column */}
          <div>
            <div className="flex w-full justify-end gap-4 pb-2 pr-2">
              {!nfts[index].isUnlimited && (
                <Icon
                  onClick={() => handleOpenDuplicateCategory(index)}
                  icon={IconCopy}
                  size="md"
                  className="cursor-pointer"
                  stroke="primary-500"
                />
              )}
              {nfts.length > 1 && (
                <div className="flex justify-end gap-4">
                  <Icon
                    onClick={() => {
                      handleDeleteCategory(index);
                    }}
                    icon={IconTrash}
                    size="md"
                    className="cursor-pointer"
                    stroke="primary-500"
                  />
                </div>
              )}
            </div>
            <div className="max-h-[1300px] overflow-auto" key={id} id={`right-${index}`}>
              <div className={`${nfts.length > 1 ? '' : 'mt-7'} space-y-6 p-2`}>
                {!template.fields?.tokenImage?.hidden && (
                  <Field
                    asterisk
                    label={
                      template.fields?.tokenImage?.label?.[language] ||
                      t('create_contract.form_labels.nft_image_title')
                    }
                    error={errors.nfts?.[index]?.image?.message}
                    labelElementRight={
                      <div className="flex items-center space-x-2">
                        <CustomTooltip>
                          <Typography size="xs">
                            {t('create_contract.form_tooltips.nft_image')}
                          </Typography>
                        </CustomTooltip>
                      </div>
                    }
                  >
                    <FileUploader
                      id={index}
                      label={
                        template.fields?.tokenImageUploader?.label?.[language] ||
                        t('create_contract.form_labels.nft_image_label')
                      }
                      accept={allowedFileType}
                      defaultFiles={nfts?.[index]?.image}
                      maxSize={5000000}
                      ratio="1:1"
                      // onChange={(files) => {
                      //   files.forEach((file) => {
                      //     if (isFileTypeImage(file)) {
                      //       setValue(
                      //         `nfts[${index}].image`,
                      //         files.map((file) => renameFile(file, file.name.replace(' ', '_')))
                      //       );
                      //       setIsImageValid(true);
                      //     } else {
                      //       setValue(`nfts[${index}].image`, []);
                      //       setIsImageValid(false);
                      //       toast.error(`${t('create_contract.errors.file_format')}`);
                      //     }
                      //   });
                      // }}
                      onChange={handleFileChange}
                      onCancel={() => setValue(`nfts[${index}].image`, [])}
                    />
                  </Field>
                )}
                {!template.fields?.tokenName?.hidden && (
                  <Field
                    asterisk
                    label={
                      template.fields?.tokenName?.label?.[language] ||
                      t('create_contract.form_labels.nft_name')
                    }
                    error={errors.nfts?.[index]?.name?.message}
                    labelElementRight={
                      <div className="flex items-center space-x-2">
                        <CustomTooltip>
                          <Typography size="xs">
                            {t('create_contract.form_tooltips.nft_name')}
                          </Typography>
                        </CustomTooltip>
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      placeholder={t('create_contract.form_placeholders.nft_name')}
                      name={nftFields.name.name}
                      onBlur={nftFields.name.onBlur}
                      onChange={nftFields.name.onChange}
                      inputRef={nftFields.name.ref}
                      error={errors.nfts?.[index]?.name?.message}
                      elementRight={
                        <Button
                          type="ghost"
                          icon={IconClose}
                          action={() => resetField(nftFields.name.name)}
                        />
                      }
                    />
                  </Field>
                )}
                {!template.fields?.tokenDescription?.hidden && (
                  <Field
                    asterisk
                    className="h-fit"
                    label={
                      template.fields?.tokenDescription?.label?.[language] ||
                      t('create_contract.form_labels.nft_description')
                    }
                    error={errors.nfts?.[index]?.description?.message}
                    labelElementRight={
                      <div className="flex items-center space-x-2">
                        <CustomTooltip>
                          <Typography size="xs">
                            {t('create_contract.form_tooltips.nft_description')}
                          </Typography>
                        </CustomTooltip>
                      </div>
                    }
                  >
                    <TextareaQuill
                      setValue={setValue}
                      field={`nfts[${index}].description`}
                      value={values.nfts[index].description}
                    />
                  </Field>
                )}
                {!template.fields?.isUnlimited?.hidden && (
                  <Field>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <Typography>
                          {t('create_contract.form_labels.unlimited') as string}
                        </Typography>
                        <CustomTooltip>
                          <Typography size="xs">
                            {t('create_contract.form_tooltips.unlimited')}
                          </Typography>
                        </CustomTooltip>
                      </div>
                      <Checkbox
                        onChange={(e) => {
                          const event = e as BaseSyntheticEvent;
                          const checked = event.target.checked as boolean;
                          setValue(`nfts[${index}].isUnlimited`, checked);
                          if (checked) {
                            setValue('qrCodeDropVal', 2);
                          } else {
                            setValue('qrCodeDropVal', 1);
                          }
                        }}
                        name={nftFields.isUnlimited?.name}
                        onBlur={nftFields.isUnlimited?.onBlur}
                        inputRef={nftFields.isUnlimited?.ref}
                        error={errors.nfts?.[index]?.isUnlimited?.message}
                        disabled={index + 1 !== nfts.length}
                        isChecked={nfts[index].isUnlimited}
                      />
                    </div>
                  </Field>
                )}
                {!template.fields?.tokenQuantity?.hidden && (
                  <Field
                    label={
                      template.fields?.tokenQuantity?.label?.[language] ||
                      t('create_contract.form_labels.nft_quantity')
                    }
                    labelElementRight={
                      <CustomTooltip>
                        <Typography size="xs">
                          {t('create_contract.form_tooltips.nft_quantity') as string}
                        </Typography>
                      </CustomTooltip>
                    }
                    error={errors.quantity?.message}
                  >
                    {nfts[index].isUnlimited ? (
                      <Typography size="xs">{t('nft.unlimited') as string}</Typography>
                    ) : (
                      <Input
                        type="number"
                        minValue="1"
                        placeholder={t('create_contract.form_placeholders.quantity')}
                        name={nftFields.quantity.name}
                        onBlur={nftFields.quantity.onBlur}
                        onChange={nftFields.quantity.onChange}
                        inputRef={nftFields.quantity.ref}
                        error={errors.nfts?.[index]?.quantity?.message}
                        onWheel={nftFields.quantity.onBlur}
                        disabled={
                          !quantityModifiable ||
                          (index + 1 === nfts.length && nfts[index].isUnlimited)
                        }
                      />
                    )}
                  </Field>
                )}
                {!template.fields?.tags?.hidden && (
                  <Field
                    error={errors.nfts?.[index]?.tags?.message}
                    label={t('create_contract.tags')}
                    labelElementRight={
                      <CustomTooltip>
                        <Typography size="xs">
                          {t('create_contract.form_tooltips.nft_tags') as string}
                        </Typography>
                      </CustomTooltip>
                    }
                  >
                    <TagInput
                      type="text"
                      placeholder={t('create_contract.form_placeholders.insert_tags')}
                      id={nftFields?.tags?.name}
                      name={nftFields?.tags?.name}
                      onBlur={nftFields?.tags?.onBlur}
                      onTagsChange={(allTags) => setValue(nftFields?.tags?.name, allTags)}
                      error={errors.nfts?.[index]?.tags?.message}
                      className="mt-[5px]"
                      tags={values.nfts[index]?.tags}
                      maxTags={3}
                    />
                  </Field>
                )}

                {!template.fields?.tokenExternalLink?.hidden && (
                  <Field
                    label={
                      template.fields?.tokenExternalLink?.label?.[language] ||
                      t('create_contract.form_labels.nft_external_link')
                    }
                    error={errors.nfts?.[index]?.external_url?.message}
                    labelElementRight={
                      <div className="flex items-center space-x-2">
                        <CustomTooltip>
                          <Typography size="xs">
                            {t('create_contract.form_tooltips.nft_external_link')}
                          </Typography>
                        </CustomTooltip>
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      placeholder={t('create_contract.form_placeholders.nft_external_link')}
                      name={nftFields.external_url.name}
                      onBlur={nftFields.external_url.onBlur}
                      onChange={nftFields.external_url.onChange}
                      inputRef={nftFields.external_url.ref}
                      error={errors.nfts?.[index]?.external_url?.message}
                      elementRight={
                        <Button
                          type="ghost"
                          icon={IconClose}
                          action={() => resetField(nftFields.external_url.name)}
                        />
                      }
                    />
                  </Field>
                )}
                {!template.fields?.customFields?.hidden && (
                  <div className="mt-2">
                    <CustomFields
                      // customLabels={templateSelected?.prefilledCustomFields}
                      category={index}
                      values={values}
                      control={control}
                      errors={errors}
                      resetField={resetField}
                      register={register}
                      dirtyFields={dirtyFields}
                      fields={field[index]}
                      setValue={setValue}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Field>
    </>
  );
};

export default NftComponent;
