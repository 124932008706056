import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/* root imports */
import { compare, compareByDate, filterCollection } from '../../../../imports/utils';

import { IconLoading } from '../../../../icons';

import { AuditCard, Typography } from '../../../../components';

import type { Filters } from '../../../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';

/* notarizations imports */

type AuditListProps = {
  type: 'pending' | 'completed' | 'unverified' | 'verified';
  // audits: (Notarization & Receipt)[];
  audits: any[];
  notarizations: any[];
  receipts: any[];
  isLoading: boolean;
  filtersState: {
    [key: string]: {
      filter: Filters;
      value: any;
    };
  };
  orderBy: {
    type: string;
    direction: string;
  };
  notarized?: boolean;
};
const AuditList = ({
  type,
  audits,
  notarizations,
  receipts,
  isLoading,
  filtersState,
  orderBy,
  notarized,
}: AuditListProps) => {
  const { t } = useTranslation(['audit']);
  const { workspace } = useAppSelector((user) => user.team);

  const { inputFilterByName, inputFilterByTag, inputFilterByDate } = filtersState;
  const filteredNotarizations = useMemo(() => {
    const filteredCollection = filterCollection({
      filtersInOrder: [
        {
          type: inputFilterByName.filter,
          propertyToFilter: ['name', 'txHash', 'folderName'],
          filterValue: inputFilterByName.value,
        },
        {
          type: inputFilterByTag.filter,
          propertyToFilter: 'tags',
          filterValue: inputFilterByTag.value,
        },
        {
          type: inputFilterByDate.filter,
          propertyToFilter: 'date',
          filterValue: inputFilterByDate.value,
        },
      ],
      collection: audits,
    });

    if (orderBy) {
      if (orderBy.type === 'date') {
        return filteredCollection.sort((a, b) => compareByDate(a.date, b.date, orderBy.direction));
      }

      if (orderBy.type === 'name') {
        return filteredCollection.sort((a, b) => compare(a, b, 'name', orderBy.direction));
      }
    }

    return filteredCollection;
  }, [audits, inputFilterByName, inputFilterByTag, inputFilterByDate, orderBy]);

  return !isLoading ? (
    <>
      <div className="my-4 flex">
        <Typography size="body-medium-20">
          {type === 'completed' && t('collection.audit_completed')}
          {type === 'pending' && t('collection.audit_in_progress')}
          {type === 'verified' && t('create_audit.notarizations_verified')}
          {type === 'unverified' && t('create_audit.notarizations_unverified')}
        </Typography>
      </div>
      <div className="flex flex-wrap gap-5">
        {filteredNotarizations.length > 0 ? (
          filteredNotarizations.map((item) => (
            <AuditCard
              key={item.auditId}
              audit={item}
              notarizations={notarizations}
              receipts={receipts}
              hasWritePermission={workspace?.permissions?.audit?.write}
              notarized={notarized}
            />
          ))
        ) : (
          <div className="col-span-full mt-6 text-center">
            <Typography>
              {audits.length === 0
                ? type === 'pending'
                  ? t('collection.no_audit_draft_found')
                  : t('collection.no_audit_notarized_found')
                : type === 'completed'
                  ? t('collection.no_filtered_audit_draft_found')
                  : t('collection.no_filtered_audit_notarized_found')}
            </Typography>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="flex grow items-center justify-center">
      <IconLoading className="size-12 animate-spin text-primary-500" />
    </div>
  );
};

export default AuditList;
