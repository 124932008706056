import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { collection, getDocs, query, where } from 'firebase/firestore';

import { isEmpty } from 'lodash';
import { useModal } from 'react-simple-modal-provider';
import { useCopyToClipboard, useValidation } from '../../hooks';

import {
  bcodeConsentUrl,
  currentEnv,
  environments,
  privacyCheckUrl,
  SECURITY_QUESTIONS,
} from '../../imports/constants';
import { createWallet, createWalletFromSeedPhrase, getAuthErrorMessage } from '../../imports/utils';

import {
  Button,
  Checkbox,
  Chip,
  Field,
  Form,
  Icon,
  Input,
  InputMnemonicPhrase,
  SelectDropdown,
  Sidebar,
  Tooltip,
  Typography,
} from '../../components';

import { signUp } from '../../api/firebase';
import type { ExportedProps } from '../../components/form/Form';
import { IconCopy, IconInvisible, IconVisible } from '../../icons';
import { SignupFormProps } from '../../imports/types';
import { db } from '../../imports/firebase';
import { acceptInvitation } from '../../modules/team/api/api';

const initialState = {
  showInputPassword: false,
  showInputRepeatPassword: false,
  showInputMnemonicPhrase: false,
};

enum Steps {
  ACCOUNT,
  PASSWORD,
  BACKUP,
  WALLET,
}

type ActionType = { type: 'SET_STATE'; fieldName: string; payload: boolean };

const reducer = (state: typeof initialState, action: ActionType) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    default:
      return state;
  }
};

type AccountStepProps = Omit<
  ExportedProps,
  | 'handleSubmit'
  | 'setValue'
  | 'control'
  | 'register'
  | 'reset'
  | 'getValues'
  | 'watch'
  | 'clearErrors'
  | 'trigger'
  | 'isValid'
>;

type PasswordStepProps = Omit<
  ExportedProps,
  | 'handleSubmit'
  | 'setValue'
  | 'control'
  | 'register'
  | 'reset'
  | 'getValues'
  | 'clearErrors'
  //| 'watch'
  | 'trigger'
  | 'isValid'
> & {
  showInputPassword: boolean;
  showInputRepeatPassword: boolean;
  dispatchFieldsVisibility: (value: ActionType) => void;
};
type BackupStepProps = Omit<
  ExportedProps,
  | 'handleSubmit'
  | 'control'
  | 'register'
  | 'reset'
  | 'getValues'
  | 'trigger'
  | 'isValid'
  | 'clearErrors'
>;

type WalletStepProps = Omit<
  ExportedProps,
  | 'handleSubmit'
  | 'trigger'
  | 'control'
  | 'register'
  | 'resetField'
  | 'reset'
  | 'clearErrors'
  | 'getValues'
  | 'isValid'
> & {
  showInputMnemonicPhrase: boolean;
  dispatchFieldsVisibility: (value: ActionType) => void;
};

const AccountStep = ({ fields, errors }: AccountStepProps) => {
  const { t } = useTranslation();

  const isCurrentEnvDemo = currentEnv === environments.DEMO;

  return (
    <div className="mb-[60px] flex w-full flex-col gap-[20px]">
      <Field
        error={errors.company?.message}
        asterisk
        label={t('authentication.sign_up_labels.company')}
        textSize="body-regular-18"
      >
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.company')}
          id={fields.company.name}
          name={fields.company.name}
          onBlur={fields.company.onBlur}
          onChange={fields.company.onChange}
          inputRef={fields.company.ref}
          error={errors.company?.message}
          className="mt-[5px]"
          // disabled={isCurrentEnvDemo}
        />
      </Field>

      <Field
        error={errors.vat?.message}
        asterisk
        label={t('authentication.sign_up_labels.vat')}
        textSize="body-regular-18"
      >
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.vat_number')}
          id={fields.vat.name}
          name={fields.vat.name}
          onBlur={fields.vat.onBlur}
          onChange={fields.vat.onChange}
          inputRef={fields.vat.ref}
          error={errors.vat?.message}
          className="mt-[5px]"
          // disabled={isCurrentEnvDemo}
        />
      </Field>

      <Field
        error={errors.sdi?.message}
        asterisk
        label={t('authentication.sign_up_labels.sdi')}
        info
        infoText="Sistema di interscambio per la fattura elettronica."
        textSize="body-regular-18"
      >
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.sdi')}
          id={fields.sdi.name}
          name={fields.sdi.name}
          onBlur={fields.sdi.onBlur}
          onChange={fields.sdi.onChange}
          inputRef={fields.sdi.ref}
          error={errors.sdi?.message}
          className="mt-[5px]"
          // disabled={isCurrentEnvDemo}
        />
      </Field>

      <Field
        error={errors.office?.message}
        asterisk
        label={t('authentication.sign_up_labels.office')}
        textSize="body-regular-18"
      >
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.office')}
          id={fields.office.name}
          name={fields.office.name}
          onBlur={fields.office.onBlur}
          onChange={fields.office.onChange}
          inputRef={fields.office.ref}
          error={errors.office?.message}
          className="mt-[5px]"
          // disabled={isCurrentEnvDemo}
        />
      </Field>

      <Field error={errors.email?.message} asterisk label={t('email')} textSize="body-regular-18">
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.email')}
          id={fields.email.name}
          name={fields.email.name}
          onBlur={fields.email.onBlur}
          onChange={fields.email.onChange}
          inputRef={fields.email.ref}
          error={errors.email?.message}
          className="mt-[5px]"
        />
      </Field>

      <Field
        error={errors.confirmEmail?.message}
        asterisk
        label={t('authentication.sign_up_labels.repeat_email')}
        textSize="body-regular-18"
      >
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.confirm_email')}
          id={fields.confirmEmail.name}
          name={fields.confirmEmail.name}
          onBlur={fields.confirmEmail.onBlur}
          onChange={fields.confirmEmail.onChange}
          inputRef={fields.confirmEmail.ref}
          error={errors.confirmEmail?.message}
          className="mt-[5px]"
        />
      </Field>

      {isCurrentEnvDemo && (
        <>
          <Field error={errors.privacyCheck?.message}>
            <div className="flex space-x-4">
              <Checkbox
                id={fields.privacyCheck.name}
                name={fields.privacyCheck.name}
                onBlur={fields.privacyCheck.onBlur}
                onChange={fields.privacyCheck.onChange}
                inputRef={fields.privacyCheck.ref}
                error={errors.privacyCheck?.message}
              />
              <Typography size="xs" weight="medium" isLink>
                <a href={privacyCheckUrl} target="_blank" rel="noreferrer">
                  {t('authentication.form_placeholders.privacy_consent')}
                </a>
              </Typography>
            </div>
          </Field>

          <Field error={errors.bcodeConsent?.message}>
            <div className="flex space-x-4">
              <Checkbox
                id={fields.bcodeConsent.name}
                name={fields.bcodeConsent.name}
                onBlur={fields.bcodeConsent.onBlur}
                onChange={fields.bcodeConsent.onChange}
                inputRef={fields.bcodeConsent.ref}
                error={errors.bcodeConsent?.message}
              />
              <Typography size="xs" weight="medium" isLink>
                <a href={bcodeConsentUrl} target="_blank" rel="noreferrer">
                  {t('authentication.form_placeholders.bcode_consent')}
                </a>
              </Typography>
            </div>
          </Field>
        </>
      )}
    </div>
  );
};

const PasswordStep = ({
  fields,
  errors,
  dirtyFields,
  watch,
  showInputPassword,
  showInputRepeatPassword,
  dispatchFieldsVisibility,
}: PasswordStepProps) => {
  const { t } = useTranslation();
  const isCurrentEnvDemo = currentEnv === environments.DEMO;
  const values = watch();
  return (
    <div className="mb-[60px] flex w-full flex-col gap-[20px]">
      <Field
        asterisk
        label={t('authentication.sign_up_labels.password')}
        textSize="body-regular-18"
      >
        <Input
          type={showInputPassword ? 'text' : 'password'}
          placeholder={t('authentication.form_placeholders.password')}
          id={fields.password.name}
          name={fields.password.name}
          onBlur={fields.password.onBlur}
          onChange={fields.password.onChange}
          inputRef={fields.password.ref}
          error={errors.password?.message}
          className="mt-[5px]"
          elementRight={
            <Button
              className="max-h-5"
              type="ghost"
              icon={showInputPassword ? IconVisible : IconInvisible}
              action={() =>
                dispatchFieldsVisibility({
                  type: 'SET_STATE',
                  fieldName: 'showInputPassword',
                  payload: !showInputPassword,
                })
              }
            />
          }
        />
        {dirtyFields.password ? (
          errors.password?.message ? (
            <Typography className="mt-2" size="body-regular-12">
              {t('authentication.forgot.password_error')}
              <span className="text-error">{errors.password?.message}</span>
            </Typography>
          ) : (
            <Typography className="mt-2" size="body-regular-12">
              {t('authentication.forgot.password_ok_1')}
              <span className="text-success">{t('authentication.forgot.password_ok_2')}</span>
            </Typography>
          )
        ) : (
          ''
        )}
      </Field>

      <Field
        // error={errors.repeatPassword?.message}
        // error={
        //   values.repeatPassword !== values.password
        //     ? 'errors.repeatPassword.message DIVERSE'
        //     : 'UGUALI'
        // }
        error={
          values.repeatPassword !== '' && values.repeatPassword !== values.password
            ? t('form_validation.dont_match')
            : ''
        }
        asterisk
        label={t('authentication.form_placeholders.repeat_password')}
        textSize="body-regular-18"
      >
        <Input
          type={showInputRepeatPassword ? 'text' : 'password'}
          placeholder={t('authentication.form_placeholders.repeat_the_password')}
          id={fields.repeatPassword.name}
          name={fields.repeatPassword.name}
          onBlur={fields.repeatPassword.onBlur}
          onChange={fields.repeatPassword.onChange}
          inputRef={fields.repeatPassword.ref}
          //error={errors.repeatPassword?.message}
          error={
            values.repeatPassword !== values.password
              ? 'errors.repeatPassword.message DIVERSE'
              : 'UGUALI'
          }
          className="mt-[5px]"
          elementRight={
            <Button
              className="max-h-5"
              type="ghost"
              icon={showInputRepeatPassword ? IconVisible : IconInvisible}
              action={() =>
                dispatchFieldsVisibility({
                  type: 'SET_STATE',
                  fieldName: 'showInputRepeatPassword',
                  payload: !showInputRepeatPassword,
                })
              }
            />
          }
        />
      </Field>
    </div>
  );
};

const BackupStep = ({ fields, errors, dirtyFields, setValue, watch }: BackupStepProps) => {
  const values = watch();
  const { t } = useTranslation();
  const isCurrentEnvDemo = currentEnv === environments.DEMO;
  const [currentQuestion, setCurrentQuestion] = useState<any>('');
  const handleQuestion = (options: any) => {
    const question = SECURITY_QUESTIONS.find((question) => question.value === options);
    setCurrentQuestion(question?.label);
    setValue('question', options);
  };
  useEffect(() => {
    handleQuestion(values.question);
  }, []);

  return (
    <div className="mb-[60px] flex w-full flex-col gap-[20px]">
      <Field
        error={errors.question?.message}
        asterisk
        label={t('authentication.sign_up_labels.secret_question')}
        textSize="body-regular-18"
        info
        infoText={t('authentication.sign_up_labels.backup_question')}
      >
        <SelectDropdown
          id={fields.question.name}
          items={SECURITY_QUESTIONS.map(({ value, label }) => ({ value, label: t(label) }))}
          // onChange={(options) => setValue('question', options)}
          onChange={(options) => handleQuestion(options)}
          name={fields.question.name}
          className={`mt-[5px] ${values.question ? '!text-grey-500' : ''}`}
          placeholder={t(currentQuestion) || t('authentication.form_placeholders.select')}
          error={errors.question?.message}
        />
      </Field>

      <Field
        error={errors.answer?.message}
        asterisk
        label={t('answer')}
        textSize="body-regular-18"
        info
        infoText={t('authentication.forgot.info_recover')}
      >
        <Input
          id={fields.answer.name}
          name={fields.answer.name}
          placeholder={t('authentication.form_placeholders.write_answer')}
          onBlur={fields.answer.onBlur}
          onChange={fields.answer.onChange}
          inputRef={fields.answer.ref}
          error={errors.answer?.message}
          className="mt-[5px]"

          // disabled={isCurrentEnvDemo}
        />
      </Field>
    </div>
  );
};
const WalletStep = ({
  fields,
  errors,
  watch,
  setValue,
  showInputMnemonicPhrase,
  dispatchFieldsVisibility,
  dirtyFields,
}: WalletStepProps) => {
  const { t } = useTranslation();
  const values = watch();
  const [stateCopyToClipboard, copyToClipboard] = useCopyToClipboard();

  const importWallet = watch('importWallet');
  const wallet = watch('wallet');
  const mnemonicPhrase = wallet?.mnemonic.phrase.split(' ');

  const handleImportWallet = async (mnemonicPhrase: string) => {
    const wallet = await createWalletFromSeedPhrase(mnemonicPhrase);

    setValue('wallet', wallet);
    setValue('backup', true);
  };

  const handleCopyToClipboard = (value: any, message: string) => {
    copyToClipboard(value);
    toast.success(message);
  };

  useEffect(() => {
    if (wallet) {
      setValue('wallet', null);
      setValue('backup', true);
    }

    if (!importWallet) {
      const wallet = createWallet();

      setValue('wallet', wallet);
      setValue('backup', true);
    }
    toast.warn(t('authentication.sign_up_labels.save_seed_remember') as string, {
      autoClose: 8000,
    });
  }, []);

  return (
    <>
      <div className="flex w-full flex-col gap-[60px]">
        <Typography size="body-regular-18" color="grey-600" className="w-full ">
          {!importWallet ? (
            <>
              {t('authentication.show_mnemonic_phrase_title.this_is_your_mnemonic_phrase')}
              <br />
              {t('authentication.show_mnemonic_phrase_title.be_careful')}
            </>
          ) : (
            t('authentication.insert_mnemonic_phrase_title')
          )}
        </Typography>

        {!importWallet ? (
          <div
            className="ring-grey relative mb-[60px] flex h-[210px] w-[450px] items-center justify-center rounded-xl border border-primary-500 p-2"
            role="presentation"
            onClick={() => {
              if (!showInputMnemonicPhrase) {
                dispatchFieldsVisibility({
                  type: 'SET_STATE',
                  fieldName: 'showInputMnemonicPhrase',
                  payload: !showInputMnemonicPhrase,
                });
              }
            }}
          >
            {showInputMnemonicPhrase ? (
              <div className="">
                <div
                  className=" grid h-[108px] min-w-[321px] grid-cols-4 gap-2"
                  role="presentation"
                >
                  {mnemonicPhrase &&
                    mnemonicPhrase.map((word: string, i: number) => (
                      <Chip key={i}>{` ${word}`}</Chip>
                    ))}
                </div>
              </div>
            ) : (
              <Icon icon={IconInvisible} size="42" stroke="primary-500" />
            )}
            <Tooltip content="copia">
              <div className="absolute bottom-[22px] right-[19px] cursor-pointer">
                <Icon
                  icon={IconCopy}
                  size="24"
                  stroke="primary-500"
                  onClick={() =>
                    handleCopyToClipboard(
                      mnemonicPhrase.toString(),
                      t('authentication.success.copied_successfully', {
                        element: t('authentication.seedphrase'),
                      })
                    )
                  }
                />{' '}
              </div>
            </Tooltip>
          </div>
        ) : (
          <InputMnemonicPhrase
            onSuccess={handleImportWallet}
            placeholder={t('authentication.form_placeholders.seed_phrase')}
          />
        )}
      </div>
      <Field error={errors.seedSaved?.message} className="mb-[18px] w-full text-right  ">
        <div className="flex w-full justify-end gap-2 text-body-regular-12 text-grey-600">
          <Checkbox
            id={fields.seedSaved.name}
            name={fields.seedSaved.name}
            onBlur={fields.seedSaved.onBlur}
            onChange={fields.seedSaved.onChange}
            inputRef={fields.seedSaved.ref}
            error={errors.seedSaved?.message}
            isChecked={values.seedSaved}
            type="secondary"
            // disabled={isCurrentEnvDemo}
          />{' '}
          {t('authentication.sign_up_labels.seed_saved')}
        </div>
      </Field>
    </>
  );
};

const SignUp = () => {
  const { t } = useTranslation();
  const [fieldsVisibility, dispatchFieldsVisibility] = useReducer(reducer, initialState);
  const { showInputPassword, showInputRepeatPassword, showInputMnemonicPhrase } = fieldsVisibility;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(Steps.ACCOUNT);
  const { open } = useModal('TermsModal');

  const vat = decodeURIComponent(searchParams.get('vat') ?? '') || undefined;
  const sdi = decodeURIComponent(searchParams.get('sdi') ?? '') || undefined;
  const office = decodeURIComponent(searchParams.get('office') ?? '') || undefined;
  const company = decodeURIComponent(searchParams.get('company') ?? '') || undefined;
  const email = decodeURIComponent(searchParams.get('email') ?? '') || undefined;
  console.log('🚀 ~ SignUp ~ email:', email);
  const workspaceId = decodeURIComponent(searchParams.get('workspaceId') ?? '') || undefined;

  const invitationId = searchParams.get('invitationId') ?? undefined;

  const titles = [
    t('authentication.sign_up_labels.account_step'),
    t('authentication.sign_up_labels.password_step'),
    t('authentication.sign_up_labels.backup_step'),
    t('authentication.sign_up_labels.wallet_step'),
  ];
  const isCurrentEnvDemo = currentEnv === environments.DEMO;
  // const isAccountCreationStep = step === 0;

  const navigate = useNavigate();

  const {
    required,
    validateEmail,
    validateRepeatEmail,
    validatePassword,
    validateRepeatPassword,
    validateSeedPhrase,
    validateVat,
  } = useValidation();

  const signInForm = {
    initialValues: {
      company: isCurrentEnvDemo ? 'Demo Company' : '',
      vat: isCurrentEnvDemo ? 'Demo Vat' : '',
      sdi: isCurrentEnvDemo ? 'Demo Sdi' : '',
      office: isCurrentEnvDemo ? 'Demo Office' : '',
      email: '',
      confirmEmail: '',
      password: '',
      repeatPassword: '',
      importWallet: false,
      wallet: null,
      question: /* SECURITY_QUESTIONS[0].value */ '',
      answer: '',
      privacyCheck: false,
      bcodeConsent: false,
      backup: false,
      // tec1: false,
      // tec2: false,
      // tec3: false,
      // tec4: false,
      // tec5: false,
      seedSaved: false,
    },
    validationSchema: yup.object({
      company: required(yup.string()),
      //vat: required(yup.string()),
      vat: required(validateVat()),
      sdi: required(yup.string()),
      office: required(yup.string()),
      email: required(validateEmail()),
      confirmEmail: required(validateRepeatEmail()),
      password: required(validatePassword()),
      repeatPassword: required(validateRepeatPassword()),
      importWallet: yup.boolean(),
      wallet: !Steps.WALLET ? required(yup.mixed()) : yup.mixed(),
      question: required(yup.string()),
      answer: required(yup.string()),
      privacyCheck: isCurrentEnvDemo
        ? yup.boolean().oneOf([true], t('form_validation.required'))
        : yup.boolean(),
      bcodeConsent: isCurrentEnvDemo
        ? yup.boolean().oneOf([true], t('form_validation.required'))
        : yup.boolean(),
      backup: yup.boolean(),
      // tec1: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
      // tec2: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
      // tec3: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
      // tec4: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
      // tec5: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
      seedSaved: yup.boolean().oneOf([true], t('form_validation.need_save_seed')),
    }),
  };

  const { initialValues, validationSchema } = signInForm;

  const handleSignUp = async (values: SignupFormProps, setOpen: any) => {
    const {
      company,
      vat,
      sdi,
      office,
      email,
      password,
      wallet,
      question,
      answer,
      backup,
      tec1,
      tec2,
      tec3,
      tec4,
      tec5,
    } = values;

    setIsLoading(true);

    const { error } = await signUp({
      company,
      vat,
      sdi,
      office,
      email,
      password,
      wallet,
      question,
      answer,
      backup,
      tec1,
      tec2,
      tec3,
      tec4,
      tec5,
    });
    if (workspaceId && invitationId) {
      // NOTE: If is a user that is accepting an invitation that does not have a workspace it will accept the invitation
      await acceptInvitation(workspaceId, invitationId);
    }

    setIsLoading(false);

    if (error) {
      toast.error(t(`authentication.errors.${getAuthErrorMessage(error.code)}`) as string);
      return;
    }
    setOpen(false);

    setTimeout(() => {
      toast.success(
        `${t(`authentication.verify_email.check_your_email`)} ${t(
          'authentication.verify_email.click_the_link_sent'
        )}`
      );
      navigate('/sign-in');
    });
  };
  return (
    <>
      <Sidebar
        topElement="logo"
        stepperLabels={[
          t('authentication.sign_up_stepper.company_data'),
          t('password'),
          'Backup',
          'Wallet',
        ]}
        stepperCurrent={step}
        bottomElement="text"
      />
      <div className="absolute  flex flex-col justify-between gap-[60px] pl-[42%] pt-[18vh]">
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          className={`absolute mx-auto flex ${
            step === Steps.WALLET ? 'w-[600px]' : 'w-[450px]'
          } flex-col items-start justify-between pb-[10vh]`}
          mode="all"
        >
          {({
            fields,
            errors,
            handleSubmit,
            resetField,
            watch,
            setValue,
            dirtyFields,
            isValid,
          }) => {
            const values = watch();
            console.log('🚀 ~ SignUp ~ values:', values);
            useEffect(() => {
              const shouldSkipFirstStep =
                !isEmpty(company) &&
                !isEmpty(vat) &&
                !isEmpty(sdi) &&
                !isEmpty(office) &&
                !isEmpty(email);
              if (step === Steps.ACCOUNT && shouldSkipFirstStep) {
                setValue('company', company);
                setValue('vat', vat);
                setValue('sdi', sdi);
                setValue('office', office);
                setValue('email', email);
                setValue('confirmEmail', email);
                setStep(1);
              }
            }, [company, vat, sdi, office, email]);
            return (
              <>
                <Typography
                  size="body-semibold-30"
                  color="grey-600"
                  weight="semibold"
                  className="mb-[60px] text-left"
                >
                  {titles[step]}
                </Typography>

                {/* {isAccountCreationStep ? (
                  <AccountStep
                    fields={fields}
                    errors={errors}
                    resetField={resetField}
                    showInputPassword={showInputPassword}
                    showInputRepeatPassword={showInputRepeatPassword}
                    dispatchFieldsVisibility={dispatchFieldsVisibility}
                    dirtyFields={dirtyFields}
                  />
                ) : (
                  <WalletStep
                    watch={watch}
                    setValue={setValue}
                    showInputMnemonicPhrase={showInputMnemonicPhrase}
                    dispatchFieldsVisibility={dispatchFieldsVisibility}
                    dirtyFields={dirtyFields}
                  />
                )} */}
                {step === Steps.ACCOUNT && (
                  <AccountStep
                    fields={fields}
                    errors={errors}
                    resetField={resetField}
                    dirtyFields={dirtyFields}
                  />
                )}
                {step === Steps.PASSWORD && (
                  <PasswordStep
                    fields={fields}
                    errors={errors}
                    resetField={resetField}
                    dirtyFields={dirtyFields}
                    watch={watch}
                    dispatchFieldsVisibility={dispatchFieldsVisibility}
                    showInputPassword={showInputPassword}
                    showInputRepeatPassword={showInputRepeatPassword}
                  />
                )}
                {step === Steps.BACKUP && (
                  <BackupStep
                    fields={fields}
                    errors={errors}
                    setValue={setValue}
                    resetField={resetField}
                    dirtyFields={dirtyFields}
                    watch={watch}
                  />
                )}
                {step === Steps.WALLET && (
                  <WalletStep
                    fields={fields}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                    showInputMnemonicPhrase={showInputMnemonicPhrase}
                    dispatchFieldsVisibility={dispatchFieldsVisibility}
                    dirtyFields={dirtyFields}
                  />
                )}
                <div className="flex w-full justify-between">
                  <div>
                    <Button
                      id="back"
                      disabled={
                        isLoading || (step === Steps.PASSWORD && !!(workspaceId && invitationId))
                      }
                      action={() => {
                        if (step === Steps.ACCOUNT) {
                          navigate(-1);
                        } else {
                          setStep((prevStep) => prevStep - 1);
                        }
                      }}
                      type="secondary"
                    >
                      {t('authentication.back')}
                    </Button>
                  </div>
                  <div>
                    <Button
                      id="continue"
                      action={
                        step === Steps.WALLET
                          ? () =>
                              open({
                                // values,
                                handleSignUp: (terms: any, setOpen: any) => {
                                  handleSignUp({ ...values, ...terms }, setOpen);
                                },
                                // handleSubmit,
                                // isLoading,
                                // setIsLoading,
                              })
                          : () => {
                              setStep(step + 1);
                            }
                      }
                      disabled={
                        (step === Steps.ACCOUNT &&
                          (!isEmpty(errors) ||
                            !dirtyFields.email ||
                            !dirtyFields.confirmEmail ||
                            (!isCurrentEnvDemo
                              ? !dirtyFields.company ||
                                !dirtyFields.vat ||
                                !dirtyFields.sdi ||
                                !dirtyFields.office
                              : !(values.privacyCheck && values.bcodeConsent)))) ||
                        (step === Steps.PASSWORD &&
                          (errors.password?.message ||
                            errors.repeatPassword?.message ||
                            !dirtyFields.password ||
                            !dirtyFields.repeatPassword ||
                            values.password !== values.repeatPassword)) ||
                        (step === Steps.BACKUP &&
                          (errors.question?.message ||
                            errors.answer?.message ||
                            !values.question ||
                            !dirtyFields.answer)) ||
                        (step === Steps.WALLET && !dirtyFields.seedSaved) ||
                        isLoading
                      }
                    >
                      {t(
                        step !== Steps.WALLET
                          ? 'authentication.continue'
                          : 'authentication.create_account'
                      )}
                    </Button>
                  </div>
                </div>
              </>
            );
          }}
        </Form>

        {/* <div className="w-[17rem]">
          <div className="flex items-center justify-center w-full my-4">
            <div className="h-0.5 w-20" />

            <Typography color="primary" className="mx-1">
              {t('or').toLocaleLowerCase()}
            </Typography>

            <div className="h-0.5 w-20 bg-primary-500" />
          </div>

          <Button disabled={isLoading} action={() => navigate(`/sign-in`)} type="secondary">
            {t('authentication.go_back')}
          </Button>
        </div> */}
        {/* </div> */}
      </div>
      {/* </SimpleBar> */}
    </>
  );
};

export default SignUp;
