import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import { EMPTY_CONTRACT_ID, fieldToCustomize, typeOfQrCode } from '../../imports/constants';
import { Button, Typography } from '../../../../components';
import DetailsField from './DetailsField';
import { Contract, Template } from '../../imports/types';
import { formatDate, truncateText } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';
import { db } from '../../../../imports/firebase';
import { getTotalNftsQuantity } from '../../../../imports/analyticsParser';
import i18n from '../../../../i18n';

type DetailsCardProps = {
  contract: Contract;
  analytics: any;
};

const DetailsCard = ({ contract, analytics }: DetailsCardProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { workspace } = useAppSelector((state) => state.team);
  const template = useAppSelector((state) => state.template);
  const language = i18n.language;

  const totalNft = getTotalNftsQuantity(contract.nfts);
  const totalNftResult = () => {
    if (totalNft.sumQuantity === 0 && totalNft.unlimited) {
      return t('create_contract.form_labels.unlimited');
    }
    if (totalNft.unlimited) {
      return t('create_contract.form_labels.unlimitedPlus', {
        sumQuantity: totalNft.sumQuantity,
      });
    }
    return totalNft.sumQuantity;
  };

  const alreadySendAllNfts = analytics?.sum?.redeem === totalNft.sumQuantity;
  return (
    <div
      className="mt-4 flex flex-col gap-5 rounded-lg border-grey-300 bg-white p-4 shadow-lg"
      id="collection-details"
    >
      <Typography size="body-regular-20">{t('collection_item.details')}</Typography>
      <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-3">
        {!template.fields?.status?.hidden &&
          DetailsField(
            template.fields?.status?.label?.[language] ||
              (t('collection_item.summary.status') as string),
            t(`collection.status.${contract?.status}`) as string
          )}
        {DetailsField(
          t('collection_item.label_fields.data') as string,
          formatDate(contract?.createdAt)
        )}
        {!template.fields?.chain?.hidden &&
          DetailsField(
            template.fields?.chain?.label?.[language] ||
              (t('collection_item.summary.name') as string),
            t(`collection_item.chains.${contract?.chain}`)
          )}
        {!template.fields?.type?.hidden &&
          DetailsField(
            template.fields?.type?.label?.[language] ||
              (t('collection_item.summary.type') as string),
            contract?.type
          )}
        {contract?.category &&
          DetailsField(t('collection_item.label_fields.category') as string, contract.category)}
        {/* CONTROLLARE */}
        {DetailsField(
          t('collection_item.label_fields.redeem') as string,
          analytics ? analytics?.sum.redeem : 0
        )}
        {!template.fields?.totalTokens?.hidden &&
          DetailsField(
            template.fields?.totalTokens?.label?.[language] ||
              (t('collection_item.label_fields.total_quantity') as string),
            totalNftResult()
          )}
        {!template.fields?.maxTokensPerUser?.hidden &&
          DetailsField(
            template.fields?.maxTokensPerUser?.label?.[language] ||
              t('collection_item.label_fields.max_tokens_per_user'),
            contract.maxTokensPerUser
          )}
        {!template.fields?.qrCodeDropVal?.hidden &&
          DetailsField(
            t('collection_item.summary.redeem') as string,
            t(typeOfQrCode(contract.qrCodeDrop))
          )}
        {!template.fields?.doesExpire?.hidden &&
          contract?.expiration >= 0 &&
          DetailsField(
            template.fields?.doesExpire?.label?.[language] ||
              (t('create_contract.form_labels.nft_expiration') as string),
            t('create_contract.form_labels.days', { days: contract?.expiration })
          )}

        {!template.fields?.numberOfCategories?.hidden &&
          contract?.nfts &&
          contract.nfts.length >= 0 &&
          DetailsField(
            template.fields?.numberOfCategories?.label?.[language] ||
              t('create_contract.form_labels.numberOfCategories'),
            contract?.nfts?.length
          )}

        {!template.fields?.doesTransfer?.hidden &&
          workspace?.permissions.token_creator?.write &&
          DetailsField(
            template.fields?.doesTransfer?.label?.[language] ||
              (t('collection_item.label_fields.nft_transfer') as string),
            t(`collection_item.label_fields.transfer.${contract?.transfer}`) ||
              t('collection_item.label_fields.disabled')
          )}
        {!template.fields?.tokenBurn?.hidden &&
          DetailsField(
            template.fields?.tokenBurn?.label?.[language] ||
              (t('collection_item.label_fields.token_burn') as string),
            t(`boolean.${contract?.tokenBurn.toString()}`)
          )}
        {!template.fields?.update?.hidden &&
          DetailsField(
            template.fields?.update?.label?.[language] ||
              (t('collection_item.label_fields.does_update') as string),
            t(`boolean.${contract?.update?.toString() === 'creator'}`)
          )}
        {!template.fields?.smartContract?.hidden &&
          DetailsField(
            template.fields?.smartContract?.label?.[language] ||
              (t('collection_item.label_fields.smart_contract') as string),
            truncateText(contract?.address || '', {
              maxLength: 15,
              rightChars: 0,
            }),
            true,
            contract?.address || ''
          )}
      </div>
      {contract.status === 'created' && !alreadySendAllNfts && (
        <div className="flex flex-col gap-5" id="functions">
          {contract.qrCodeDrop === 0 && (
            <Button
              action={() => {
                navigate(`/nft/manual-redeem`, { state: { contract } });
              }}
            >
              {t('create_contract.form_labels.manual_redeem')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailsCard;
