import { useModal } from 'react-simple-modal-provider';
import { IconAccountSwap } from '../../icons';
import Icon from '../icon/Icon';

const SwapAccountIcon = () => {
  const { open } = useModal('ChangeAccountModal');
  return (
    <div className="size-[40px] rounded-lg">
      <div
        className={`flex !h-[40px] !w-[40px] cursor-pointer items-center justify-center rounded-[10px] border border-primary-400 bg-white hover:bg-primary-100 `}
        onClick={open}
      >
        <Icon icon={IconAccountSwap} size="md" color="primary-400" />
      </div>
    </div>
  );
};

export default SwapAccountIcon;
