import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
/* root imports */
import { onValue, ref } from 'firebase/database';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import QRCode from 'qrcode';
import { ImagePlaceholder } from '../../../../assets/images';
import {
  Button,
  CustomizedText,
  Icon,
  Image,
  InnerPage,
  Input,
  NftCard,
  Tag,
  Typography,
} from '../../../../components';
import {
  IconArrow,
  IconClose,
  IconEdit,
  IconLoading,
  IconResearch,
  IconShare2,
  IconSwap,
  IconTag,
} from '../../../../icons';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
/* tokenCreator imports */
import 'swiper/css';
import 'swiper/css/navigation';
import StepperCard from '../../../../components/stepperCard/StepperCard';
import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import {
  getCollectionAnalytics,
  getCollectionGamingAnalytics,
} from '../../../../imports/analyticsParser';
import { COLORS, redeemer } from '../../../../imports/constants';
import { getContractFromContractId, getLastGameDate } from '../../../../imports/contractsParsers';
import { database, db } from '../../../../imports/firebase';
import { Folder, TQrCodeFormatted } from '../../../../imports/types';
import {
  formatIPFSUri,
  safeWhere,
  downloadAllQrCodes,
  downloadAllTokensCsv,
} from '../../../../imports/utils';
import { updateContract } from '../../../../redux/contracts/contracts.slice';
import {
  AnalyticsComponent,
  DetailsCard,
  DetailsField,
  DetailsGamingTable,
  DetailsTable,
} from '../../components';

import type { Contract, Nft } from '../../imports/types';

type DetailsProps = {
  contract: Contract | undefined;
  isLoading: boolean;
};

const Details = ({ contract, isLoading: isLoadingFromProps }: DetailsProps) => {
  const { t } = useTranslation(['tokenCreator', 'translation']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { uid } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);
  const { list: contractList } = useAppSelector((state) => state.contracts);
  const { id: contractId } = useParams();

  const gamingAnalytics = getCollectionGamingAnalytics(contractList, contractId || '');

  const [image, setImage] = useState('');
  const [folders, setFolders] = useState<Array<Folder>>([]);
  const [nfts, setNfts] = useState<Nft[]>([]);
  const [analytics, setAnalytics] = useState<any>();
  const [searchFilter, setSearchFilter] = useState('');
  const [tagsFilter, setTagsFilter] = useState('');
  // const stateFilterOptions: {
  //   value: string;
  //   label: string;
  //   disabled?: boolean;
  //   icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  // }[] = [
  //   {
  //     value: '',
  //     label: t('collection.filters.state.state'),
  //     icon: IconInfo,
  //   },
  //   {
  //     value: 'redeem',
  //     label: t('collection.filters.state.redeem'),
  //     icon: IconQrCode,
  //   },
  //   {
  //     value: 'transfer',
  //     label: t('collection.filters.state.transfer'),
  //     icon: IconSwap,
  //   },
  //   {
  //     value: 'tokenVerification',
  //     label: t('collection.filters.state.verified'),
  //     icon: IconFileConfirmed,
  //   },
  //   {
  //     value: '',
  //     label: t('collection.filters.state.all'),
  //     icon: IconListPlus,
  //   },
  // ];
  // useEffect(() => {
  //   if (contract && contract.templateId) {
  //     setTemplate(contract.templateId);
  const {
    workspaceGroupObject: { workspaceId, groupId, inGroup, isSuperGroup },
  } = useWorkspaceAndGroup();

  const activeGroupId = workspace?.activeGroupId;

  const stepsForTable = useMemo(() => {
    const steps: any[] = [];
    if (analytics && analytics.activities && contract) {
      steps.push({
        title: t('collection_item.transaction'),
        component: <DetailsTable analytics={analytics.activities} />,
      });
    }
    if (contract?.hasGame) {
      steps.push({
        title: 'Gaming',
        component: <DetailsGamingTable />,
      });
    }
    return steps;
  }, [contract, analytics]);

  const template = useAppSelector((state) => state.template);
  useEffect(() => {
    if (contract && contract.image) {
      if (Array.isArray(contract.image)) {
        setImage(contract.image[0].preview);
      } else {
        setImage(formatIPFSUri(contract.image));
      }
    } else {
      setImage(ImagePlaceholder);
    }
    if (contract?.nfts) {
      setNfts([...contract.nfts]);
    }

    if (!contract?.nfts && contract?.status === 'created') {
      const interval = setInterval(() => {
        window.location.reload();
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [contract, contractList]);

  useEffect(() => {
    if (contractId) {
      setAnalytics(
        getCollectionAnalytics(
          getContractFromContractId(contractId, contractList)?.activities || []
        )
      );
    }
  }, [workspace?.id, contractList]);

  useEffect(() => {
    const foldersQuery = query(
      collection(db, 'folders'),
      ...safeWhere(uid, workspace?.id, isSuperGroup ? undefined : activeGroupId),
      where('type', '==', 'contract')
    );

    const unsubscribeFolders = onSnapshot(foldersQuery, async (querySnapshot) => {
      const folders: any[] = [];
      querySnapshot.forEach((doc) => {
        folders.push(doc.data());
      });

      setFolders(folders.sort((a, b) => (a.name > b.name ? 1 : -1)));
    });
    return () => {
      unsubscribeFolders();
    };
  }, [activeGroupId]);

  useEffect(() => {
    const scoresQuery = query(
      collection(db, `contracts/${contractId}/scores`),
      where('createdAt', '>', getLastGameDate(contractList))
    );
    const unsubscribeScores = onSnapshot(scoresQuery, async (querySnapshot) => {
      const scores: any[] = [];
      querySnapshot.forEach((doc) => {
        scores.push(doc.data());
      });
      if (contractId) {
        const contract = getContractFromContractId(contractId, contractList);
        if (contract) {
          const updatedContract = cloneDeep(contract);
          if (updatedContract) {
            updatedContract.scores = scores;
            dispatch(updateContract(updatedContract));
          }
        }
      }
    });
    return () => {
      unsubscribeScores();
    };
  }, []);

  const getQrCodesFormatted = async () => {
    const qrCodes: any = [];
    nfts.forEach(async (nft) => {
      if (contract && nft) {
        /**
         * This condition covers the unique QRCode for all NFTs
         */
        if (contract.qrCodeDrop === 2) {
          const qrMockKey = '0x0000000000000000000000000000000000000000000000000000000000000000';
          qrCodes.push(
            await Promise.all(
              [...Array(1)].map(async (value, index) => ({
                id: contract.qrCodes?.[0]?.id || index + 1,
                key: qrMockKey,
                image: await QRCode.toDataURL(
                  `${redeemer}/redeem?id=${contract.id}&key=${qrMockKey}`
                ),
                url: `${redeemer}/redeem?id=${contract.id}&key=${qrMockKey}`,
              }))
            )
          );

          return;
        }
        /**
         * This condition covers the case where every NFT has it's own QRCode
         */
        if (contract.qrCodeDrop) {
          const unsubscribeQrCodes = onValue(
            ref(database, `${contract.id}/s/${nft.id - 1}`),
            async (snapshot) => {
              const values: TQrCodeFormatted[] = [];
              if (!snapshot.exists()) return null;
              await Promise.all(
                snapshot.val().map(async (snap: any) => {
                  if (!snap?.s && !snap?.e) {
                    const { p } = snap;
                    const qrCodeFormatted: TQrCodeFormatted = {
                      id: nft.id,
                      key: p,
                      image: await QRCode.toDataURL(
                        `${redeemer}/redeem?id=${contract.id}&key=${p}`
                      ),
                      url: `${redeemer}/redeem?id=${contract.id}&key=${p}`,
                    };
                    values.push(qrCodeFormatted);
                  }
                })
              );

              qrCodes.push(values);
            }
          );

          return unsubscribeQrCodes;
        }
      }
    });

    return qrCodes;
  };

  const downloadAllQr = async () => {
    if (contract) {
      const qrCodes = await getQrCodesFormatted();

      setTimeout(() => {
        const qnt = qrCodes.reduce(
          (acc: number, cur: { id: number; key: string; image: string }[]) => acc + cur.length,
          0
        );

        if (qnt) {
          downloadAllQrCodes(
            qrCodes.sort(
              (
                a: { id: number; key: string; image: string }[],
                b: { id: number; key: string; image: string }[]
              ) => a[0].id - b[0].id
            ),
            contract
          );
        } else {
          toast.error(t('collection_item.error.no_more_qrcodes') as string);
        }
      }, 1000);
    }
  };

  const downloadCompleteCsv = async () => {
    const qrCodes = await getQrCodesFormatted();
    setTimeout(() => {
      const qnt = qrCodes.reduce(
        (acc: number, cur: { id: number; key: string; image: string }[]) => acc + cur.length,
        0
      );
      if (qnt) {
        downloadAllTokensCsv(nfts, qrCodes);
      } else {
        toast.error(t('collection_item.error.no_more_qrcodes') as string);
      }
    }, 1000);
  };

  const handleTagFilter = (nft: Nft) => {
    if (tagsFilter) {
      return nft?.tags?.some((tag) =>
        tag?.toLocaleLowerCase().includes(tagsFilter?.toLocaleLowerCase())
      );
    }
    return true;
  };

  return (
    <InnerPage>
      {isLoadingFromProps ? (
        <div className="flex grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : (
        <>
          {!contract ? (
            <div className="mx-auto flex max-w-sm grow flex-col items-center justify-center">
              <Typography as="h2" size="xl" className="text-center">
                {t('collection_item.contract_not_found_title')}
              </Typography>
              <IconArrow
                onClick={() => navigate('/nft')}
                width={40}
                height={40}
                stroke={COLORS['primary-500']}
                className="mb-3 cursor-pointer rounded-md"
              />
            </div>
          ) : (
            <div className="flex flex-col gap-8">
              <div className="flex ">
                <IconArrow
                  onClick={() => navigate('/nft')}
                  width={40}
                  height={40}
                  stroke={COLORS['primary-500']}
                  className="mb-3 cursor-pointer rounded-md"
                />
              </div>
              <div className="flex flex-row justify-between gap-8">
                <Image src={image} type="cover" className="h-[262px] w-auto rounded-lg shadow-xl" />
                <div className="flex flex-col">
                  <div className="flex flex-row gap-8">
                    <div className="flex w-full flex-col gap-8">
                      <div className="flex w-full flex-col gap-3" id="header">
                        <div className="flex w-full flex-row gap-4 ">
                          <Typography size="xl" className="w-full">
                            {contract?.name}
                          </Typography>
                          {!template.fields?.tag?.hidden &&
                            contract?.tag &&
                            contract?.tag?.length > 0 && (
                              <div className="flex w-full items-center space-x-2">
                                {contract.tag.map((tag) => (
                                  <Tag key={tag} title={tag} />
                                ))}
                              </div>
                            )}
                          {workspace?.permissions?.token_creator?.write && (
                            <div className="flex w-full flex-row justify-end gap-4">
                              {contract?.update === 'creator' && (
                                <Icon
                                  icon={IconEdit}
                                  onClick={() =>
                                    navigate(`/nft/update-collection/${contract.id}`, {
                                      state: contract,
                                    })
                                  }
                                  className="cursor-pointer rounded-lg bg-primary-400 p-2 shadow-xl"
                                  size="30"
                                  stroke="white"
                                />
                              )}
                              {contract?.addCategory && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    navigate(`/nft/add-categories/${contract.id}`, {
                                      state: contract,
                                    })
                                  }
                                  className="flex size-[30px] cursor-pointer items-center justify-center rounded-lg bg-primary-400 p-2 text-body-semibold-20 text-white shadow-xl"
                                >
                                  +
                                </button>
                              )}
                              {['creator', 'creator&owner'].includes(contract.transfer) && (
                                <Icon
                                  icon={IconShare2}
                                  onClick={() => navigate(`/nft/transfer-nft`, { state: contract })}
                                  className="cursor-pointer rounded-lg bg-primary-400 p-2 shadow-xl"
                                  size="30"
                                  stroke="white"
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <div>
                          {DetailsField(t('collection_item.label_fields.by'), workspace?.name)}
                        </div>
                        <div>
                          {DetailsField(
                            t('collection_item.label_fields.created_with_template'),
                            template.name
                          )}
                        </div>
                        <CustomizedText text={contract?.description} />
                        <DetailsCard contract={contract} analytics={analytics} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AnalyticsComponent
                label={t('collection_item.analytics')}
                analytics={analytics ? analytics.sum : undefined}
                hasGaming={contract.hasGame}
                to={`/nft/analytics/${contractId}`}
                gamingAnalytics={gamingAnalytics}
              />
              <div className="mt-4 flex flex-col gap-4" id="nfts">
                <Typography size="body-regular-20">{t('collection_item.nfts')}</Typography>
                <div className="flex items-start justify-between">
                  <div className="flex w-full items-center gap-4">
                    <Input
                      type="text"
                      placeholder={t('collection.filters.name_or_attribute')}
                      onChange={(event) => {
                        setSearchFilter(event.target.value);
                      }}
                      elementLeft={<Icon icon={IconResearch} color="grey-600" />}
                      elementRight={
                        <Button type="ghost" icon={IconClose} action={() => setSearchFilter('')} />
                      }
                      value={searchFilter}
                      className="!border-grey4 text-grey2 h-[36px] rounded-[4px] !text-body-regular-12"
                    />
                  </div>
                  <div className="flex gap-4">
                    <Button action={downloadAllQr}>
                      {t('collection_item.label_fields.download_all_qr')}
                    </Button>
                    <Button action={downloadCompleteCsv} type="secondary">
                      {t('collection_item.label_fields.download_all_csv')}
                    </Button>
                  </div>
                </div>
                <div className="flex w-full items-center gap-4">
                  <Input
                    type="text"
                    placeholder={t('collection.filters.tag')}
                    onChange={(event) => {
                      setTagsFilter(event.target.value);
                    }}
                    elementLeft={<Icon icon={IconTag} />}
                    elementRight={
                      <Button type="ghost" icon={IconClose} action={() => setTagsFilter('')} />
                    }
                    value={tagsFilter}
                    className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-500"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 md:grid-cols-4 lg:grid-cols-5">
                {nfts
                  ?.sort((a, b) => a.id - b.id)
                  ?.filter((nft) => {
                    return (
                      (nft?.name
                        ?.toLocaleLowerCase()
                        ?.includes(searchFilter?.toLocaleLowerCase()) &&
                        handleTagFilter(nft)) ||
                      (Array.isArray(nft?.attributes) &&
                        nft?.attributes?.some(
                          (attribute) =>
                            attribute?.trait_type
                              ?.toLocaleLowerCase()
                              ?.includes(searchFilter?.toLocaleLowerCase()) ||
                            (attribute?.type === 'text' &&
                              typeof attribute?.value === 'string' &&
                              attribute?.value
                                ?.toLocaleLowerCase()
                                .includes(searchFilter?.toLocaleLowerCase()))
                        ))
                    );
                  })
                  .map((nft) => (
                    <NftCard
                      contract={contract}
                      item={nft}
                      disabled={contract?.status !== 'created'}
                      key={nft.id}
                    />
                  ))}
              </div>
              {/* </div> */}
              <StepperCard steps={stepsForTable} />
            </div>
          )}
        </>
      )}
    </InnerPage>
  );
};

export default Details;
