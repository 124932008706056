/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconAccountSwap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    version="1.1"
    stroke={props.stroke}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Layer_1" />
    <g id="Layer_2">
      <g>
        <path
          fill={props.color}
          d="M59.65,273.7c0,44.09,35.87,79.96,79.96,79.96s79.96-35.87,79.96-79.96c0-44.09-35.87-79.96-79.96-79.96    S59.65,229.61,59.65,273.7z M139.61,225.74c26.44,0,47.96,21.51,47.96,47.96s-21.51,47.96-47.96,47.96    c-26.44,0-47.96-21.51-47.96-47.96S113.17,225.74,139.61,225.74z"
        />
        <path
          fill={props.color}
          d="M27.5,469.14c0,8.84,7.16,16,16,16h192.22c8.84,0,16-7.16,16-16c0-55.1-44.83-99.93-99.93-99.93h-24.37    C72.33,369.22,27.5,414.04,27.5,469.14z M127.43,401.22h24.37c31.95,0,58.81,22.17,66.02,51.93H61.4    C68.62,423.38,95.48,401.22,127.43,401.22z"
        />
        <path
          fill={props.color}
          d="M452.35,106.82c0-44.09-35.87-79.96-79.96-79.96s-79.96,35.87-79.96,79.96s35.87,79.96,79.96,79.96    S452.35,150.91,452.35,106.82z M324.43,106.82c0-26.44,21.51-47.96,47.96-47.96c26.44,0,47.96,21.51,47.96,47.96    s-21.51,47.96-47.96,47.96C345.94,154.77,324.43,133.26,324.43,106.82z"
        />
        <path
          fill={props.color}
          d="M384.57,202.34H360.2c-55.1,0-99.93,44.83-99.93,99.93c0,8.84,7.16,16,16,16H468.5c8.84,0,16-7.16,16-16    C484.5,247.16,439.67,202.34,384.57,202.34z M294.18,286.26c7.21-29.76,34.08-51.93,66.02-51.93h24.37    c31.95,0,58.81,22.17,66.02,51.93H294.18z"
        />
        <path
          fill={props.color}
          d="M279.64,453.14c-8.84,0-16,7.16-16,16s7.16,16,16,16h92.75c8.84,0,16-7.16,16-16v-52.96l2.13,2.13    c3.12,3.12,7.22,4.69,11.31,4.69c4.09,0,8.19-1.56,11.31-4.69c6.25-6.25,6.25-16.38,0-22.63l-29.43-29.43    c-0.38-0.38-0.77-0.73-1.18-1.07c-0.18-0.14-0.36-0.27-0.54-0.41c-0.24-0.18-0.47-0.36-0.71-0.53c-0.22-0.15-0.45-0.28-0.68-0.41    c-0.22-0.13-0.44-0.27-0.66-0.39c-0.23-0.12-0.47-0.23-0.71-0.34c-0.24-0.11-0.47-0.23-0.71-0.33c-0.23-0.1-0.47-0.17-0.7-0.26    c-0.26-0.09-0.51-0.19-0.78-0.27c-0.23-0.07-0.47-0.12-0.7-0.18c-0.27-0.07-0.54-0.14-0.82-0.2c-0.26-0.05-0.53-0.08-0.8-0.12    c-0.25-0.04-0.5-0.08-0.75-0.11c-0.48-0.05-0.95-0.07-1.43-0.07c-0.05,0-0.1-0.01-0.14-0.01c-0.06,0-0.11,0.01-0.17,0.01    c-0.47,0-0.94,0.03-1.41,0.07c-0.26,0.03-0.51,0.07-0.77,0.11c-0.26,0.04-0.52,0.07-0.78,0.12c-0.28,0.06-0.56,0.13-0.84,0.2    c-0.23,0.06-0.46,0.11-0.68,0.18c-0.27,0.08-0.53,0.18-0.8,0.28c-0.23,0.08-0.46,0.16-0.68,0.25c-0.25,0.1-0.48,0.22-0.73,0.34    c-0.23,0.11-0.47,0.21-0.7,0.33c-0.23,0.12-0.45,0.26-0.67,0.4c-0.22,0.13-0.45,0.26-0.67,0.41c-0.25,0.16-0.48,0.35-0.71,0.52    c-0.18,0.14-0.37,0.26-0.55,0.41c-0.41,0.34-0.8,0.69-1.17,1.06l-29.44,29.44c-6.25,6.25-6.25,16.38,0,22.63    c3.12,3.12,7.22,4.69,11.31,4.69c4.09,0,8.19-1.56,11.31-4.69l2.13-2.13v36.96H279.64z"
        />
        <path
          fill={props.color}
          d="M232.41,60.5c8.84,0,16-7.16,16-16s-7.16-16-16-16h-92.75c-8.84,0-16,7.16-16,16v52.96l-2.13-2.13    c-6.25-6.25-16.38-6.25-22.63,0c-6.25,6.25-6.25,16.38,0,22.63l29.43,29.43c0.38,0.38,0.77,0.73,1.18,1.07    c0.18,0.15,0.36,0.27,0.55,0.41c0.24,0.18,0.47,0.36,0.71,0.52c0.22,0.15,0.46,0.28,0.68,0.42c0.22,0.13,0.43,0.27,0.66,0.39    c0.24,0.13,0.48,0.23,0.72,0.35c0.23,0.11,0.46,0.23,0.7,0.32c0.24,0.1,0.48,0.18,0.71,0.26c0.25,0.09,0.5,0.19,0.76,0.27    c0.24,0.07,0.48,0.13,0.72,0.19c0.26,0.07,0.53,0.14,0.8,0.19c0.28,0.06,0.56,0.09,0.84,0.13c0.24,0.03,0.47,0.08,0.71,0.1    c0.52,0.05,1.05,0.08,1.58,0.08s1.05-0.03,1.58-0.08c0.24-0.02,0.47-0.07,0.71-0.1c0.28-0.04,0.56-0.07,0.84-0.13    c0.27-0.05,0.53-0.13,0.79-0.19c0.24-0.06,0.48-0.11,0.72-0.19c0.26-0.08,0.51-0.18,0.76-0.27c0.24-0.09,0.48-0.16,0.71-0.26    c0.24-0.1,0.47-0.21,0.7-0.32c0.24-0.11,0.48-0.22,0.72-0.35c0.22-0.12,0.44-0.26,0.66-0.39c0.23-0.14,0.46-0.27,0.69-0.42    c0.24-0.16,0.47-0.34,0.71-0.52c0.18-0.14,0.37-0.26,0.55-0.41c0.41-0.34,0.81-0.69,1.18-1.07l29.43-29.43    c6.25-6.25,6.25-16.38,0-22.63c-6.25-6.25-16.38-6.25-22.63,0l-2.13,2.13V60.5H232.41z"
        />
      </g>
    </g>
  </svg>
);
export default IconAccountSwap;
