import {
  IconAccountManagement,
  IconAnalytics,
  IconChartPie,
  IconEdit,
  IconFileNft,
  IconFolderNotch,
  IconFunctionality,
  IconNft,
  IconQrCode,
  IconShare,
} from '../../../icons';
import { currentEnv, environments } from '../../../imports/constants';
import type { Routes } from '../../../imports/types';

import {
  AddCategories,
  AttributesDetails,
  Collection,
  CollectionAnalytics,
  CollectionItem,
  CollectionNft,
  CompanyProfile,
  CreateContract,
  CreatePolls,
  Folder,
  ManualRedeem,
  NewsDetails,
  NftAnalytics,
  PollsDetails,
  RestrictedAreaDetails,
  ShopDetails,
  TransferNft,
  UpdateCollection,
  Users,
} from '../pages';
import CompanyAnalytics from '../pages/analytics/CompanyAnalytics';
import Functionality from '../pages/functionality/Functionality';

const isProd = currentEnv === environments.PROD;

const routes: Routes[] = [
  {
    name: 'collection',
    path: '/',
    component: Collection,
    isHome: true,
    topbarIcon: IconNft,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
  {
    name: 'collection_item',
    path: '/collection/:id',
    topbarIcon: IconNft,
    component: CollectionItem,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
  {
    name: 'collection_item',
    path: '/collection/:id/:nftId',
    topbarIcon: IconNft,
    component: CollectionNft,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },

  {
    name: 'company_profile',
    path: '/company-profile',
    component: CompanyProfile,
    topbarIcon: IconAccountManagement,
    hideFromTeam: true,
  },
  {
    name: 'update_collection',
    path: '/update-collection/:id',
    component: UpdateCollection,
    topbarIcon: IconEdit,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['write'],
    },
  },
  {
    name: 'add_token',
    path: '/add-categories/:id',
    component: AddCategories,
    topbarIcon: IconEdit,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['write'],
    },
  },
  {
    name: 'update_token',
    path: '/update-collection/:id/:tokenId',
    component: UpdateCollection,

    topbarIcon: IconEdit,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['write'],
    },
  },
  {
    name: 'create_contract',
    path: '/create-contract',
    component: CreateContract,
    topbarIcon: IconFileNft,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['write'],
    },
  },
  {
    name: 'manual_redeem',
    path: '/manual-redeem',
    component: ManualRedeem,
    topbarIcon: IconQrCode,
  },
  {
    name: 'transfer_nft',
    path: '/transfer-nft',
    component: TransferNft,
    topbarIcon: IconShare,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['write'],
    },
  },
  {
    name: 'folder',
    path: '/folder/:id',
    topbarIcon: IconFolderNotch,
    component: Folder,
  },
  {
    name: 'analytics',
    path: '/analytics/',
    component: CompanyAnalytics,
    topbarIcon: IconAnalytics,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
  {
    name: 'collection-analytics',
    path: '/analytics/:contractId',
    // component:  CollectionAnalytics,
    component: CollectionAnalytics,
    topbarIcon: IconChartPie,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
  {
    name: 'nft-analytics',
    path: '/analytics/:contractId/:nftId',
    // component:  CollectionAnalytics,
    component: NftAnalytics,
    topbarIcon: IconChartPie,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
  {
    name: 'crm',
    path: '/crm',
    component: Users,
    topbarIcon: IconChartPie,
    isHome: true,
    requiresPermission: {
      moduleName: 'crm',
      permissions: ['read'],
    },
  },
  {
    name: 'functionality',
    path: '/functionality',
    component: Functionality,
    topbarIcon: IconFunctionality,
    isHome: true,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['write'],
    },
  },
  {
    name: 'create_polls',
    path: '/create-polls',
    component: CreatePolls,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['write'],
    },
  },
  {
    name: 'polls_item',
    path: 'functionality/polls/:id',
    component: PollsDetails,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
  {
    name: 'edit_polls',
    path: '/edit-polls/:id',
    component: CreatePolls,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['write'],
    },
  },
  {
    name: 'news_item',
    path: 'functionality/news/:id',
    component: NewsDetails,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
  {
    name: 'attributes_item',
    path: 'functionality/attributes/:id',
    component: AttributesDetails,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
  {
    name: 'shop_item',
    path: 'functionality/shop/:id',
    component: ShopDetails,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
  {
    name: 'restrictedArea_item',
    path: 'functionality/restricted/:id',
    component: RestrictedAreaDetails,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read'],
    },
  },
];

export default routes;
