import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Icon, Typography } from '../../../../components';
import { IconArrowDown, IconArrowUp, IconWarning } from '../../../../icons';
import { getWorkspaceCreatedContracts } from '../../../../imports/contractsParsers';
import { AvailableOn } from '../../../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';
import { Contract, Nft } from '../../imports/types';

interface ContractsAccordionProps {
  availableContracts: AvailableOn;
  setContracts: any;
  isPollPage?: boolean;
}

interface CheckedContracts {
  contract: Contract;
  isChecked: boolean;
  checkedTokens: number[];
  isValid: boolean;
}

const ContractsManager: React.FC<ContractsAccordionProps> = ({
  availableContracts,
  setContracts,
  isPollPage = false,
}) => {
  const [allContracts, setAllContracts] = useState<Contract[]>([]);
  const [checkedContracts, setCheckedContracts] = useState<CheckedContracts[]>([]);
  const [expandedContracts, setExpandedContracts] = useState<{ [key: string]: boolean }>({});
  const { t } = useTranslation(['tokenCreator']);
  const { workspace } = useAppSelector((state) => state.team);
  const { list: contractList } = useAppSelector((state) => state.contracts);

  function extractContractsFromCheckedContracts(
    checkedContracts: CheckedContracts[],
    allContracts: Contract[]
  ): Contract[] {
    const formattedContracts: Contract[] = [];
    checkedContracts.forEach((checkedContract) => {
      if (checkedContract.isValid && checkedContract.isChecked) {
        allContracts.forEach((contract) => {
          const tokens: Nft[] = [];
          checkedContract.checkedTokens.forEach((tokenId) => {
            contract.nfts.forEach((nft) => {
              if (nft.id === tokenId) {
                tokens.push(nft);
              }
            });
          });
          if (checkedContract.contract.address === contract.address) {
            formattedContracts.push({
              ...contract,
              nfts: tokens,
            });
          }
        });
      }
    });

    return formattedContracts;
  }

  useEffect(() => {
    setAllContracts(getWorkspaceCreatedContracts(workspace?.id, contractList));
  }, []);

  useEffect(() => {
    if (checkedContracts.length > 0)
      setContracts(extractContractsFromCheckedContracts(checkedContracts, allContracts));
  }, [checkedContracts]);

  useEffect(() => {
    const checkedContractsArray: CheckedContracts[] = [];
    allContracts.forEach((contract) => {
      if (contract.status !== 'created') return;
      const key = contract.address;
      const isChecked = Object.keys(availableContracts).includes(key);
      const checkedTokens = isChecked ? availableContracts[key] : [];
      const isValid = isChecked;
      checkedContractsArray.push({
        contract,
        isChecked,
        checkedTokens,
        isValid,
      });
    });

    setCheckedContracts(checkedContractsArray);
  }, [allContracts]);

  const toggleContract = (contract: CheckedContracts, selectAllContract?: boolean) => {
    const toggledContracts = checkedContracts.map((checkedContract) => {
      if (checkedContract.contract.address === contract.contract.address) {
        const isChecked = !checkedContract.isChecked;
        const checkedTokens = isChecked
          ? contract.contract.nfts
              .filter((nft) => selectAllContract || !isPollPage || nft.quantity !== 1) // Filter discarding contracts with quantity !== 1 if they are in the polls to prevent checking single nfts, if selectAllContract present however I still count them
              .map((nft) => nft.id)
          : [];
        return {
          ...checkedContract,
          isChecked,
          checkedTokens,
          isValid: checkedTokens.length > 0,
        };
      }
      return checkedContract;
    });
    setCheckedContracts(toggledContracts);
  };

  const handleExpand = (contract: CheckedContracts) => {
    setExpandedContracts((prevState) => ({
      ...prevState,
      [contract.contract.id]: !prevState[contract.contract.id],
    }));
  };

  const toggleToken = (contract: CheckedContracts, tokenId: number) => {
    const contractsWithNewtokens = checkedContracts.map((checkedContract) => {
      if (checkedContract.contract.address === contract.contract.address) {
        const newCheckedTokens = checkedContract.checkedTokens.includes(tokenId)
          ? checkedContract.checkedTokens.filter((token) => token !== tokenId)
          : [...checkedContract.checkedTokens, tokenId];
        return {
          ...checkedContract,
          checkedTokens: newCheckedTokens,
          isValid: newCheckedTokens.length > 0,
        };
      }
      return checkedContract;
    });
    setCheckedContracts(contractsWithNewtokens);
  };

  const toggleAllTokens = (contract: CheckedContracts, selectAllContract: boolean) => {
    const allTokensOn = checkedContracts.map((checkedContract) => {
      if (checkedContract.contract.address === contract.contract.address) {
        return {
          ...checkedContract,
          checkedTokens: checkedContract.contract.nfts
            .filter((nft) => selectAllContract || !isPollPage || nft.quantity !== 1) // Filter discarding contracts with quantity !== 1 if they are in the polls to prevent checking single nfts, if selectAllContract present however I still count them
            .map((nft) => nft.id),
          isValid: true,
        };
      }
      return checkedContract;
    });
    setCheckedContracts(allTokensOn);
  };

  const unToggleAllTokens = (contract: CheckedContracts) => {
    const allTokensOff = checkedContracts.map((checkedContract) => {
      if (checkedContract.contract.address === contract.contract.address) {
        return {
          ...checkedContract,
          checkedTokens: [],
          isValid: false,
        };
      }
      return checkedContract;
    });
    setCheckedContracts(allTokensOff);
  };

  const toggleAllContractsAndTokens = (mode: 'select' | 'deselect') => {
    const checkedContractsArray: CheckedContracts[] = [];
    allContracts.forEach((contract) => {
      const allTokensSingleQuantity =
        isPollPage && contract.nfts.every((nft) => nft.quantity === 1); // Indicates that all contract tokens are single, only if in pollPage
      const singleTokenContract =
        isPollPage && contract.nfts.length === 1 && contract.nfts[0].quantity === 1; // Indicates that the contract has only one token and is single, only if in pollPage
      const selectAllContract = allTokensSingleQuantity && !singleTokenContract; // Indicates that contract tokens van selected all because it has more than one but all single ones, only if in pollPage
      if (mode === 'select') {
        checkedContractsArray.push({
          contract,
          isChecked: !singleTokenContract,
          checkedTokens: contract.nfts
            .filter((nft) => selectAllContract || !isPollPage || nft.quantity !== 1) // Filter discarding contracts with quantity !== 1 if they are in the polls to prevent checking single nfts, if selectAllContract present however I still count them
            .map((nft) => nft.id),
          isValid: true,
        });
      } else {
        checkedContractsArray.push({
          contract,
          isChecked: false,
          checkedTokens: [],
          isValid: false,
        });
      }
    });
    setCheckedContracts(checkedContractsArray);
  };

  const renderTokens = (nfts: Nft[], checkedContract: CheckedContracts) => {
    return nfts.map((nft) => {
      const isDisabled = isPollPage && nft.quantity === 1;
      return (
        <div key={`${nft.id}_${checkedContract.contract.id}`} className="ml-7 flex flex-row gap-4">
          <div className="flex">
            <Checkbox
              id={`${checkedContract.contract.id}${nft.id}`}
              key={uniqueId(nft.id.toString())}
              isChecked={checkedContract.checkedTokens.includes(nft.id)}
              onChange={() => {
                if (!isDisabled) toggleToken(checkedContract, nft.id);
              }}
              disabled={isDisabled}
            />
          </div>
          <div className="flex">
            <Typography size="sm" weight="light">
              {nft.id} - {nft.name}
            </Typography>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex size-auto flex-col space-y-2">
      <div className="sticky top-0 z-10 flex w-auto flex-col gap-2 bg-white pb-2">
        <div className="flex flex-row gap-2">
          <div onClick={() => toggleAllContractsAndTokens('select')}>
            <Typography color="primary-500" size="sm" className="cursor-pointer">
              {t('token_manager.select_all_contracts')}
            </Typography>
          </div>
          <div className="mx-2 h-auto w-px bg-primary-500"></div>
          <div onClick={() => toggleAllContractsAndTokens('deselect')}>
            <Typography color="primary-500" size="sm" className="cursor-pointer">
              {t('token_manager.deselect_all_contracts')}
            </Typography>
          </div>
        </div>
        {extractContractsFromCheckedContracts(checkedContracts, allContracts).length === 0 && (
          <div className="flex flex-row gap-2">
            <Icon icon={IconWarning} className="mt-[2px] size-4" />
            <Typography color="error" size="sm" className="cursor-pointer">
              {t('polls.errors.select_one_contract')}
            </Typography>
          </div>
        )}
      </div>

      <div className="overflow-auto">
        {checkedContracts
          .filter((c) => c)
          .map((checkedContract, index) => {
            const id = checkedContract.contract.id;
            const name = checkedContract.contract.name;
            const nfts = checkedContract.contract.nfts;
            const isChecked = checkedContract.isChecked;
            const tokensLength = checkedContract.contract.nfts.length;
            const checkedTokens = checkedContract.checkedTokens.length;
            const isContractExpanded = expandedContracts[id];
            const isValid = checkedContract.isValid;
            const allTokensSingleQuantity = isPollPage && nfts.every((nft) => nft.quantity === 1); // Indicates that all contract tokens are single, only if in pollPage
            const singleTokenContract = isPollPage && tokensLength === 1 && nfts[0].quantity === 1; // Indicates that the contract has only one token and is single, only if in pollPage
            const selectAllContract = allTokensSingleQuantity && !singleTokenContract; // Indicates that contract tokens van selected all because it has more than one but all single ones, only if in pollPage
            const notSelectableContract = allTokensSingleQuantity && singleTokenContract; // Contract not selectable because it has only one token and is single (quantity =1)

            return (
              <div
                key={uniqueId(index.toString())}
                className="gap 2 flex flex-col rounded border p-2"
              >
                <div className="flex w-full flex-row gap-4">
                  {isContractExpanded ? (
                    <div className="flex">
                      <Icon
                        icon={IconArrowUp}
                        onClick={() => handleExpand(checkedContract)}
                        className="size-4 cursor-pointer"
                        color="secondary-500"
                        stroke="primary-500"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <Icon
                        icon={IconArrowDown}
                        onClick={() => handleExpand(checkedContract)}
                        className="size-4 cursor-pointer"
                        color="secondary-500"
                        stroke="primary-500"
                      />
                    </div>
                  )}
                  <div className="flex w-auto flex-row">
                    <Checkbox
                      id={id}
                      isChecked={isChecked}
                      key={uniqueId(id.toString())}
                      onChange={() => {
                        toggleContract(checkedContract, selectAllContract);
                      }}
                      disabled={singleTokenContract}
                    />
                  </div>
                  <Typography
                    size="sm"
                    weight="light"
                    color={!isValid && isChecked ? 'error' : 'primary-500'}
                  >
                    {name}
                  </Typography>
                  {isChecked && (
                    <Typography size="sm" weight="light" color="grey-400">
                      - {checkedTokens}/{tokensLength}
                    </Typography>
                  )}
                  <div className="grow" />
                  {isChecked && !notSelectableContract && (
                    <>
                      <div onClick={() => toggleAllTokens(checkedContract, selectAllContract)}>
                        <Typography color="primary-500" size="sm" className="cursor-pointer">
                          {t('token_manager.select_all_tokens')}
                        </Typography>
                      </div>
                      <div className="mx-2 h-auto w-px bg-primary-500"></div>
                      <div onClick={() => unToggleAllTokens(checkedContract)}>
                        <Typography color="primary-500" size="sm" className="cursor-pointer">
                          {t('token_manager.deselect_all_tokens')}
                        </Typography>
                      </div>
                    </>
                  )}
                </div>

                {isContractExpanded && !notSelectableContract && (
                  <div className="ml-7 mt-2 flex flex-col gap-2">
                    {renderTokens(nfts, checkedContract)}
                  </div>
                )}
                {!isValid && isChecked && (
                  <div
                    className="flex flex-row gap-2"
                    key={uniqueId(checkedContract.contract.id.toString())}
                  >
                    <Typography size="sm" weight="light" color="error">
                      {t('token_manager.invalid')}
                    </Typography>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ContractsManager;
