import type { ReactNode } from 'react';
import React from 'react';

import type { InputType } from '../../imports/types';

import Typography from '../typography/Typography';
import './input.css';

export type InputProps = InputType & {
  label?: string;
  minValue?: string | number;
  maxValue?: string | number;
  elementLeft?: ReactNode;
  elementRight?: ReactNode;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onWheel?: React.WheelEventHandler<HTMLInputElement>;
  className?: string;
  wFull?: boolean;
  disabled?: boolean;
  elementsRight?: ReactNode[];
  step?: number;
};

const Input = ({
  id,
  name,
  type,
  value,
  step,
  defaultValue,
  placeholder,
  disabled,
  error,
  success,
  onBlur,
  onChange,
  inputRef,
  minValue,
  maxValue,
  elementLeft,
  elementRight,
  elementsRight,
  className,
  onKeyUp,
  onWheel,
  wFull = false,
  label,
}: InputProps) => {
  const getInputStatus = () => {
    let status = '';

    if (error) {
      status = 'error';
    }
    if (success) {
      status = 'success';
    }
    if (disabled) {
      status = 'disabled';
    }

    return status;
  };

  const getInputPaddingX = () => {
    let padding = '';

    if (elementLeft) {
      padding = `${padding} with-element-left`;
    }
    if (elementRight) {
      padding = `${padding} with-element-right`;
    }

    return padding;
  };

  return (
    <div className="input-container " style={{ width: wFull ? '100%' : 'auto' }}>
      {label && (
        <div className="input-label">
          <Typography size="body-regular-12">{label}</Typography>
        </div>
      )}
      {elementLeft && <div className="input-element left  p-px">{elementLeft}</div>}
      {elementRight && <div className="input-element right p-px ">{elementRight}</div>}
      {elementsRight && (
        <div className="input-element right mr-[4px] p-[4px]">
          {elementsRight.map((element, index) => (
            <div key={index}>{element}</div>
          ))}
        </div>
      )}
      <input
        className={`input ${getInputPaddingX()} ${getInputStatus()} ${className}`}
        style={elementsRight && { paddingRight: '42px' }}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        min={minValue}
        max={maxValue}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        ref={inputRef}
        onKeyUp={onKeyUp}
        onWheel={onWheel}
        step={step}
      />
    </div>
  );
};

export default Input;
