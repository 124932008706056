import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// import { Form } from 'react-router-dom';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';

import { useLoadingStatusContext } from '../../context';
import { form } from '../../modules/team/common/add-group-form';
import Button from '../button/Button';
import Field from '../field/Field';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';
import Checkbox from '../checkbox/Checkbox';

type AddUserModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { t } = useTranslation(['team']);
  const { state } = useLoadingStatusContext();
  const { handleAddGroup } = useModalProps('AddWorkspaceGroupModal');
  return (
    <ModalLayout
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="mb-16 mt-[20px] flex flex-col justify-start gap-[16px] px-[50px] pt-[60px]">
        <section className="flex h-full flex-col items-start ">
          <Typography
            weight="medium"
            size="body-medium-30"
            color="primary-500"
            className="mb-[10px]"
          >
            {t('manage.add_group.title')}
          </Typography>
          <Form
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            className="size-full py-5"
          >
            {({ fields, handleSubmit, errors, setValue, resetField, watch, isValid }) => {
              const values = watch();
              return (
                <div className="flex h-full flex-col justify-between">
                  <div className="flex size-full flex-col justify-around">
                    <Field error={errors.name?.message} label={t('manage.add_user.name')}>
                      <Input
                        placeholder={t('manage.add_group.name-placeholder')}
                        name={fields.name.name}
                        id={fields.name.name}
                        onChange={fields.name.onChange}
                        onBlur={fields.name.onBlur}
                        inputRef={fields.name.ref}
                        className="mt-[5px] "
                      />
                    </Field>
                    <Field>
                      <div className="mt-[5px] flex w-full items-center justify-start gap-x-2">
                        <Checkbox
                          name={fields.isSuperGroup.name}
                          id={fields.isSuperGroup.name}
                          onChange={() => {
                            setValue(fields.isSuperGroup.name, !watch(fields.isSuperGroup.name));
                          }}
                        />
                        <Typography size="md" weight="regular">
                          {t('manage.add_group.super_group')}
                        </Typography>
                      </div>
                    </Field>
                  </div>

                  <div className="mt-7 flex items-end justify-end space-y-[25px]">
                    <Button
                      disabled={!isValid || state.status === 'pending'}
                      action={() => {
                        handleSubmit(handleAddGroup)().finally(() => {});
                      }}
                    >
                      {t('manage.add_group.title')}
                    </Button>
                  </div>
                </div>
              );
            }}
          </Form>
        </section>
      </div>
    </ModalLayout>
  );
};

const AddWorkspaceGroupModal = ({ children }: AddUserModalProps) => {
  const [isOpen, setOpen] = useModalState();
  const { t } = useTranslation();
  return (
    <Modal id="AddWorkspaceGroupModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};

export default AddWorkspaceGroupModal;
