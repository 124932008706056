import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Typography } from '../../../../components';
import { typeOfQrCode } from '../../imports/constants';
import DetailsField from '../detailsCard/DetailsField';
import { formatDate, truncateText } from '../../../../imports/utils';
import { Contract, Nft, Template } from '../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';
import i18n from '../../../../i18n';

type NftDetailsCardProps = {
  contract: Contract;
  nft: Nft;
};

const NftDetailsCard = ({ contract, nft }: NftDetailsCardProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { workspace } = useAppSelector((state) => state.team);
  const template = useAppSelector((state) => state.template);
  const language = i18n.language;

  return (
    <div
      className="mt-4 flex flex-col rounded-lg border-grey-300 bg-white p-4 shadow-lg"
      id="details"
    >
      <Typography size="body-regular-20" className="mb-4" weight="semibold">
        {t('collection_item.details_nft')}
      </Typography>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-5">
        {!template.fields?.status?.hidden &&
          DetailsField(
            template.fields?.status?.label?.[language] ||
              (t('collection_item.label_fields.status') as string),
            contract?.status
          )}
        {DetailsField(
          t('collection_item.label_fields.data') as string,
          formatDate(Number(contract?.createdAt))
        )}

        {!template.fields?.chain?.hidden &&
          DetailsField(
            template.fields?.chain?.label?.[language] ||
              (t('collection_item.label_fields.chain') as string),
            t(`collection_item.chains.${contract?.chain}`)
          )}

        {!template.fields?.type?.hidden &&
          DetailsField(
            template.fields?.type?.label?.[language] ||
              (t('collection_item.label_fields.type') as string),
            contract?.type
          )}

        {!template.fields?.category?.hidden &&
          contract?.category &&
          DetailsField(
            template.fields?.category?.label?.[language] ||
              t('collection_item.label_fields.category'),
            contract?.category
          )}

        {DetailsField(
          t('collection_item.label_fields.total_quantity') as string,
          nft.quantity === 0 ? t('create_contract.form_labels.unlimited') : nft.quantity
        )}
        {!template.fields?.maxTokensPerUser?.hidden &&
          DetailsField(
            template.fields?.maxTokensPerUser?.label?.[language] ||
              t('create_contract.form_labels.nft_per_wallet'),
            contract?.maxTokensPerUser
          )}
        {contract?.qrCodeDropVal &&
          DetailsField(
            t('collection_item.summary.redeem') as string,
            t(typeOfQrCode(contract.qrCodeDropVal || 1))
          )}
        {!template.fields?.doesExpire?.hidden &&
          contract &&
          contract.expiration >= 0 &&
          DetailsField(
            template.fields?.doesExpire?.label?.[language] ||
              (t('create_contract.form_labels.nft_expiration') as string),
            t('create_contract.form_labels.days', { days: contract?.expiration })
          )}

        {!template.fields?.numberOfCategories?.hidden &&
          contract?.nfts &&
          contract.nfts.length >= 0 &&
          DetailsField(
            template.fields?.numberOfCategories?.label?.[language] ||
              t('create_contract.form_labels.numberOfCategories'),
            contract?.nfts.length
          )}

        {DetailsField(
          t('collection_item.label_fields.owner') as string,
          truncateText(contract?.owner || '', {
            maxLength: 15,
            rightChars: 4,
          })
        )}
        {!template.fields?.doesTransfer?.hidden &&
          workspace?.permissions.token_creator.write &&
          DetailsField(
            template.fields?.doesTransfer?.label?.[language] ||
              (t('collection_item.label_fields.nft_transfer') as string),
            t(`collection_item.label_fields.transfer.${contract?.transfer}`) || 'disabled'
          )}
        {!template.fields?.tokenBurn?.hidden &&
          DetailsField(
            template.fields?.tokenBurn?.label?.[language] ||
              (t('collection_item.label_fields.token_burn') as string),
            t(`boolean.${contract?.tokenBurn.toString()}`)
          )}
        {!template.fields?.update?.hidden &&
          DetailsField(
            template.fields?.update?.label?.[language] ||
              (t('collection_item.label_fields.does_update') as string),
            t(`boolean.${contract?.update?.toString() === 'creator'}`)
          )}
        {!template.fields?.smartContract?.hidden &&
          DetailsField(
            template.fields?.smartContract?.label?.[language] ||
              (t('collection_item.label_fields.smart_contract') as string),
            truncateText(contract?.address || '', {
              maxLength: 15,
              rightChars: 0,
            }),
            true,
            contract?.address || ''
          )}
        {DetailsField('Token ID', nft.id)}
      </div>
    </div>
  );
};

export default NftDetailsCard;
