import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { Button, Typography } from '../../../../components';
import { useGetGroups } from '../../../../hooks/useGetGroups';
import { IconUserPlus } from '../../../../icons';
import { useAppSelector } from '../../../../redux/hooks';
import { WorkspaceMember } from '../../imports/types';
import { ManageTeamsTableBody } from '../index';
import { useManageTeamsContext } from '../../pages/manage-teams/ManageTeamsContext';

type GroupsWrapperProps = {
  groupMembers: WorkspaceMember[];
  role: string;
  setValue: UseFormSetValue<any>;
  workspaceIsOwner: boolean;
};

const GroupsWrapper = ({ role, setValue, workspaceIsOwner, groupMembers }: GroupsWrapperProps) => {
  const workspaceId = useAppSelector((state) => state.team?.workspace?.id);
  const groupId = useAppSelector((state) => state.team?.workspace?.activeGroupId);
  const isOwner = useAppSelector((state) => state.team?.workspace?.isOwner);
  const uid = useAppSelector((state) => state.user?.uid);
  const { open: openAddUserToGroup } = useModal('AddUserToGroupModal');
  const { t } = useTranslation(['team']);
  const { data, handleAddUserToGroup } = useManageTeamsContext();
  const { groups, isLoading, isError } = useGetGroups(workspaceId);
  const currentUser = groupMembers.find((member) => member.user === uid);
  const isCurrentUserAdmin = currentUser?.isGroupAdmin;

  const changeSelection = (
    selection: string,
    index: number | string,
    module: 'token_creator' | 'notarization' | 'audit'
  ) => {
    if (selection === 'viewer') {
      setValue(`${index}.${module}.write`, false);
      setValue(`${index}.${module}.read`, true);
    }
    if (selection === 'editor') {
      setValue(`${index}.${module}.write`, true);
      setValue(`${index}.${module}.read`, true);
    }
    if (selection === 'no_access') {
      setValue(`${index}.${module}.write`, false);
      setValue(`${index}.${module}.read`, false);
    }
  };

  return (
    <>
      {groups && groups.length > 0 ? (
        groups
          ?.filter((group) => {
            if (!isOwner) {
              return group.id === groupId;
            }
            return group;
          })
          .map((group) => (
            <div className="my-2" key={group.id}>
              <div className="flex flex-col rounded border-DEFAULT border-primary-100 ">
                <div className="flex flex-row items-center justify-between border-b-DEFAULT border-primary-100 p-2">
                  <Typography size="body-medium-18" weight="medium">
                    {group.name}
                  </Typography>
                  {(workspaceIsOwner || isCurrentUserAdmin) && (
                    <Button
                      width="2em"
                      height="2em"
                      type="ghost-dark"
                      iconStroke="primary-500"
                      icon={IconUserPlus}
                      action={() => {
                        openAddUserToGroup({
                          groupMembers: data.groupMembers,
                          handleAddUserToGroup,
                          members: data.members,
                          groupId: group.id,
                          groupName: group.name,
                        });
                      }}
                    />
                  )}
                </div>
                <div>
                  {groupMembers.length > 0 ? (
                    groupMembers
                      ?.filter((member: WorkspaceMember) =>
                        member.groupIds?.includes(String(group?.id))
                      )
                      ?.map((member, i) => {
                        return (
                          <ManageTeamsTableBody
                            member={member}
                            index={i}
                            role={role}
                            changeSelection={changeSelection}
                            workspaceIsOwner={workspaceIsOwner}
                          />
                        );
                      })
                  ) : (
                    <Typography size="body-medium-18" weight="medium">
                      {t('errors.no_members')}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          ))
      ) : (
        <Typography size="body-medium-18" weight="medium">
          {t('errors.no_groups')}
        </Typography>
      )}
    </>
  );
};

export default GroupsWrapper;
