import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  CustomTooltip,
  Field,
  FileUploader,
  Input,
  Select,
  Typography,
} from '../../../../../components';
import { IconClose } from '../../../../../icons';
import { getRemoteConfigValue } from '../../../../../imports/remoteConfig';
import { renameFile } from '../../../../../imports/utils';
import { CustomFieldsProps } from '../../../imports/types';
import { isFileTypeImage } from '../../../imports/utils';
import { useAppSelector } from '../../../../../redux/hooks';
import i18n from '../../../../../i18n';

const PartnerCustomField = ({
  errors,
  category,
  index,
  values,
  // customLabels,
  fieldLabel,
  resetField,
  fieldValue,
  setValue,
  fieldLink,
  fieldType,
  fieldPrivate,
  options,
}: CustomFieldsProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const template = useAppSelector((state) => state.template);
  const language = i18n.language;

  const { nfts } = values;
  const attributeType = nfts?.[category]?.attributes?.[index]?.type;
  const attributes = values.nfts[category].attributes;
  const allowedFileType: { [key: string]: string[] } = getRemoteConfigValue('allowedFiles', 'json');
  const [image, setImage] = useState<File[]>([]);
  const attributeCustomType = nfts?.[category]?.attributes?.[index]?.customType;
  const templateField = template.customFields.find(
    (field: any) => field.value === attributeCustomType
  );
  const handleFileChange = (files: File[]) => {
    setImage(files);
    if (files.length > 0) {
      files.forEach((file) => {
        if (isFileTypeImage(file)) {
          setValue(
            `nfts[${category}].attributes[${index}].file`,
            files.map((file) => renameFile(file, file.name.replace(' ', '_')))
          );
          setValue(`nfts[${category}].attributes[${index}].link`, '');
        } else {
          setValue(`nfts[${category}].attributes[${index}].file`, []);
          toast.error(`${t('create_contract.errors.file_format')}`);
        }
      });
    } else {
      setValue(`nfts[${category}].attributes[${index}].file`, []);
    }
  };

  useEffect(() => {
    setValue(`nfts[${category}].attributes[${index}].file`, image);
  }, [image, setValue, index]);
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="felx-row flex gap-2 ">
        <Field
          error={errors.nfts?.[category]?.attributes?.[index]?.type?.message}
          label={t('create_contract.form_labels.attribute')}
          className="!w-1/2"
        >
          <Select
            options={options}
            error={errors.nfts?.[category]?.attributes?.[index]?.type?.message}
            name={fieldType.name}
            // onBlur={fieldType.onBlur}
            onChange={(change) => {
              const customFieldFound = template.customFields.find(
                (field: any) => field.value === change.target.value
              );
              // console.log(customFieldFound);
              setValue(`nfts[${category}].attributes[${index}].customType`, change.target.value);
              setValue(`nfts[${category}].attributes[${index}].type`, customFieldFound?.type);
              if (customFieldFound?.fields.trait_type?.value) {
                setValue(
                  `nfts[${category}].attributes[${index}].trait_type`,
                  customFieldFound?.fields.trait_type?.value
                );
              } else {
                setValue(`nfts[${category}].attributes[${index}].trait_type`, '');
              }
            }}
            inputRef={attributeType.ref}
            defaultValue={
              options.find(
                (option) => option.value === nfts?.[category]?.attributes?.[index]?.customType
              )?.value
            }
          />
        </Field>
        <div className="mb-2 flex w-1/2 items-end justify-between">
          <div className="flex items-center">
            <Typography>{t('create_contract.form_labels.private_field') as string}</Typography>
            <CustomTooltip>
              <Typography size="xs">
                {t('create_contract.form_tooltips.private_field') as string}
              </Typography>
            </CustomTooltip>
          </div>

          <Checkbox
            name={fieldPrivate.name}
            id={fieldPrivate.name}
            onChange={fieldPrivate.onChange}
            inputRef={fieldPrivate.ref}
            onBlur={fieldPrivate.onBlur}
            isChecked={values.nfts?.[category]?.attributes?.[index]?.private}
          />
        </div>
      </div>
      {/* SECOND ROW */}
      <div className="flex flex-col">
        <div className="flex gap-2">
          <Field
            error={errors.nfts?.[category]?.attributes?.[index]?.trait_type?.message}
            label={templateField?.fields.trait_type?.label?.[language]}
            className="!w-1/2"
          >
            <Input
              type="text"
              placeholder={t('create_contract.form_placeholders.custom_field_label')}
              name={fieldLabel.name}
              onBlur={fieldLabel.onBlur}
              onChange={fieldLabel.onChange}
              inputRef={fieldLabel.ref}
              error={errors.nfts?.[category]?.attributes?.[index]?.trait_type?.message}
              elementRight={
                <Button type="ghost" icon={IconClose} action={() => resetField(fieldLabel.name)} />
              }
              disabled={templateField?.fields.trait_type?.disabled}
            />
          </Field>
          <Field
            error={errors.nfts?.[category]?.attributes?.[index]?.value?.message}
            label={t('create_contract.form_labels.external_url')}
            className="!w-1/2"
          >
            <Input
              type="text"
              placeholder={t('create_contract.form_placeholders.external_url')}
              name={fieldValue.name}
              onBlur={fieldValue.onBlur}
              onChange={fieldValue.onChange}
              inputRef={fieldValue.ref}
              error={errors.nfts?.[category]?.attributes?.[index]?.value?.message}
              elementRight={
                <Button type="ghost" icon={IconClose} action={() => resetField(fieldValue.name)} />
              }
            />
          </Field>
        </div>
      </div>
      {/* THIRD ROW */}
      <Typography size="body-regular-16" className="mt-4" weight="medium">
        {t('create_contract.form_labels.load_partner')}
      </Typography>
      <div className="flex flex-col gap-2">
        <Field
          error={
            !nfts?.[category]?.attributes?.[index]?.file?.[0] &&
            errors.nfts?.[category]?.attributes?.[index]?.link?.message
          }
          label={t('create_contract.form_labels.image_link')}
        >
          <Input
            type="text"
            placeholder={t('create_contract.form_placeholders.link')}
            name={fieldLink.name}
            onBlur={fieldLink.onBlur}
            onChange={fieldLink.onChange}
            inputRef={fieldLink.ref}
            elementRight={
              <Button type="ghost" icon={IconClose} action={() => resetField(fieldLink.name)} />
            }
            disabled={nfts?.[category]?.attributes?.[index]?.file?.[0]}
          />
        </Field>
        <div className="flex h-full items-center">
          <Typography size="body-regular-14" className="mx-auto">
            {t('or')}
          </Typography>
        </div>
        <Field
          label={t('create_contract.form_labels.load_partner_title')}
          error={errors.nfts?.[category]?.attributes?.[index]?.file?.message}
        >
          <FileUploader
            id={category + '-' + index}
            label={t('create_contract.form_placeholders.upload_image')}
            accept={{ 'image/*': allowedFileType['image/*'] }}
            defaultFiles={nfts?.[category]?.attributes?.[index]?.file}
            maxSize={5000000}
            checkName
            onChange={handleFileChange}
            onCancel={() => setValue(`nfts[${category}].attributes[${index}].file`, [])}
            ratio={['image', 'partner'].includes(attributes[index].type) ? '16:9' : 'all'}
          />
        </Field>
      </div>
    </div>
  );
};

export default PartnerCustomField;
