import { get, ref } from 'firebase/database';
import QRCode from 'qrcode';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Modal, { useModal, useModalState } from 'react-simple-modal-provider';
import { Icon, ItemOption } from '..';
import { IconEdit, IconInfo, IconOptions } from '../../icons';
import { redeemer } from '../../imports/constants';
import { database } from '../../imports/firebase';
import { formatIPFSUri } from '../../imports/utils';
import { Contract } from '../../modules/tokenCreator/imports/types';
import { useAppSelector } from '../../redux/hooks';
import { ModalBody } from '../modals/QrModal';

type NftTopCardProps = {
  contract: any;
  item: any;
  disabled?: boolean;
  isPreview?: boolean;
  isAnalytics?: boolean;
};

type QrModalProps = {
  children: ReactNode;
};

let externalIsOpen = false;

const QrModal = ({ children }: QrModalProps) => {
  const [isOpen, setOpen] = useModalState();
  useEffect(() => {
    externalIsOpen = isOpen;
  }, [isOpen]);

  return (
    <Modal id="QrModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

type TQrCodeFormatted = { id: number; key: string; image: string };

const NftTopCard = ({ contract, item, disabled, isPreview, isAnalytics }: NftTopCardProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const [openMenu, setOpenMenu] = useState(false);
  const { open, close } = useModal('QrModal');
  const navigate = useNavigate();
  const [qrCodesFormatted, setQrCodesFormatted] = useState<TQrCodeFormatted[] | null>(null);
  const { workspace } = useAppSelector((state) => state.team);
  const makeQrCodeVisibile = async (contract: Contract) => {
    if (contract.qrCodeDrop === 2) {
      const qrMockKey = '0x0000000000000000000000000000000000000000000000000000000000000000';
      setQrCodesFormatted(
        await Promise.all(
          [...Array(1)].map(async (value, index) => ({
            id: contract.qrCodes?.[0]?.id || index + 1,
            key: qrMockKey,
            image: await QRCode.toDataURL(`${redeemer}/redeem?id=${contract.id}&key=${qrMockKey}`),
          }))
        )
      );
      return;
    }
    // if (!contract.qrCodes?.length) return;
    if (contract.qrCodeDrop) {
      if (contract?.qrCodes) {
        setQrCodesFormatted(
          await Promise.all(
            contract?.qrCodes?.map(async ({ id, key }) => ({
              id,
              key,
              image: await QRCode.toDataURL(`${redeemer}/redeem?id=${contract.id}&key=${key}`),
            }))
          )
        );
      } else {
        const numberOfTokens = contract.maxSupplyPerRarity.length - 1;
        const values: TQrCodeFormatted[] = [];

        await Promise.all(
          new Array(numberOfTokens).fill(1).map(async (_, id: number) => {
            const catRef = ref(database, `${contract.id}/s/${id}`);
            const snapshot = await get(catRef);
            if (!snapshot.exists()) return null;
            await Promise.all(
              snapshot.val().map(async (snap: any) => {
                if (!snap?.s && !snap?.e) {
                  const { p } = snap;
                  const qrCodeFormatted: TQrCodeFormatted = {
                    id: id + 1,
                    key: p,
                    image: await QRCode.toDataURL(`${redeemer}/redeem?id=${contract.id}&key=${p}`),
                  };
                  values.push(qrCodeFormatted);
                }
              })
            );
            return null;
          })
        );

        setQrCodesFormatted(values);
      }
    }
  };
  useEffect(() => {
    if (!isPreview && contract.status === 'created') makeQrCodeVisibile(contract);
  }, [contract]);

  return (
    <>
      <div
        className={`flex size-full items-center justify-center rounded-lg ${
          item.image && `bg-cover bg-center`
        }`}
        onClick={() =>
          navigate(
            isAnalytics
              ? `/nft/analytics/${contract.id}/${item.id}`
              : `/nft/collection/${contract.id}/${item.id}`
          )
        }
        style={{
          backgroundImage: isPreview
            ? `url(${item.image[0].preview ?? item.image}`
            : `url(${formatIPFSUri(item.image)})`,
        }}
      ></div>
      {!disabled && (qrCodesFormatted?.length || contract.update === 'creator') ? (
        <div
          onClick={() => setOpenMenu(!openMenu)}
          className="absolute right-0 top-0 !z-50 size-[27px]  cursor-pointer items-center justify-between rounded-bl-lg rounded-tr-lg bg-white pl-1 pt-1"
        >
          <Icon icon={IconOptions} size="md" className="mx-auto" />
          <div
            onMouseEnter={() => setOpenMenu(true)}
            onMouseLeave={() => setOpenMenu(false)}
            className={`absolute left-0 top-0 z-10 mt-7 flex  w-[210px] flex-col rounded-lg border border-primary-500 bg-white ${
              openMenu ? 'block' : 'hidden'
            }`}
          >
            <div className="flex flex-col">
              {qrCodesFormatted?.length && (
                <ItemOption
                  id={`info_notarization-${item.id}`}
                  name={t('collection.options.download_QR')}
                  icon={IconInfo}
                  onClick={() =>
                    open({
                      contract,
                      contractId: contract.id,
                      dropDate: contract.dropDate,
                      nft: item,
                      contractImage: contract.useAsLogo ? contract.image : undefined,
                      // qrCodes:
                      //   contract.qrCodeDrop! === 2
                      //     ? [qrCodesFormatted?.[0]]
                      //     : qrCodesFormatted?.filter?.((qrCode) => qrCode.id === item.id)!,
                    })
                  }
                  // onClick={handleOpenDetail}
                />
              )}
              {workspace?.permissions?.token_creator?.write && contract.update === 'creator' && (
                <ItemOption
                  id={`update-token-${item.id}`}
                  name={t('collection.options.update_token')}
                  icon={IconEdit}
                  onClick={() => navigate(`/nft/update-collection/${contract.id}/${item.id}`)}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export { NftTopCard, QrModal };
