import { ethers } from 'ethers';
import { DocumentData, QuerySnapshot, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balanceOf } from '../abi/balanceOf';
import { rpcProvider } from '../imports/constants';
import ECollections from '../imports/enums';
import { getDocumentsFromSub } from '../imports/firestore.utils';
import { Contract, TSendFormValues } from '../modules/tokenCreator/imports/types';
import { sleep } from '../imports/utils';

interface IUseValidateSend {
  contract: Contract;
  setError: any;
}

const useValidateSend = ({ contract, setError }: IUseValidateSend) => {
  const { t } = useTranslation(['tokenCreator']);
  const [usersTransactions, setUsersTransactions] = useState<QuerySnapshot<DocumentData>>();

  /**
   * Scans the db and looks for the total number of tokens owned by every address
   */
  const getAddress2QuantityOwned = async (
    values: TSendFormValues['values'],
    contractAddress: string
  ) => {
    const map = new Map<string, number>([]);
    const contract = new ethers.Contract(
      contractAddress,
      balanceOf,
      new ethers.providers.JsonRpcProvider(rpcProvider)
    );

    /* eslint-disable no-await-in-loop */
    for (const { address, category, quantity } of values) {
      const chainValue = parseInt(await contract.balanceOf(address, category), 10);
      map.set(address, chainValue);
      await sleep(500);
    }
    /* eslint-enable no-await-in-loop */

    return map;
  };

  /**
   * Scans the form and gets the total number of token per category
   */
  const getCategory2quantity = (values: TSendFormValues['values']) => {
    const map = new Map<string, number>([]);
    values.forEach(({ category, quantity }) => {
      const oldValue = map.get(category) ? map.get(category) : 0;
      map.set(category, quantity + oldValue!);
    });
    return map;
  };

  /**
   * Scans the db and looks for the total number of tokens owned by every address
   */
  const getCategory2QuantityOwned = async (usersTransactions: QuerySnapshot<DocumentData>) => {
    const map = new Map<string, number>([]);

    await Promise.all(
      usersTransactions.docs.map(async (doc) => {
        const data = doc.data();
        const oldValue = map.get(data.category) ? map.get(data.category) : 0;
        map.set(data.category, data.quantity + oldValue);
      })
    );

    return map;
  };

  /**
   * Sums up two mapping
   */
  const compareMappings = (first: Map<string, number>, second: Map<string, number>) => {
    const map = new Map<string, number>([]);
    first.forEach((value, key, _) => {
      const ownedByAddress = second.get(key) ? second.get(key) : 0;
      map.set(key, ownedByAddress! + value);
    });
    return map;
  };

  const validateForm = async (values: TSendFormValues['values']): Promise<boolean> => {
    if (!usersTransactions) return false;
    /**
     * CONDITION 1
     * (address, category) must be unique
     */
    const address2Category = new Map<string, string[]>([]);
    const indexesCond1: number[] = [];
    values.forEach(({ address, category }, i) => {
      if (address2Category.get(address)?.includes(category)) {
        indexesCond1.push(i);
      } else {
        address2Category.set(address, [category]);
      }
    });

    indexesCond1.forEach((index) =>
      setError(`values.${index}.address`, {
        type: 'maxLength',
        message: t('send.errors.address.category'),
      })
    );

    if (indexesCond1.length > 0) return false;

    /**
     * CONDITION 2
     * User cannot transfer more than maxTokensPerUser for every user
     */
    const maxTokensPerUser = contract?.maxTokensPerUser;

    const address2TotalQuantity = await getAddress2QuantityOwned(values, contract.address);

    values.forEach((value) => {
      const mapValue = address2TotalQuantity.get(value.address);
      address2TotalQuantity.set(value.address, (mapValue ?? 0) + value.quantity);
    });

    const indexesCond2: number[] = [];
    address2TotalQuantity.forEach((value, key, _) => {
      if (value <= maxTokensPerUser) return;

      values.forEach((el, i) => {
        if (el.address === key) indexesCond2.push(i);
      });

      const owned =
        address2TotalQuantity.get(key) ??
        0 - (values.find((value) => value.address === key)?.quantity ?? 0);

      const remaining = maxTokensPerUser - owned;

      indexesCond2.forEach((index) =>
        setError(`values.${index}.address`, {
          type: 'maxLength',
          message: t('send.errors.address.limit', { offeset: Math.max(0, remaining) }),
        })
      );
    });

    if (indexesCond2.length > 0) return false;

    /**
     * CONDITION 3
     * Total number of tokens per category <= maxSupplyPerRarity - tokens already sent
     */

    const { maxSupplyPerRarity } = contract;
    const category2quantity = getCategory2quantity(values);
    const category2QuantityOwned = await getCategory2QuantityOwned(usersTransactions);
    const category2TotalQuantity = compareMappings(category2quantity, category2QuantityOwned);

    const indexesCond3: number[] = [];
    category2TotalQuantity.forEach((value, key, _) => {
      const maxSupply = maxSupplyPerRarity[Number(key)];
      if (value <= maxSupply) return;

      values.forEach((el, i) => {
        if (el.category === key) indexesCond3.push(i);
      });

      const supplyRemaining = Math.max(
        0,
        maxSupplyPerRarity[Number(key)] - (category2QuantityOwned.get(key) ?? 0)
      );

      indexesCond3.forEach((index) =>
        setError(`values.${index}.address`, {
          type: 'maxLength',
          message: t('send.errors.address.supply', { number: supplyRemaining }),
        })
      );
    });

    if (indexesCond3.length > 0) return false;

    return true;
  };

  useEffect(() => {
    if (!contract) return;
    getDocumentsFromSub(
      ECollections.Contracts,
      contract.id,
      '/manual',
      where('status', '!=', 'failed')
    )
      .then((data) => setUsersTransactions(data))
      .catch((err) => console.error(err));
  }, [contract]);

  return validateForm;
};

export default useValidateSend;
