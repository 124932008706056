import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Contract, Nft, NftAttribute, Template } from '../../imports/types';

import { ImageBilling, ImagePlaceholder } from '../../../../assets/images';

import {
  Button,
  Image,
  SectionTitle,
  ShowMore,
  TagInput,
  Textarea,
  Typography,
} from '../../../../components';
import { ExportedProps } from '../../../../components/form/Form';

import { getRemoteConfigValue } from '../../../../imports/remoteConfig';
import NftComponent from './NftComponent';

interface INftStep
  extends Omit<
    ExportedProps,
    'fields' | 'trigger' | 'reset' | 'getValues' | 'clearErrors' | 'isValid' | 'dirtyFields'
  > {
  isLoading: boolean;
  action: () => void;
  toggleIsBillingOverlayOpen?: () => void;
  onBack: () => void;
  values: any;
  title: string;
  templates?: Template[];
  ctaLabel: string;
  topBarElements?: (nft: Nft) => ReactNode;
  quantityModifiable?: boolean;
  dirtyFields: any;
  fields: any;
  contract: Contract | undefined;
  tokenId?: string;
}
const UpdateNftsStep: React.FC<INftStep> = ({
  errors,
  handleSubmit,
  resetField,
  templates,
  control,
  register,
  setValue,
  isLoading,
  title,
  action: handleSaveContract,
  toggleIsBillingOverlayOpen,
  onBack,
  values,
  ctaLabel,
  topBarElements,
  quantityModifiable = true,
  dirtyFields,
  fields,
  watch,
  contract,
  tokenId,
}) => {
  const { t } = useTranslation(['tokenCreator']);
  const { nfts, doesExpire } = values;
  const valuesForNftComponent = {
    ...values,
    doesTransfer: contract?.transfer !== 'none',
    chain: contract?.chain,
    maxTokensPerUser: contract?.maxTokensPerUser,
    expiration: contract?.expiration,
    hasGame: contract?.hasGame,
    dropDate: contract?.dropDate,
    numberOfCategories: contract?.numberOfCategories,
  };

  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');
  const [howManyNotarizations, setHowManyNotarizations] = useState(0);

  useEffect(() => {
    let howMany: number = 0;
    nfts.forEach((nft: Nft) => {
      let partialHowMany = 0;
      if (nft.attributes) {
        nft.attributes.forEach((attribute: NftAttribute) => {
          if (attribute.type === 'notarizations') {
            partialHowMany += 1;
          }
        });
      }
      if (partialHowMany > howMany) {
        howMany = partialHowMany;
      }
    });
    setHowManyNotarizations(howMany);
  }, [values]);

  return (
    <>
      <SectionTitle title={title} onBack={onBack}>
        {toggleIsBillingOverlayOpen && (
          <button type="button" onClick={toggleIsBillingOverlayOpen}>
            <Image src={ImageBilling} alt={t('billing')} className="size-10" />
          </button>
        )}
      </SectionTitle>
      <ShowMore localMax={10} data={nfts} buttonLabel={t('show_more')}>
        {(nft, index) => (
          <div className="mx-auto mt-10 w-4/5" key={index}>
            <NftComponent
              values={valuesForNftComponent}
              nft={nft}
              index={index}
              templates={templates}
              quantityModifiable={quantityModifiable}
              errors={errors}
              resetField={resetField}
              register={register}
              setValue={setValue}
              control={control}
              key={index}
              dirtyFields={dirtyFields}
              fields={fields}
              watch={watch}
              doesExpire={doesExpire}
            />
          </div>
        )}
      </ShowMore>
      <div className="mt-20 flex justify-center">
        <Button
          id="start-creation"
          action={() => handleSubmit(handleSaveContract)()}
          disabled={isLoading || howManyNotarizations > 1}
        >
          {ctaLabel}
        </Button>
      </div>
    </>
  );
};

export default UpdateNftsStep;
