import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useModal } from 'react-simple-modal-provider';
import { Icon, ItemOption } from '..';
import { IconEdit, IconFileAudit, IconInfo, IconOptions, IconStamp } from '../../icons';

type AuditTopCardProps = {
  item: any;
  hasWritePermission?: boolean;
  notarized?: boolean;
  notarizations: any;
  receipts: any;
};

const AuditTopCard = ({
  item,
  hasWritePermission,
  notarized,
  notarizations,
  receipts,
}: AuditTopCardProps) => {
  const { t } = useTranslation(['audit']);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const { open: openModalDetail } = useModal('AuditDetailsModal');
  const { open: openModalRename } = useModal('AuditRenameModal');

  const handleOpenRenameAudit = (audit: any) => {
    openModalRename({ audit });
  };
  const goTo = (page: string) => {
    navigate(page);
  };

  const { open: openModalDeleteAudit } = useModal('DeleteAuditModal');
  const handleOpenDeleteAudit = () => {
    openModalDeleteAudit({ audit: item });
  };

  const handleOnClick = () => {
    if (notarized) {
      openModalDetail({ audit: item, hasWritePermission, notarizations, receipts, goTo });
    } else {
      navigate(`/audit-db/create-audit?auditId=${item.auditId}`);
    }
  };
  return (
    <>
      <div
        className="flex size-full items-center justify-center"
        onClick={() => handleOnClick()}
        // onClick={() => navigate(`/audit-db/create-audit?auditId=${item.auditId}`)}
      >
        {item.status === 'pending' && <Icon icon={IconFileAudit} size="lg" />}
        {item.status === 'completed' && <Icon icon={IconStamp} size="lg" />}
      </div>
      <div
        onClick={() => setOpenMenu(!openMenu)}
        className="absolute right-0 top-0 size-[27px] cursor-pointer items-center justify-between pt-2"
      >
        <Icon icon={IconOptions} size="md" />
        <div
          onMouseEnter={() => setOpenMenu(true)}
          onMouseLeave={() => setOpenMenu(false)}
          className={`absolute left-0 top-0 z-10 mt-7 flex w-[210px] flex-col rounded-lg border border-primary-500 bg-white ${
            openMenu ? 'block' : 'hidden'
          }`}
        >
          <div className="flex flex-col">
            <ItemOption
              id={`info_notarization-${item.id}`}
              name={t('collection.info_notarization')}
              icon={IconInfo}
              onClick={handleOnClick}
            />
            {/* <ItemOption
              id={`share_notarization-${item.id}`}
              name={t('collection.share_notarization')}
              icon={IconShare}
            /> */}

            {item.status === 'pending' && hasWritePermission && (
              <ItemOption
                id={`rename_notarization-${item.id}`}
                name={t('collection.rename_notarization')}
                icon={IconEdit}
                onClick={() => handleOpenRenameAudit(item)}
              />
            )}
            {item.status === 'completed' && hasWritePermission && (
              <ItemOption
                id={`delete_notarization-${item.id}`}
                name={t('collection.delete_notarization')}
                color="error"
                onClick={handleOpenDeleteAudit}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AuditTopCard;
