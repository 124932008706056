import { ReactNode, useState } from 'react';

type BasicCardProps = {
  top: ReactNode;
  bottom: ReactNode;
  onClick?: () => void;
  className?: string;
};

const BasicCard = ({ top, bottom, onClick, className }: BasicCardProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={` flex h-[150px] w-[173px] flex-col  rounded-lg border ${
        isHover && 'border-primary-500'
      }  shadow-md ${className}`}
    >
      <div className="relative flex size-full cursor-pointer rounded-t-lg">
        {top}
        <div
          className={`absolute bottom-0 h-auto w-full flex-row gap-x-1 rounded-b-lg border-t bg-white p-1 ${
            isHover && 'border-t-primary-500 bg-primary-100'
          } `}
        >
          {bottom}
        </div>
      </div>
    </div>
  );
};
export default BasicCard;
