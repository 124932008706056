import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query } from 'firebase/firestore';

import { toast } from 'react-toastify';
import * as yup from 'yup';

import { getUserDoc, logOut, signIn, updateUserDoc } from '../../api/firebase';
import { useValidation } from '../../hooks';
import { getAuthErrorMessage } from '../../imports/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logOutTeam, setWorkspace } from '../../redux/team/team.slice';
import { logOutUser, signIn as signInAction } from '../../redux/user/user.slice';

import { Button, Field, Form, Input, Sidebar, Typography } from '../../components';
import type { ExportedProps } from '../../components/form/Form';
import LinkText from '../../components/linkText/LinkText';
import { IconClose, IconInvisible, IconVisible } from '../../icons';
import api from '../../imports/axios';
import { auth, db } from '../../imports/firebase';
import { getWorkspaceCustomers } from '../../modules/team/api/api';

type AccountStepProps = Omit<
  ExportedProps,
  | 'handleSubmit'
  | 'trigger'
  | 'setValue'
  | 'watch'
  | 'control'
  | 'register'
  | 'reset'
  | 'getValues'
  | 'clearErrors'
  | 'isValid'
  | 'dirtyFields'
> & {
  showInputPassword: boolean;
  setShowInputPassword: (value: boolean) => void;
};

const AccountStep = ({
  fields,
  errors,
  resetField,
  showInputPassword,
  setShowInputPassword,
}: AccountStepProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Field asterisk error={errors.email?.message} label={t('email')} textSize="body-regular-18">
        <Input
          className="mt-[5px] text-body-regular-16"
          type="text"
          placeholder={t('authentication.form_placeholders.email')}
          id={fields.email.name}
          name={fields.email.name}
          onBlur={fields.email.onBlur}
          onChange={fields.email.onChange}
          inputRef={fields.email.ref}
          error={errors.email?.message}
        />
      </Field>
      <Field
        error={errors.password?.message}
        asterisk
        label={t('password')}
        textSize="body-regular-18"
        className="mt-[34px] "
      >
        <Input
          className="mt-[5px] text-body-regular-16"
          type={showInputPassword ? 'text' : 'password'}
          placeholder={t('authentication.form_placeholders.password')}
          id={fields.password.name}
          name={fields.password.name}
          onBlur={fields.password.onBlur}
          onChange={fields.password.onChange}
          inputRef={fields.password.ref}
          error={errors.password?.message}
          // tooltip
          elementRight={
            <Button
              type="ghost"
              icon={showInputPassword ? IconVisible : IconInvisible}
              action={() => setShowInputPassword(!showInputPassword)}
              className="mt-[5px] "
            />
          }
        />
        <LinkText
          to="/forgot-password"
          className="mr-0 mt-[8px] block w-full cursor-pointer justify-end text-right  hover:underline"
          direction="right"
          iconSize="18"
          textSize="text-body-medium-14"
        >
          {t('authentication.forgot.question') as string}
        </LinkText>
      </Field>
    </>
  );
};

type LegalFieldsStepProps = Omit<
  ExportedProps,
  | 'handleSubmit'
  | 'trigger'
  | 'setValue'
  | 'watch'
  | 'control'
  | 'register'
  | 'clearErrors'
  | 'reset'
  | 'getValues'
  | 'isValid'
  | 'dirtyFields'
>;

const LegalFieldsStep = ({ fields, errors, resetField }: LegalFieldsStepProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Field error={errors.company?.message}>
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.company')}
          id={fields.company.name}
          name={fields.company.name}
          onBlur={fields.company.onBlur}
          onChange={fields.company.onChange}
          inputRef={fields.company.ref}
          error={errors.company?.message}
        />
      </Field>

      <Field error={errors.vat?.message}>
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.vat_number')}
          id={fields.vat.name}
          name={fields.vat.name}
          onBlur={fields.vat.onBlur}
          onChange={fields.vat.onChange}
          inputRef={fields.vat.ref}
          error={errors.vat?.message}
          elementRight={
            <Button type="ghost" icon={IconClose} action={() => resetField(fields.vat.name)} />
          }
        />
      </Field>

      <Field error={errors.sdi?.message}>
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.sdi')}
          id={fields.sdi.name}
          name={fields.sdi.name}
          onBlur={fields.sdi.onBlur}
          onChange={fields.sdi.onChange}
          inputRef={fields.sdi.ref}
          error={errors.sdi?.message}
          elementRight={
            <Button type="ghost" icon={IconClose} action={() => resetField(fields.sdi.name)} />
          }
        />
      </Field>

      <Field error={errors.office?.message}>
        <Input
          type="text"
          placeholder={t('authentication.form_placeholders.office')}
          id={fields.office.name}
          name={fields.office.name}
          onBlur={fields.office.onBlur}
          onChange={fields.office.onChange}
          inputRef={fields.office.ref}
          error={errors.office?.message}
          elementRight={
            <Button type="ghost" icon={IconClose} action={() => resetField(fields.office.name)} />
          }
        />
      </Field>
    </>
  );
};

const SignIn = () => {
  const { t } = useTranslation();
  const [showInputPassword, setShowInputPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const profile = useAppSelector((state) => state.user);

  const isAccountSignInStep = profile.role === 'guest';
  const isLegalFieldsStep = profile.role === 'taker';

  const { required, validateEmail, validatePassword, validateVat } = useValidation();

  const signInForm = {
    initialValues: {
      email: '',
      password: '',
      company: '',
      office: '',
      vat: '',
      sdi: '',
    },
    validationSchema: yup.object({
      email: isAccountSignInStep ? required(validateEmail()) : validateEmail(),
      password: isAccountSignInStep ? required(validatePassword()) : yup.string(),
      company: isLegalFieldsStep ? required(yup.string()) : yup.string(),
      office: isLegalFieldsStep ? required(yup.string()) : yup.string(),
      vat: isLegalFieldsStep ? required(validateVat()) : yup.string(),
      sdi: isLegalFieldsStep ? required(yup.string()) : yup.string(),
    }),
  };

  const { initialValues, validationSchema } = signInForm;

  const showErrorToast = (error: { code: string }) => {
    toast.error(t(`authentication.errors.${getAuthErrorMessage(error.code)}`) as string);
  };

  const handleAuthenticate = async (values: { email: string; password: string }) => {
    const { email, password } = values;

    setIsLoading(true);
    const { value: valueSignIn, error } = await signIn({ email, password });

    if (error) {
      showErrorToast(error);
      setIsLoading(false);
      return;
    }

    const { profile, team } = valueSignIn;
    console.log('🚀 ~ handleAuthenticate ~ team:', team);
    const { value: valueProfileDoc } = await getUserDoc(profile.uid);

    if (valueProfileDoc) {
      const { isEmailVerified } = profile;
      const { company, vat, sdi, office } = valueProfileDoc;

      const isLegalFieldsFilled = company && vat && sdi && office;

      const getRole = () => {
        if (!isEmailVerified && process.env.REACT_APP_ENV !== 'LOCAL') {
          return 'unverified';
        }

        if (isLegalFieldsFilled) {
          return 'consumer';
        }

        return 'taker';
      };

      const customers = await getWorkspaceCustomers(team?.owner || '');
      dispatch(
        setWorkspace({
          ...team,
          ownerAddress: team.owner_address,
          isOwner: team.owner === profile.uid,
          customers,
        })
      );
      const signInData = await signInAction({ profile: { ...profile, role: getRole() } });
      if (!signInData?.payload.error) {
        dispatch(signInData);
        window.postMessage('userlogged');
      } else {
        navigate('/sign-in'); // usefirebasequery breaks when you logout when subscribed to a collection
        dispatch(logOutUser());
        dispatch(logOutTeam());
        await logOut();
      }
    }

    setIsLoading(false);
  };

  const handleUpdateProfile = async (values: {
    company: string;
    office: string;
    vat: string;
    sdi: string;
  }) => {
    setIsLoading(true);

    const { value, error } = await updateUserDoc(profile.uid, values);

    setIsLoading(false);

    if (error) {
      showErrorToast(error);
      setIsLoading(false);
      return;
    }

    const { company, vat, sdi, office } = value;
    dispatch(
      signInAction({ profile: { ...profile, company, vat, sdi, office, role: 'consumer' } })
    );

    const idToken = await auth.currentUser?.getIdToken();

    if (idToken) {
      await api.post(
        '/trial',
        {},
        {
          headers: {
            authorization: idToken,
          },
        }
      );
    }
    navigate('/notarizations');
  };

  const handleSignIn = async (values: {
    email: string;
    password: string;
    company: string;
    office: string;
    vat: string;
    sdi: string;
  }) => {
    const { email, password, company, office, vat, sdi } = values;

    if (isAccountSignInStep) {
      handleAuthenticate({ email, password });
    }

    if (isLegalFieldsStep) {
      handleUpdateProfile({ company, office, vat, sdi });
    }
  };

  return (
    <>
      {/* <ModalLayout></ModalLayout> */}

      <Sidebar topElement="logo" bottomElement="text" />
      <div className="absolute flex  min-h-full  flex-col justify-center gap-[60px] pl-[42%]">
        <Typography
          as="h1"
          size="body-semibold-30"
          color="grey-600"
          weight="semibold"
          className="w-[31.25rem] "
        >
          {t('authentication.sign_in')}
        </Typography>

        <div className=" flex w-[450px] max-w-md flex-col items-center justify-center">
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className="flex w-full flex-col "
          >
            {({ fields, handleSubmit, errors, resetField, isValid }) => (
              <>
                {isAccountSignInStep && (
                  <AccountStep
                    fields={fields}
                    errors={errors}
                    resetField={resetField}
                    showInputPassword={showInputPassword}
                    setShowInputPassword={setShowInputPassword}
                  />
                )}

                {isLegalFieldsStep && (
                  <LegalFieldsStep fields={fields} errors={errors} resetField={resetField} />
                )}

                <Button
                  id="signin-button"
                  type="primary"
                  action={() => handleSubmit(handleSignIn)()}
                  // loading={isLoading}
                  className="ml-0 mt-[60px] !max-w-[30%] "
                  disabled={!isValid || isLoading}
                >
                  {t('authentication.sign_in')}
                </Button>
              </>
            )}
          </Form>
          {!isAccountSignInStep && (
            <Button disabled={isLoading} action={() => dispatch(logOutUser())} type="secondary">
              {t('authentication.go_back')}
            </Button>
          )}

          <div className="mt-[40px] h-px w-full bg-grey-400"></div>

          <Typography
            size="body-regular-16"
            color="grey-400"
            className="mt-[22px] w-full text-left"
          >
            {t('authentication.not_account')}
          </Typography>
          <LinkText
            to="/sign-up "
            className="mt-[22px] w-full text-left"
            direction="right"
            textSize="body-medium-18"
            iconSize="19"
          >
            {t('authentication.create_account')}
          </LinkText>
        </div>
      </div>
    </>
  );
};

export default SignIn;
