import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { ethers } from 'ethers';
import { PablockSDK } from 'pablock-sdk';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Location as ReactLocation, useLocation, useNavigate } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { balanceOf } from '../../../../abi/balanceOf';
import { safeTransferFrom } from '../../../../abi/safe-transfer-from';
import { Button, Field, InnerPage, Input, SectionTitle, Select } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { useValidation } from '../../../../hooks';
import { IconClose, IconPlus } from '../../../../icons';
import {
  apiEndpoint,
  pablockApiKey,
  pablockConfig,
  rpcProvider,
} from '../../../../imports/constants';
import { auth } from '../../../../imports/firebase';
import { UserState } from '../../../../imports/types';
import { capitalizeFirstLetter, sleep } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';
import { Contract, Nft } from '../../imports/types';
import { bcodeSDK } from '../../../../imports/bcodeSDK';

interface Location<State> extends Omit<ReactLocation, 'state'> {
  state: State;
}

type CustomState = {
  contract?: Contract;
};

type SendFormValues = {
  values: {
    addressFrom: string;
    category: string;
    quantity: number;
    addressTo: string;
  }[];
};

const TransferNft = () => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { open: openLoadingModal } = useModal('LoadingModal');

  const { state: contract } = useLocation();
  // const contract = state?.contract!;

  const { dispatch: loadingContext, state } = useLoadingStatusContext();

  const { required } = useValidation();

  const {
    wallet: { privateKey, address: companyAddress },
    uid,
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
    getValues,
    setError,
    watch,
    control,
  } = useForm<SendFormValues>({
    defaultValues: {
      values: [{ addressFrom: '', category: '', quantity: 1, addressTo: '' }],
    },
    resolver: yupResolver(
      yup.object({
        values: yup.array().of(
          yup.object().shape({
            addressFrom: required(
              yup
                .string()
                .matches(/^0x[0-9a-fA-f]{40}$/g, t('send.errors.address.not_valid'))
                .transform((val) => val.toLowerCase())
            ),
            category: required(yup.string()),
            quantity: required(yup.number().min(1)),
            addressTo: required(
              yup
                .string()
                .matches(/^0x[0-9a-fA-f]{40}$/g, t('send.errors.address.not_valid'))
                .transform((val) => val.toLowerCase())
            ),
          })
        ),
      })
    ),
    mode: 'onSubmit',
  });

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'values',
  });

  const checkAvailability = async ({ values }: SendFormValues, contractAddress: string) => {
    const groupByAddressAndCategory = new Map();

    // Group by AddressFrom and Category
    values.forEach((value) => {
      const key = JSON.stringify({ from: value.addressFrom, category: value.category });
      const quantityOfKey = groupByAddressAndCategory.get(key);
      if (quantityOfKey == null) {
        groupByAddressAndCategory.set(key, value.quantity);
      } else {
        groupByAddressAndCategory.set(key, quantityOfKey + value.quantity);
      }
    });

    const contract = new ethers.Contract(
      contractAddress,
      balanceOf,
      new ethers.providers.JsonRpcProvider(rpcProvider)
    );

    await Promise.all(
      Array.from(groupByAddressAndCategory.entries()).map(async ([key, quantityRequested]) => {
        key = JSON.parse(key);
        const to = values.find((val) => val.addressFrom === key.from)?.addressTo;

        const quantity = Number(await contract.balanceOf(key.from, key.category));

        if (quantity < quantityRequested) {
          toast.error(t('transfer_nft.errors.not_enough') as string);
          throw new Error('Not enough quantity', {
            cause: { quantityRequested, quantity, from: key.from, to },
          });
        }
      })
    );
  };

  const handleError = (error: { cause: any }) => {
    const { cause } = error;
    const { values } = getValues();
    const index = values.findIndex(({ addressFrom }) => addressFrom === cause.from);
    if (index < 0) return;
    setError(`values.${index}.addressFrom`, {
      message: t('transfer_nft.errors.quantity', {
        quantity: cause.quantity,
      }),
    });
  };

  const handleNftsSend = async ({ values }: SendFormValues) => {
    loadingContext({
      type: 'SET_PENDING',
      payload: {
        message: t('send.send_action_pending'),
      },
    });
    openLoadingModal({ goTo: navigate, type: 'contract' });

    if (!bcodeSDK.isInitialized()) {
      await bcodeSDK.init();
    }

    bcodeSDK.setPrivateKey(privateKey);

    try {
      await checkAvailability({ values }, contract.address);

      for (const value of values) {
        let count = 0;
        for (const intValue of values) {
          if (JSON.stringify(intValue) === JSON.stringify(value)) {
            count += 1;
          }
        }
        if (count > 1) {
          toast.error(t('transfer_nft.errors.duplicated_tx') as string);
          throw new Error(t('Duplicated transaction'));
        }
        count = 0;
      }

      const nonce = await bcodeSDK.getNonce(bcodeSDK.getWalletAddress());

      let increment = 0;
      let lastRequestId = null;
      // await Promise.all(
      // values.map(async ({ addressFrom, category, quantity, addressTo }, index) =>
      for (const { addressFrom, category, quantity, addressTo } of values) {
        if (lastRequestId) {
          while (
            /* eslint-disable no-await-in-loop */
            !['mined', 'success'].includes((await bcodeSDK.getMetaTxStatus(lastRequestId)).status)
          ) {
            await sleep(1200);
          }
        }
        /* eslint-disable no-await-in-loop */
        const metaTx = await bcodeSDK.prepareTransaction(
          {
            address: contract.address,
            abi: safeTransferFrom,
            name: capitalizeFirstLetter(contract.name).replace(/\s/g, ''),
            version: '1.0',
          },
          'safeTransferFrom',
          [addressFrom, addressTo, category, quantity, ethers.utils.formatBytes32String('')],
          { nonce: nonce.toNumber() + increment }
        );

        /* eslint-disable no-await-in-loop */
        const firebaseToken = (await auth.currentUser?.getIdToken()) as string;
        const { requestId } =
          /* eslint-disable no-await-in-loop */
          (
            await axios.post(
              `${apiEndpoint}/executeTransfer`,
              {
                metaTx,
              },
              {
                headers: {
                  'x-access-token': firebaseToken,
                },
              }
            )
          ).data;

        console.info(requestId);
        lastRequestId = requestId;

        increment += 1;
      }

      loadingContext({
        type: 'SET_SUCCESS',
        payload: {
          title: t('send.send_action_success'),
        },
      });
    } catch (error) {
      if ((error as any).cause != null) {
        handleError(error as { cause: any });
      }

      loadingContext({
        type: 'SET_ERROR',
        payload: {
          message: t('send.send_action_failed'),
        },
      });
    }
  };

  if (!contract) return <></>;

  return (
    <>
      <InnerPage>
        <SectionTitle title={t('transfer_nft.title')} onBack={() => navigate(-1)} />
        <form>
          <div className="mt-8 space-y-4">
            {fields.map(({ id }, index) => {
              const addressFromField = register(`values.${index}.addressFrom`);
              const categoryField = register(`values.${index}.category`);
              const quantity = register(`values.${index}.quantity`);
              const categoryFieldValue = watch(`values.${index}.category`);
              const nft = contract.nfts.find((nft: Nft) => String(nft.id) === categoryFieldValue);
              const addressToField = register(`values.${index}.addressTo`);

              return (
                <div className="relative grid grid-cols-4 gap-x-8" key={index}>
                  {fields.length !== 1 && (
                    <Button
                      icon={IconClose}
                      className="absolute right-0 top-0"
                      action={() => {
                        remove(index);
                      }}
                      iconSize="sm"
                      type="ghost"
                    />
                  )}

                  <Field
                    label={t('transfer_nft.form_labels.address_from')}
                    error={errors.values?.[index]?.addressFrom?.message}
                  >
                    <Input
                      type="text"
                      placeholder={t('transfer_nft.form_placeholders.address_from')}
                      name={addressFromField.name}
                      onBlur={addressFromField.onBlur}
                      onChange={addressFromField.onChange}
                      inputRef={addressFromField.ref}
                      error={errors.values?.[index]?.addressFrom?.message}
                      elementRight={
                        <div className="flex items-center space-x-3 pr-6">
                          <span
                            role="button"
                            tabIndex={0}
                            className="bg-white font-medium underline "
                            onClick={() => setValue(addressFromField.name, companyAddress)}
                          >
                            Me
                          </span>

                          <Button
                            type="ghost"
                            icon={IconClose}
                            action={() => resetField(addressFromField.name)}
                          />
                        </div>
                      }
                    />
                  </Field>
                  <Field
                    label={t('transfer_nft.form_labels.category')}
                    error={errors.values?.[index]?.category?.message}
                  >
                    <Select
                      options={contract.nfts?.map((nft: Nft) => ({
                        value: String(nft.id),
                        label: nft.name,
                      }))}
                      name={categoryField.name}
                      onBlur={categoryField.onBlur}
                      onChange={categoryField.onChange}
                      inputRef={categoryField.ref}
                      error={errors.values?.[index]?.category?.message}
                    />
                  </Field>
                  <Field
                    label={t('transfer_nft.form_labels.quantity')}
                    error={errors.values?.[index]?.quantity?.message}
                  >
                    <Input
                      type="number"
                      minValue="1"
                      maxValue={nft?.quantity ? String(nft?.quantity) : ''}
                      placeholder={t('transfer_nft.form_placeholders.quantity')}
                      name={quantity.name}
                      onBlur={quantity.onBlur}
                      onChange={quantity.onChange}
                      inputRef={quantity.ref}
                      error={errors.values?.[index]?.quantity?.message}
                      elementRight={
                        <Button
                          type="ghost"
                          icon={IconClose}
                          action={() => resetField(quantity.name)}
                        />
                      }
                      disabled={!nft}
                    />
                  </Field>
                  <Field
                    label={t('transfer_nft.form_labels.address_to')}
                    error={errors.values?.[index]?.addressTo?.message}
                  >
                    <Input
                      type="text"
                      placeholder={t('transfer_nft.form_placeholders.address_to')}
                      name={addressToField.name}
                      onBlur={addressToField.onBlur}
                      onChange={addressToField.onChange}
                      inputRef={addressToField.ref}
                      error={errors.values?.[index]?.addressTo?.message}
                      elementRight={
                        <Button
                          type="ghost"
                          icon={IconClose}
                          action={() => resetField(addressToField.name)}
                        />
                      }
                    />
                  </Field>
                </div>
              );
            })}
          </div>
          <Button
            type="ghost"
            action={() => append({ addressFrom: '', category: '', quantity: 1, addressTo: '' })}
            icon={IconPlus}
            iconSize="lg"
            iconColor="grey-50"
            className="mx-auto"
            // loading={state.status === 'pending'}
          />
          <Button action={handleSubmit(handleNftsSend)} className="mx-auto mt-20 w-[17rem]">
            {t('transfer_nft.send_nfts')}
          </Button>
        </form>
      </InnerPage>
    </>
  );
};

export default TransferNft;
