import { isEqual } from 'lodash';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button, Form, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context/loading/LoadingContext';
import { CHAIN_MODULES } from '../../../../imports/constants';
import { useAppSelector } from '../../../../redux/hooks';
import { addGroup, grantPermission, grantPermissionsOnDb, handleBackendError } from '../../api/api';
import { FormType } from '../../common/add-group-form';
import {
  checkPermissionChanged,
  checkWritePermissionChanged,
  computeRoles,
} from '../../common/utils';
import { GroupCreationData, Permissions, WorkspaceMember } from '../../imports/types';
import { getInitialValues } from '../../pages/manage-teams/utils/manageTeamsUtils';
import { GroupsWrapper, ManageTeamsTableHeader } from '../index';

type WorkspaceGroupManageProps = {
  groupMembers: WorkspaceMember[] | undefined;
  pendingRequest: boolean;
};

const Groups = ({ groupMembers, pendingRequest }: WorkspaceGroupManageProps) => {
  const { t } = useTranslation(['team']);
  const {
    uid,
    wallet: { privateKey },
  } = useAppSelector((state) => state.user);
  const workspace = useAppSelector((state) => state.team.workspace);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();

  const { open: OpenAddWorkspaceGroupModal } = useModal('AddWorkspaceGroupModal');

  const handleChangePermissions = async (
    address: string,
    userId: string,
    permissions: Permissions
  ) => {
    if (workspace == null) return;
    try {
      const prevPermissions = groupMembers?.find(({ user }) => user === userId)
        ?.permissions as Permissions;
      if (checkPermissionChanged(prevPermissions, permissions)) {
        const writePermissionChanged = checkWritePermissionChanged(prevPermissions, permissions);
        // First value needs to be setted, doesn't mind if is true or false beacuse index 0 is not releated to any modules
        if (writePermissionChanged) {
          await grantPermission(privateKey, workspace?.id, address, userId, [
            false,
            ...CHAIN_MODULES.map((mod: string) => permissions[mod.toLowerCase()].write || false),
          ]);
        }

        const setDbPermissions = await grantPermissionsOnDb(
          workspace?.id,
          userId,
          computeRoles(permissions),
          writePermissionChanged
        );
        if (typeof setDbPermissions === 'object' && setDbPermissions.error) {
          throw new Error(setDbPermissions.error);
        }
      }
      // toast.success(t('manage.detail.change.success') as string);
    } catch (error) {
      handleBackendError(error);
    }
  };

  const handleAddGroup: SubmitHandler<FormType> = async (values) => {
    try {
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('manage.add_user.pending'),
        },
      });
      const group = {
        workspace_id: workspace?.id,
        name: values.name,
        isSuperGroup: values.isSuperGroup,
        created_at: Date.now(),
        updated_at: Date.now(),
      } as GroupCreationData;

      await addGroup(group);
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('manage.add_group.success'),
        },
      });
    } catch (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('manage.add_group.error'),
        },
      });
      console.error(error);
      handleBackendError(error);
    }
  };

  const onSubmit = (permissions: { [key: string]: Permissions }) => {
    if (workspace?.isOwner) {
      try {
        groupMembers?.forEach((member, i) =>
          handleChangePermissions(member!.address, member!.user, permissions[member!.user])
        );
        toast.success(t('manage.detail.change.success') as string);
      } catch (error) {
        handleBackendError(error);
      }
    } else toast.error(t('errors.request_not_authorized') as string);
    // qui ci dovrà essere la funzione per non poter modificare in attesa di approvazione
  };
  return (
    <div className={`mt-7 text-body-medium-18 `}>
      {pendingRequest && (
        <div className="absolute z-20 flex size-full items-center justify-center bg-white text-center opacity-80">
          <Typography
            size="body-medium-20"
            weight="medium"
            className="pointer-events-none m-auto text-center "
          >
            {t('errors.wait_pending_approvation')}
          </Typography>
        </div>
      )}

      <Form
        initialValues={getInitialValues(groupMembers ?? [])}
        validationSchema={yup.object({})}
        className={`size-full pb-5 ${pendingRequest && 'pointer-events-none opacity-30'}`}
      >
        {({ handleSubmit, setValue, watch, reset }) => {
          const role = watch();

          // const handleSelected = (permissions: { read: boolean; write: boolean }) => {
          //   if (permissions.write) return 'editor';
          //   if (!permissions.write && permissions?.read) return 'viewer';
          //   if (!permissions?.write && !permissions?.read) return 'no_access';
          // };

          return (
            <>
              <ManageTeamsTableHeader pendingRequest={false} />
              <GroupsWrapper
                setValue={setValue}
                groupMembers={groupMembers ?? []}
                role={role}
                workspaceIsOwner={workspace?.isOwner ?? false}
              />

              {workspace?.isOwner && (
                <div className="mt-[60px] flex justify-between">
                  <Button
                    type="secondary"
                    action={() => {
                      reset();
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <div className="flex justify-around gap-2">
                    <Button
                      action={() => {
                        OpenAddWorkspaceGroupModal({ handleAddGroup });
                      }}
                      type="primary"
                    >
                      {t('add_group')}
                    </Button>
                    <Button
                      disabled={isEqual(role, getInitialValues(groupMembers ?? []))}
                      action={handleSubmit(onSubmit)}
                    >
                      {t('save_changes')}
                    </Button>
                  </div>
                </div>
              )}
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default Groups;
