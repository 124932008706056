import { get, ref } from 'firebase/database';
import { collection, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* root imports */
import {
  addDocumentFolder,
  removeDocumentFromFolder,
  replaceDocumentFolder,
} from '../../../../api/folders';
import { useAppSelector } from '../../../../redux/hooks';

import { useCopyToClipboard } from '../../../../hooks';

import { redeemer } from '../../../../imports/constants';
import { database, db } from '../../../../imports/firebase';
import { delayed, safeWhere } from '../../../../imports/utils';

import type { Folder } from '../../../../imports/types';

import { Button, Form, Typography } from '../../../../components';

/* tokenCreator imports */

import type { Contract, Nft, OverlayTypes } from '../../imports/types';

import 'swiper/css';
import 'swiper/css/navigation';
import { changePrivateCollection } from '../../../../api/firebase';
import { useLoadingStatusContext } from '../../../../context';
import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import NftDetails from '../analytics/NftDetails';

type TQrCodeFormatted = { id: number; key: string; image: string };

type EditFieldProps = {
  handleCloseSidebar: () => void;
  contractId: string;
  contractPrivate: boolean;
};

const EditField = ({ handleCloseSidebar, contractId, contractPrivate }: EditFieldProps) => {
  const { t } = useTranslation();
  const profile = useAppSelector((state) => state.user);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const [isLoading, setIsLoading] = useState(false);
  const [privateChanged, setPrivateChanged] = useState(contractPrivate);
  const form = {
    initialValues: {
      isPrivate: contractPrivate,
    },
    validationSchema: yup.object({
      isPrivate: yup.boolean().required(),
    }),
  };

  const { initialValues, validationSchema } = form;

  const handlePrivateCollection = async (pvtCollection: boolean, contractId: string) => {
    setIsLoading(true);
    const result = await changePrivateCollection(pvtCollection, contractId);
    if (result.error === undefined) {
      toast.success(
        t('account.success.field_changed', {
          field: t('account.profile_fields.private_collection'),
        }) as string
      );
    } else {
      toast.error(t('account.error.generic') as string);
    }
    setIsLoading(false);
    handleCloseSidebar();
  };

  return (
    <div className="flex w-full flex-col items-center space-y-4">
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        className="flex w-full flex-col"
      >
        {({ fields, handleSubmit, errors, resetField }) => (
          <div className="flex flex-col items-center space-y-4 text-center">
            <Typography weight="medium" size="lg">
              {t('account.edit_profile_field.title', {
                field: t('account.profile_fields.private_collection'),
              })}
            </Typography>

            <div className="w-full text-left">
              <>
                <Button
                  className={`!mt-2 ${!privateChanged ? 'primary' : 'secondary'}`}
                  action={() => privateChanged && setPrivateChanged(!privateChanged)}
                >
                  {t('account.edit_profile_field.not_pvt')}
                </Button>
                <Button
                  className={`!mt-2 ${privateChanged ? 'primary' : 'secondary'}`}
                  action={() => !privateChanged && setPrivateChanged(!privateChanged)}
                >
                  {t('account.edit_profile_field.pvt')}
                </Button>
              </>

              <div className="!mt-2">
                <Button
                  disabled={privateChanged === contractPrivate}
                  action={() => handlePrivateCollection(privateChanged, contractId)}
                  loading={isLoading}
                >
                  {t('account.edit_profile_field.save')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

const CollectionNft = () => {
  const { t } = useTranslation(['tokenCreator', 'translation']);

  const navigate = useNavigate();
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const { address } = useAppSelector((state) => state.user.wallet);
  const { workspace } = useAppSelector((state) => state.team);

  const { id: contractId, nftId } = useParams();
  const [searchParams] = useSearchParams();
  const { uid } = useAppSelector((state) => state.user);

  const tokenId = searchParams.get('tokenId');
  const signature = searchParams.get('signature');
  const addressOwnership = searchParams.get('address');
  const random = searchParams.get('rnd');

  const isVerifyOwnershipUrl = !!tokenId && !!signature && !!addressOwnership && !!random;

  const showSidebarInitialState = { contractId: '', contractName: '' };
  const [showSidebar, setShowSidebar] = useState<{ contractId: string; contractName: string }>(
    showSidebarInitialState
  );
  const [isLoading, setIsLoading] = useState(true);
  const [contract, setContract] = useState<Contract | null>(null);
  const [allowGoBack, setAllowGoBack] = useState<boolean>(true);
  const [overlay, setOverlay] = useState<{ type?: OverlayTypes; tokenId?: number }>({});
  const [qrCodesFormatted, setQrCodesFormatted] = useState<TQrCodeFormatted[] | null>(null);
  const [stateCopyToClipboard, copyToClipboard] = useCopyToClipboard();

  const [view, setView] = useState<'details' | 'analytics' | 'gaming'>('details');

  const toggleOverlay = (type?: OverlayTypes, tokenId?: number) => {
    if (!type) {
      setOverlay({});
      return;
    }

    setOverlay({ type, tokenId });
  };

  const handleCopyToClipboard = (value: any, message: string) => {
    copyToClipboard(value);
    toast.success(message);
  };
  const handleEdit = (contract: Contract) => {
    const id = contract.id;
    const isPrivate = contract.isPrivate ? contract.isPrivate : false;
    console.log(id, isPrivate);
  };
  const handleOpenDialogOverlay = (type: 'qr-code', id: number) => {
    toggleOverlay();

    delayed(() => {
      toggleOverlay(type, id);
    }, 400);
  };

  const handleShowSidebar = (contractId: string, contractName: string) => {
    setShowSidebar({ contractId, contractName });
  };

  const handleCloseSidebar = () => {
    setShowSidebar(showSidebarInitialState);
  };

  const [folders, setFolders] = useState<Array<Folder>>([]);

  const handleAddNotarizationFolder = async (values: { folder: string }) => {
    const { folder } = values;

    setIsLoading(true);
    handleCloseSidebar();

    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        title: t('collection_item.actions.pending'),
      },
    });

    try {
      if (contract?.id) {
        await addDocumentFolder(folder, contract.id, 'contracts').then(async () => {
          loadingStatusDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('collection_item.actions.success'),
            },
          });
          setIsLoading(false);
        });
      }
    } catch (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          title: t('collection_item.actions.failed'),
        },
      });
      setIsLoading(false);
    }

    delayed(() => {
      loadingStatusDispatch({ type: 'SET_HIDE' });
    }, 2500);
  };

  const handleChangeNotarizationFolder = async (values: { folder: string }) => {
    const { folder } = values;

    setIsLoading(true);
    handleCloseSidebar();

    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        title: t('collection_item.actions.pending'),
      },
    });
    if (contract?.folderId) {
      if (folder === 'remove') {
        await removeDocumentFromFolder(contract.folderId, contract.id, 'contracts')
          .then(() => {
            loadingStatusDispatch({
              type: 'SET_SUCCESS',
              payload: {
                title: t('collection_item.actions.success'),
              },
            });
            setIsLoading(false);
          })
          .catch(() => {
            loadingStatusDispatch({
              type: 'SET_ERROR',
              payload: {
                title: t('collection_item.actions.failed'),
              },
            });
            setIsLoading(false);
          });
      } else {
        await replaceDocumentFolder(contract.folderId, folder, contract.id, 'contracts')
          .then(() => {
            loadingStatusDispatch({
              type: 'SET_SUCCESS',
              payload: {
                title: t('collection_item.actions.success'),
              },
            });
            setIsLoading(false);
          })
          .catch((error) => {
            loadingStatusDispatch({
              type: 'SET_ERROR',
              payload: {
                title: t('collection_item.actions.failed'),
              },
            });
            setIsLoading(false);
          });
      }
    }

    delayed(() => {
      loadingStatusDispatch({ type: 'SET_HIDE' });
    }, 2500);
  };

  const handleFolder = (values: { folder: string }) => {
    if (contract?.folderId) {
      handleChangeNotarizationFolder(values);
    } else {
      handleAddNotarizationFolder(values);
    }
  };

  const {
    workspaceGroupObject: { groupId, isSuperGroup },
  } = useWorkspaceAndGroup();
  const activeGroupId = workspace?.activeGroupId;
  useEffect(() => {
    const foldersQuery = query(
      collection(db, 'folders'),
      ...safeWhere(uid, workspace?.id, isSuperGroup ? undefined : activeGroupId),
      where('type', '==', 'contract')
    );

    const unsubscribeFolders = onSnapshot(foldersQuery, async (querySnapshot) => {
      const folders: any[] = [];
      querySnapshot.forEach((doc) => {
        folders.push(doc.data());
      });

      setFolders(folders.sort((a, b) => (a.name > b.name ? 1 : -1)));
    });
    return () => {
      unsubscribeFolders();
    };
  }, [workspace?.id, activeGroupId]);

  const makeQrCodeVisibile = async (contract: Contract) => {
    const numberOfTokens = contract.maxSupplyPerRarity.length - 1;
    const values: TQrCodeFormatted[] = [];
    if (contract.qrCodeDrop === 2) {
      const qrMockKey = '0x0000000000000000000000000000000000000000000000000000000000000000';
      if (contract?.qrCodes) {
        setQrCodesFormatted(
          await Promise.all(
            [...Array(1)].map(async (value, index) => ({
              id: contract.qrCodes?.[0]?.id || index + 1,
              key: qrMockKey,
              image: await QRCode.toDataURL(`${redeemer}/redeem?id=${contractId}&key=${qrMockKey}`),
            }))
          )
        );
      } else {
        const maxSupply = contract.maxSupplyPerRarity[numberOfTokens];
        const catRef = ref(
          database,
          `${contractId}/s/${numberOfTokens - 1}/${maxSupply - 1 >= 0 ? maxSupply - 1 : 0}`
        );
        await Promise.all(
          new Array(1).fill(1).map(async (_) => {
            const snapshot = await get(catRef);
            if (!snapshot.exists()) return null;
            if (!snapshot.val().s && !snapshot.val().e) {
              const qrCodeFormatted: TQrCodeFormatted = {
                id: 0,
                key: qrMockKey,
                image: await QRCode.toDataURL(
                  `${redeemer}/redeem?id=${contractId}&key=${qrMockKey}`
                ),
              };
              values.push(qrCodeFormatted);
            }
            return null;
          })
        );
        setQrCodesFormatted(values);
        return;
      }
    }
    if (contract.qrCodeDrop) {
      if (contract?.qrCodes) {
        setQrCodesFormatted(
          await Promise.all(
            contract?.qrCodes?.map(async ({ id, key }) => ({
              id,
              key,
              image: await QRCode.toDataURL(`${redeemer}/redeem?id=${contractId}&key=${key}`),
            }))
          )
        );
      } else {
        await Promise.all(
          new Array(numberOfTokens).fill(1).map(async (_, id: number) => {
            const catRef = ref(database, `${contractId}/s/${id}`);
            const snapshot = await get(catRef);
            if (!snapshot.exists()) return null;
            await Promise.all(
              snapshot.val().map(async (snap: any) => {
                if (!snap?.s && !snap?.e) {
                  const { p } = snap;
                  const qrCodeFormatted: TQrCodeFormatted = {
                    id: id + 1,
                    key: p,
                    image: await QRCode.toDataURL(`${redeemer}/redeem?id=${contractId}&key=${p}`),
                  };
                  values.push(qrCodeFormatted);
                }
              })
            );
            return null;
          })
        );

        setQrCodesFormatted(values);
      }
    }
  };

  useEffect(() => {
    let unsubscribeContract = () => {};

    if (contractId) {
      const contractDocument = doc(db, 'contracts', contractId);

      unsubscribeContract = onSnapshot(contractDocument, async (document) => {
        const contract = document.data() as Contract;

        if (contract) {
          const nfts = (await getDocs(collection(db, `contracts/${contractId}/nfts`))).docs.map(
            (doc) => doc.data() as Nft
          );

          setContract({ ...contract, nfts });
        }

        setIsLoading(false);
      });
    }

    return () => {
      unsubscribeContract();
    };
  }, []);

  useEffect(() => {
    if (!contract) return;
    if (contract.status !== 'created') setAllowGoBack(false);
    const isTeamMember = contract.workspace_id === workspace?.id;

    if (contract.owner !== address.toLowerCase() && !isTeamMember) {
      navigate('/nft/collection');
      return;
    }

    if (isVerifyOwnershipUrl) {
      if (contract.nfts.find((nft) => nft.id === Number(tokenId))) {
        toggleOverlay('verify-ownership', Number(tokenId));
      }
    }

    if (contract.status === 'created') makeQrCodeVisibile(contract);
  }, [contract]);

  return <NftDetails contract={contract} nftId={nftId} isLoading={isLoading} />;
};

export default CollectionNft;
