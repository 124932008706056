import CryptoJS from 'crypto-js';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import isSameDay from 'date-fns/isSameDay';
import { Signer, utils, Wallet } from 'ethers';
import { saveAs } from 'file-saver';
import { where } from 'firebase/firestore';
import Fuse from 'fuse.js';
import { t } from 'i18next';
import { jsPDF as JsPDF } from 'jspdf';
import JSZip from 'jszip';
import isArray from 'lodash/isArray';
import { toast } from 'react-toastify';
import { sortBy } from 'lodash';
import type { Contract, Nft } from '../modules/tokenCreator/imports/types';
import { apiEndpoint, backendEndpoint, freeIpfsGateway, paidIpfsGateway } from './constants';
// import { getRemoteConfigValue } from './remoteConfig';
import type { FilterCollectionProps, FilterInOrder, TQrCodeFormatted } from './types';
// ================================================== Uncomment after fxing pablock sdk package ===================================
// export const sdk = new PablockSDK({
//   apiKey: process.env.REACT_APP_BCODE_API_KEY,
//   config: {
//     env: process.env.REACT_APP_SDK_ENV,
//     debugMode: true,
//   },
// });
export const delayed = (action: () => void, delay: number) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        action();
      } catch (err) {
        reject();
      } finally {
        resolve(null);
      }
    }, delay);
  });

export const getAuthErrorMessage = (errorCode: string) => {
  const errorMessagesSupported = [
    'auth/email-already-in-use',
    'auth/wrong-password',
    'auth/user-not-found',
  ];

  if (errorMessagesSupported.includes(errorCode)) {
    return errorCode;
  }

  return 'generic';
};

export const encodeRedirectUrl = ({
  url,
  to,
  parameters,
}: {
  url: string;
  to?: string;
  parameters?: string;
}) =>
  `${url}${to ? `&to=${encodeURIComponent(to)}` : ''}${
    parameters ? `&params=${encodeURIComponent(parameters)}` : ''
  }`;

export const getParametersFromParametersInUrl = (parametersInUrl: string) => {
  const parameters: { [key: string]: any } = {};

  parametersInUrl.split(',').forEach((parametersInUrl) => {
    const parameterKeyValuePairs = parametersInUrl.split('=');

    Object.assign(parameters, { [parameterKeyValuePairs[0]]: parameterKeyValuePairs[1] });
  });

  return parameters;
};

export const camelCaseToSnakeCase = (text: string) =>
  text.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const mockContractAndNftCreationGetter = (value: number, multiplier: number) =>
  value * multiplier;

export const formatAmountToCurrency = (amount: number, currency: 'eur' | 'usd' = 'eur') => {
  const symbols = {
    eur: '€',
    usd: '$',
  };

  return `${amount.toFixed(2)}${symbols[currency]}`;
};

export const regexFromUnionType = (unionType: string[]) => new RegExp(`^(${unionType.join('|')})`);

export const filterFuzzyMatch = <T>({
  collection,
  propertyToFilter,
  filterValue,
}: {
  collection: FilterCollectionProps<T>['collection'];
  propertyToFilter: FilterInOrder['propertyToFilter'] | string[];
  filterValue: FilterInOrder['filterValue'];
}) => {
  if (!filterValue) {
    return collection;
  }

  const options = {
    keys: !isArray(propertyToFilter) ? [propertyToFilter] : [...propertyToFilter],
    threshold: 0.3,
  };

  const fuse = new Fuse(collection, options);

  return fuse.search(filterValue).map((item) => item.item);
};

export const filterExactMatch = <T>({
  collection,
  propertyToFilter,
  filterValue,
}: {
  collection: FilterCollectionProps<T>['collection'];
  propertyToFilter: string;
  filterValue: FilterInOrder['filterValue'];
}) => {
  if (!filterValue) {
    return collection;
  }

  return collection.filter((item: any) => {
    if (isArray(item[propertyToFilter])) {
      return item[propertyToFilter].includes(filterValue);
    }

    return item[propertyToFilter] === filterValue;
  });
};

export const filterDateMatch = <T>({
  collection,
  propertyToFilter,
  filterValue,
}: {
  collection: FilterCollectionProps<T>['collection'];
  propertyToFilter: string;
  filterValue: FilterInOrder['filterValue'];
}) => {
  if (!filterValue) {
    return collection;
  }

  return collection.filter((item: any) =>
    isSameDay(fromUnixTime(item[propertyToFilter]), new Date(filterValue))
  );
};

export const filterNotarizationDateMatch = <T>({
  collection,
  propertyToFilter,
  filterValue,
}: {
  collection: FilterCollectionProps<T>['collection'];
  propertyToFilter: string;
  filterValue: FilterInOrder['filterValue'];
}) => {
  if (!filterValue) {
    return collection;
  }

  return collection.filter((item: any) =>
    isSameDay(new Date(item[propertyToFilter]), new Date(filterValue))
  );
};

export const filterCollection = <T>({
  filtersInOrder,
  collection,
}: FilterCollectionProps<T>): T[] => {
  if (collection.length > 0) {
    const filter = ({
      collection,
      filterInOrder: { type, propertyToFilter, filterValue },
    }: {
      collection: T[];
      filterInOrder: FilterInOrder;
    }) => {
      const filters = {
        FUZZY_MATCH: () => filterFuzzyMatch({ collection, propertyToFilter, filterValue }),
        EXACT_MATCH: () =>
          filterExactMatch({
            collection,
            propertyToFilter: propertyToFilter as string,
            filterValue,
          }),
        DATE_MATCH: () =>
          filterDateMatch({
            collection,
            propertyToFilter: propertyToFilter as string,
            filterValue,
          }),
        NOTARIZATION_DATE_MATCH: () =>
          filterNotarizationDateMatch({
            collection,
            propertyToFilter: propertyToFilter as string,
            filterValue,
          }),
      };

      return filters[type]();
    };

    let filteredCollection = collection;

    filtersInOrder.forEach((filterInOrder) => {
      filteredCollection = filter({ collection: filteredCollection, filterInOrder });
    });

    return filteredCollection;
  }

  return collection;
};

// export const isFileTypeImage = ({ type }: File) => {
//   const supportedImagesTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp'];
//   return supportedImagesTypes.includes(type);
// };

export const client = (endpoint: string, { body, fromFunctions = true, ...customConfig }: any) => {
  const headers: any = { 'Content-Type': 'application/json' };
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  return window
    .fetch(`${fromFunctions ? apiEndpoint : backendEndpoint}/${endpoint}`, config)
    .then(async (response) => {
      if (response.ok) {
        // TODO: check the response format - unexpected end of an input
        try {
          return await response.json();
        } catch (err) {
          console.log(err);
          return {};
        }
      }
      const errorMessage = await response.text();
      return Promise.reject(new Error(errorMessage));
    });
};

export const fileToDataUrl = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      resolve(e?.target?.result);
    };
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });

export const dataUrlToFile = (dataUrl: string, fileName: string, type: string) =>
  new Promise((resolve, reject) => {
    fetch(dataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileName, { type });
        resolve(file);
      })
      .catch((error) => reject(error));
  });

export const calculateFileHash = async (file: any, callback: (arg0: any) => void) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    try {
      reader.onload = (event) => {
        const wordArray = CryptoJS.lib.WordArray.create(event.target!.result as any);

        resolve(CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex));
      };

      reader.readAsArrayBuffer(file);
    } catch (err) {
      reject(err);
    }
  });

export const getBase64 = (file: File, callback: (e: any) => void) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      if (callback) {
        return resolve(callback(reader.result));
      }
      return resolve(reader.result);
    };

    reader.onerror = (error) => {
      console.error('Error: ', error);
    };

    reader.readAsDataURL(file);
  });
};

export const compare = (a: any, b: any, key: string, order: string = 'desc') => {
  const c = order === 'desc' ? b : a;
  const d = order === 'desc' ? a : b;

  const cKey = typeof c[key] === 'string' ? c[key].toLowerCase() : c[key];
  const dKey = typeof d[key] === 'string' ? d[key].toLowerCase() : d[key];

  if (cKey < dKey) {
    return -1;
  }
  if (cKey > dKey) {
    return 1;
  }
  return 0;
};

export const compareByDate = (a: any, b: any, order: string = 'desc') => {
  const c = order === 'desc' ? b : a;
  const d = order === 'desc' ? a : b;

  const dateA = new Date(c);
  const dateB = new Date(d);

  return dateA.getTime() - dateB.getTime();
};

export const createWallet = () => {
  const wallet = Wallet.createRandom();

  return wallet;
};

export const createWalletFromSeedPhrase = async (seedPhrase: string) => {
  const wallet = Wallet.fromMnemonic(seedPhrase);

  return wallet;
};

/**
 * This function is used to sign a message using the wallet private key
 *
 * @param message
 * @param signer
 * @returns
 */
export const signMessage = async (message: string, signer: Signer) => {
  const signature = await signer.signMessage(utils.arrayify(message));

  return signature;
};

export const svgUrlToPng = (svgUrl: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx!.drawImage(img, 0, 0);
      const png = canvas.toDataURL('image/png');

      return resolve(png);
    };

    img.onerror = (error) => reject(error);

    img.src = svgUrl;
  });

export const renameFile = (originalFile: File, newName: string) =>
  new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });

export const handleDownloadQrCodePdf = async ({
  qrCodes,
  // dropDate,
  nft,
}: // translations,
{
  qrCodes: { id: number; key: string; image: string }[];
  // dropDate: number;
  nft: Nft;
  // translations: { name: string; dropDate: string };
}) => {
  if (!qrCodes?.length) {
    toast.error(t('no_qr') as string);
    return;
  }

  const areQrCodesMultiple = qrCodes.length > 1;

  const doc = new JsPDF();

  qrCodes.forEach(({ image }, index) => {
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    const qrCodeWidth = 64;
    const qrCodeHeight = 64;
    const qrCodeX = (width - qrCodeWidth) / 2;
    const qrCodeY = (height - qrCodeHeight) / 2;

    const imgElement = document.createElement('img');
    imgElement.src = image;

    /* const centerText = (text: string, y: number) => {
      const textWidth =
        (doc.getStringUnitWidth(text) * doc.getFontSize()) / doc.internal.scaleFactor;
      const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(text, textOffset, y);
    };

    doc.setFontSize(24);
    doc.setFont('helvetica', 'bold');
    centerText(translations.name, qrCodeY - 20);

    if (dropDate) {
      doc.setFontSize(18);
      doc.setFont('helvetica', 'normal');
      centerText(translations.dropDate, qrCodeY - 10);
    } */

    doc.addImage(imgElement, 'png', qrCodeX, qrCodeY, qrCodeWidth, qrCodeHeight);

    /* if (areQrCodesMultiple) {
      doc.setFontSize(16);
      centerText(`${index + 1} / ${qrCodes!.length}`, qrCodeY + qrCodeHeight + 5);
    } */

    if (index !== qrCodes!.length - 1) {
      doc.addPage();
    }
  });

  doc.save(`${nft?.name}-qr-codes.pdf`);
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  toast.success(t('copied_successfully') as string);
};
/**
 *
 * @param number value to trasform
 * @param digits number of most significant digits
 * @returns rounded value with specified number of most significant digits
 */
export const toMostSignificantDigit = (number: number, digits: number) =>
  parseFloat(number.toPrecision(digits));

export const timeSince = (date: number) => {
  const seconds = Math.floor((new Date().valueOf() - date) / 1000);
  let interval = seconds / 31536000;

  const yearsAgo = ' ' + t('years_ago');
  const monthsAgo = ' ' + t('months_ago');
  const daysAgo = ' ' + t('days_ago');
  const hoursAgo = ' ' + t('hours_ago');
  const minutesAgo = ' ' + t('minutes_ago');
  const secondsAgo = ' ' + t('seconds_ago');

  if (interval > 1) {
    return Math.floor(interval) + yearsAgo;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + monthsAgo;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + daysAgo;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + hoursAgo;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + minutesAgo;
  }
  return Math.floor(seconds) + secondsAgo;
};

/**
 *
 * @param uri IPFS uri
 * @returns Bcode current IPFS gateway + CID
 */
export const formatIPFSUri = (uri: string): string => {
  const targetGateway = ['https://ipfs.io/ipfs', 'ipfs://', freeIpfsGateway];
  uri = uri?.replaceAll('#', '%23');
  uri = uri?.replaceAll(' ', '%20');
  uri = uri?.replaceAll('(', '%28');
  uri = uri?.replaceAll(')', '%29');

  for (const gateway of targetGateway) {
    if (uri.startsWith(gateway)) {
      return uri.replace(gateway, paidIpfsGateway);
    }
  }
  return uri;
};

/**
 * @description Function that iterate over all the symbols not supported in url and change them.
 * @param image image name
 * @returns image name without symbols
 */
export const formatImage = (image: string): string => {
  // SPECIAL_SYMBOLS.forEach(({ char, sub }) => {
  //   image = image.replaceAll(char, sub);
  // });
  image = encodeURIComponent(image);
  return image;
};

export const getParametersFromUrl = (parametersInUrl: string) => {
  const doesUrlHaveParameters = parametersInUrl.includes('?');

  if (parametersInUrl && doesUrlHaveParameters) {
    const parameters: { [key: string]: any } = {};

    parametersInUrl
      .split('?')[1]
      .split('&')
      .forEach((parametersInUrl) => {
        const parameterKeyValuePairs = parametersInUrl.split('=');

        Object.assign(parameters, { [parameterKeyValuePairs[0]]: parameterKeyValuePairs[1] });
      });

    return parameters;
  }
};

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const urlToObject = async (image: string, name?: string) => {
  const response = await fetch(image);
  const blob = await response.blob();
  const file = new File([blob], name ?? 'image.jpeg', { type: blob.type });
  return file;
};

export const truncateText = (
  text: string,
  config: {
    maxLength: number;
    position?: 'middle' | 'end';
    leftChars?: number;
    rightChars?: number;
  }
) =>
  text?.length > config.maxLength
    ? `${text.substring(
        0,
        config?.leftChars || Math.floor(config.maxLength / 2)
      )}...${text.substring(
        // eslint-disable-next-line no-unsafe-optional-chaining
        text.length - (typeof config?.rightChars === 'number' ? config?.rightChars : 3),
        text.length
      )}`
    : text;

/**
 * If workspace id is not found performs the query the old way,
 * just so we don't break the app
 */
/**
 *
 * @description Creates a promise that resolves after the given
 * milliseconds
 * @param {int} milliseconds
 */
export async function sleep(milliseconds: number) {
  return new Promise<void>((resolve, reject) => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      // timeout = null;
      resolve();
    }, milliseconds);
  });
}

export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(2);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
};

export const getExplorerTx = (transactionHash: string) => {
  return `${process.env.REACT_APP_EXPLORER_POLYGON}/tx/${transactionHash}`;
};

export const formatDate = (input: number): string => {
  if (!input) return '';
  const inputString = input.toString();
  if (inputString.length === 10) {
    const date = new Date(input * 1000);
    return format(date, 'dd/MM/yyyy').toString();
  }
  return format(new Date(input), 'dd/MM/yyyy').toString();
};

export const ratioChecker = (
  image: Blob | MediaSource,
  minRatio: number,
  maxRatio: number,
  callback: (arg0: boolean) => void
) => {
  const img = new Image();
  const objectUrl = URL.createObjectURL(image);
  img.src = objectUrl;
  img.onload = () => {
    const ratio = img.width / img.height;
    //console.log('H', img.height, 'W', img.width, 'R', ratio);
    if (minRatio <= ratio && ratio <= maxRatio) {
      callback(true);
    } else {
      callback(false);
    }
  };
};

export const isFileTypeImage = ({ type }: File) => {
  const [key, value] = type.split('/');
  return ['.png', '.jpeg', '.svg+xml', '.gif'].includes('.' + value);
};

export const getDefaultDateTimeValue = (options?: {
  extraHours: number;
  extended?: boolean;
  extraDays?: number;
}): { defaultDate: string; defaultTime: number } => {
  const extraHours = options?.extraHours || 0;

  const twoHoursLaterDateTime = new Date(new Date().getTime() + extraHours * 60 * 60 * 1000);
  twoHoursLaterDateTime.setMinutes(0);

  const year = twoHoursLaterDateTime.getFullYear();
  const month = (twoHoursLaterDateTime.getMonth() + 1).toString().padStart(2, '0');
  const day = twoHoursLaterDateTime.getDate().toString().padStart(2, '0');
  const hours = twoHoursLaterDateTime.getHours();
  const minutes = twoHoursLaterDateTime.getMinutes().toString().padStart(2, '0');

  return { defaultDate: `${year}-${month}-${day}`, defaultTime: hours };
};

export const convertFirestoreTs = (ts: { seconds: number; nanoseconds: number }) => {
  return ts.seconds + Math.ceil(ts.nanoseconds / 1000000000);
};

export const hoursOptionsForSelect = Array.from({ length: 24 }, (_, index) => index).map(
  (value) => ({
    value,
    label: value < 10 ? `0${value}` : `${value}`,
  })
);

/**
 * @description given an array of formatted qr codes and a token it downloads a pdf containing all the images in that token
 * @param qrCodes
 * @param nft
 */
export const downloadQrCodes = (qrCodes: TQrCodeFormatted[], nft: Nft): void => {
  const doc = new JsPDF();
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();
  const qrCodeWidth = 64;
  const qrCodeHeight = 64;
  const qrCodeX = (width - qrCodeWidth) / 2;
  const qrCodeY = (height - qrCodeHeight) / 2;
  qrCodes.forEach(({ key, url }, i) => {
    const canvas: any = document.getElementById(key);
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const imgElement = document.createElement('img');
      imgElement.src = pngUrl;
      doc.addImage(imgElement, 'png', qrCodeX, qrCodeY, qrCodeWidth, qrCodeHeight);
      if (i !== qrCodes.length - 1) doc.addPage();
    }
  });
  doc.save(`${nft?.name}-qr-codes.pdf`);
};

/**
 * @description zip an array of pdf. If used for contracts and token pass optional contract prop and it uses the correct names, or an array containing the file's names
 * @param docs
 * @param contract
 * @param fileNames
 */
export const zipDocuments = (docs: any[], contract?: Contract, fileNames?: string[]): void => {
  const zip = new JSZip();
  const nfts = sortBy(contract?.nfts, ['id']);
  docs.forEach(({ tokenId, doc }, index) => {
    const fileName =
      `${tokenId}-${nfts[index].name}.pdf` || `${tokenId}-${fileNames?.[index]}` || `file-${index}`;
    const pdfContent = doc.output('blob');
    zip.file(fileName, pdfContent);
  });

  zip.generateAsync({ type: 'blob' }).then((zipBlob) => {
    saveAs(zipBlob, contract?.name || 'documents.zip');
  });
};

/**
 * @description downloads a zip file containing all the qrcode pdf
 * @param allQrCodesFormatted
 * @param contract
 */
export const downloadAllQrCodes = async (
  allQrCodesFormatted: TQrCodeFormatted[][],
  contract: Contract
) => {
  const docs: { tokenId: number; doc: any }[] = [];

  allQrCodesFormatted.forEach((qrCodesFormatted) => {
    if (qrCodesFormatted.length) {
      const doc = new JsPDF();
      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();
      const qrCodeWidth = 64;
      const qrCodeHeight = 64;
      const qrCodeX = (width - qrCodeWidth) / 2;
      const qrCodeY = (height - qrCodeHeight) / 2;
      qrCodesFormatted.forEach(({ image }, i) => {
        const pngUrl = image;
        const imgElement = document.createElement('img');
        imgElement.src = pngUrl;
        doc.addImage(imgElement, 'png', qrCodeX, qrCodeY, qrCodeWidth, qrCodeHeight);
        if (i !== qrCodesFormatted.length - 1) {
          doc.addPage();
        }
      });
      docs.push({ tokenId: qrCodesFormatted[0].id, doc });
    }
  });

  zipDocuments(docs, contract);
};

/**
 * @description download a csv containing all token data from a contract
 * @param nfts
 * @param qrCodesFormatted
 * @returns
 */
export const downloadAllTokensCsv = (nfts: Nft[], qrCodesFormatted: TQrCodeFormatted[][]) => {
  if (!nfts || nfts.length === 0) {
    console.error('No NFTs provided');
    return;
  }
  const headers = [
    'Name',
    'Description',
    'Quantity',
    'External URL',
    'Image',
    ...(nfts[0]?.attributes?.map(({ trait_type }) => trait_type) || []),
    'TokenID',
    'URL',
    'Address',
  ];
  const rows: any = [];
  nfts.forEach((nft, index) => {
    const customValues = nft?.attributes?.map(({ value }) =>
      typeof value === 'string' ? value : value.name
    );
    const values = [
      nft?.name,
      nft?.description,
      nft?.quantity,
      nft?.external_url,
      nft?.image,
      customValues || '',
    ];
    const qrCodeRows = qrCodesFormatted[index]?.map(({ id, url, key }) => [
      ...values,
      id - 1,
      url,
      key,
    ]);
    if (qrCodeRows) {
      rows.push(...qrCodeRows);
    }
  });
  const csvContent =
    'data:text/csv;charset=utf-8,' + [headers, ...rows].map((e) => e.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
};

export const getWorkspaceAndGroup = (
  workspaceId: string,
  groupId?: string,
  isSuperGroup?: boolean
) => {
  if (!workspaceId) return {};
  return groupId
    ? ({
        workspaceId,
        groupId: isSuperGroup ? undefined : groupId,
        inGroup: true,
        isSuperGroup,
      } as const)
    : ({ workspaceId } as const);
};

/**
 * If workspace id is not found performs the query the old way,
 * just so we don't break the app
 */
export function safeWhere(uid: string, workspaceId?: string, groupId?: string) {
  const queryConstraints = [];
  if (!workspaceId) {
    queryConstraints.push(where('userId', '==', uid));
  }
  if (workspaceId) {
    if (groupId) {
      queryConstraints.push(where('groupId', '==', groupId));
    }
    queryConstraints.push(where('workspace_id', '==', workspaceId));
  }
  return queryConstraints;
}
