import { useQuery } from 'react-query';

import { ethers } from 'ethers';
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import MerkleTree from 'merkletreejs';
import { FunctionComponent, SVGProps, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* root imports */
import { useModal } from 'react-simple-modal-provider';
import { getUserAuthToken } from '../../../../api/firebase';
import { useValidation } from '../../../../hooks';
import { db } from '../../../../imports/firebase';
import {
  calculateFileHash,
  compare,
  compareByDate,
  delayed,
  filterCollection,
  safeWhere,
  signMessage,
  truncateText,
} from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';

import type { Filters } from '../../../../imports/types';

import {
  IconArrow,
  IconCalendar,
  IconClose,
  IconEdit,
  IconFastBack,
  IconFastForward,
  IconInfo,
  IconLoading,
} from '../../../../icons';

import {
  Button,
  CollectionHeader,
  Field,
  FileUploader,
  Form,
  Icon,
  InnerPage,
  Input,
  NotarizationCard,
  TagInput,
  Tooltip,
  Typography,
} from '../../../../components';

/* module notarization imports */
import type { Notarization, Receipt } from '../../../notarizations/imports/types';

/* module audit imports */
import { createAudit, createAuditDoc, updateAuditDoc } from '../../api';

import { useLoadingStatusContext } from '../../../../context';
import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import { getRemoteConfigValue } from '../../../../imports/remoteConfig';
import { isVerifiedOnChain } from '../../../team/api/api';
import type { Audit } from '../../imports/types';

const filtersInitialState: {
  [key: string]: {
    filter: Filters;
    value: any;
  };
} = {
  inputFilterByName: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByTag: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByDate: { filter: 'NOTARIZATION_DATE_MATCH', value: '' },
};

type FiltersActionType = {
  type: 'SET_STATE';
  filterFieldName: 'inputFilterByName' | 'inputFilterByTag' | 'inputFilterByDate' | 'inputOrderBy';
  payload: string | number | Date;
};

const filtersReducer = (state: typeof filtersInitialState, action: FiltersActionType) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        [action.filterFieldName]: { ...state[action.filterFieldName], value: action.payload },
      };
    default:
      return state;
  }
};

enum AuditStep {
  NAME,
  VERIFY_NOTARIZATIONS,
  AUDIT_DETAIL,
}

const CreateAudit = () => {
  const { t } = useTranslation(['audit']);
  const { uid, wallet, workspace } = useAppSelector(({ user, team, subscription }) => ({
    ...user,
    workspace: team.workspace,
  }));
  const { required, validateFilePresence } = useValidation();
  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();
  const navigate = useNavigate();
  const {
    workspaceGroupObject: { workspaceId, groupId, inGroup, isSuperGroup },
  } = useWorkspaceAndGroup();

  const [searchParams] = useSearchParams();

  const auditId = searchParams.get('auditId');
  const targetOwnerUid = workspace?.owner ?? uid;

  const [filtersState, filtersDispatch] = useReducer(filtersReducer, filtersInitialState);
  const { inputFilterByName, inputFilterByTag, inputFilterByDate } = filtersState;
  const [orderBy, setOrderBy] = useState<{
    type: string;
    direction: string;
  }>({ type: 'date', direction: 'desc' });

  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');
  const [audit, setAudit] = useState<{ auditId: string; data: Audit } | null>(null);
  const [notarizations, setNotarizations] = useState<Array<Notarization & Receipt>>([]);
  const [receipts, setReceipts] = useState<Array<Receipt>>([]);

  const [subscriptionsState, setSubscriptionsState] = useState<Array<boolean>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [auditStep, setAuditStep] = useState(0);

  const { open: openModalView } = useModal('AuditViewNotarizedModal');
  const { open: openModalNotarize } = useModal('AuditNotarizeModal');
  const { open: openModalRename } = useModal('AuditRenameModal');
  const { open: openLoadingModal } = useModal('LoadingModal');

  const goTo = (page: string) => {
    navigate(page);
  };

  const handleOpenRenameAudit = (audit: any, setAudit: any) => {
    openModalRename({ audit, setAudit });
  };

  const handleLoadingModal = () => {
    openLoadingModal({ goTo, type: 'audit' });
  };
  const { data: verifiedOnChain } = useQuery(
    ['verified_on_chain', wallet.address],
    () => isVerifiedOnChain(wallet.address!, workspace?.id!),
    {
      enabled: wallet?.address !== '' && workspace?.id != null,
    }
  );

  const form = {
    initialValues: {
      name: audit ? audit.data.name : '',
      fileList: null,
      description: audit ? audit.data.description : null,
      tags: audit ? audit.data.tags : [],
      model: audit ? audit.data.model : '',
    },
    validationSchema: yup.object({
      name: required(yup.string()),
      ...(AuditStep.AUDIT_DETAIL === auditStep && {
        fileList: validateFilePresence(true),
        description: required(yup.string()),
      }),
    }),
  };

  const { initialValues, validationSchema } = form;

  const filteredNotarizations = useMemo(() => {
    const notarizationsFormatted: Notarization[] = [];

    notarizations.forEach((notarization) => {
      const receipt = receipts.find(({ receiptId }) => receiptId === notarization.receiptId);
      if (notarization.receiptId === receipt?.receiptId) {
        notarizationsFormatted.push({
          ...notarization,
          ...receipt,
        });
      }
    });
    const filteredCollection = filterCollection({
      // return filterCollection({
      filtersInOrder: [
        {
          type: inputFilterByName.filter,
          propertyToFilter: ['name'],
          filterValue: inputFilterByName.value,
        },
        {
          type: inputFilterByTag.filter,
          propertyToFilter: 'tags',
          filterValue: inputFilterByTag.value,
        },
        {
          type: inputFilterByDate.filter,
          propertyToFilter: 'date',
          filterValue: inputFilterByDate.value,
        },
      ],
      collection: notarizationsFormatted,
    });
    if (orderBy) {
      if (orderBy.type === 'date') {
        return filteredCollection.sort((a, b) => compareByDate(a.date, b.date, orderBy.direction));
      }
      if (orderBy.type === 'name') {
        return filteredCollection.sort((a, b) => compare(a, b, 'name', orderBy.direction));
      }
    }
    return filteredCollection;
  }, [notarizations, receipts, inputFilterByName, inputFilterByTag, inputFilterByDate, orderBy]);

  const resetFilterField = (filterFieldName: FiltersActionType['filterFieldName']) => {
    filtersDispatch({
      type: 'SET_STATE',
      filterFieldName,
      payload: '',
    });
  };

  const getVerifiedNotarizations = () =>
    filteredNotarizations.filter((notarization) => notarization.verified);

  const getUnverifiedNotarizations = () =>
    filteredNotarizations.filter(
      (notarization) => !notarization.verified && notarization.status === 'success'
    );

  const handleCreateAuditFromName = async (values: { name: string }) => {
    if (!workspace?.id) return;
    const { name } = values;
    setIsLoading(true);

    if (!verifiedOnChain && !workspace?.isOwner) {
      toast.warn(t('team.not_verified') as string);
      setIsLoading(false);
      return;
    }

    const timestamp = Date.now();
    const auditData: Audit = {
      name,
      description: '',
      date: timestamp,
      verifiedNotarizations: [],
      status: 'pending',
      userId: targetOwnerUid,
      workspace_id: workspace?.id,
      ...(inGroup && groupId && { groupId, inGroup }),
    };

    const { value: createAuditValue, error: createAuditError } = await createAuditDoc(auditData);

    if (createAuditError) {
      console.error(createAuditError);
      toast.error(t('create_audit.actions.failed') as string);
      setIsLoading(false);
      return;
    }

    if (createAuditValue) {
      setAudit(createAuditValue);
      setIsLoading(false);
      setAuditStep(1);
    }
  };

  const handleSaveAuditVerifiedNotarizations = async () => {
    setIsSaving(true);

    if (audit && audit.auditId) {
      const verifiedNotarizations = getVerifiedNotarizations().map((notarization) =>
        doc(db, 'notarizations', notarization.id)
      );

      const auditData: Audit = { ...audit.data, verifiedNotarizations };

      const { value: updateAuditValue, error: updateAuditError } = await updateAuditDoc(
        audit.auditId,
        auditData
      );

      if (updateAuditValue) {
        setAudit(updateAuditValue);
      }
    }

    delayed(() => {
      setIsSaving(false);
    }, 2000);
  };

  const handleSaveAuditDetails = async (values: { description: string; tags: string[] }) => {
    const { description, tags } = values;

    if (audit && audit.auditId && values) {
      const verifiedNotarizations = getVerifiedNotarizations().map((notarization) =>
        doc(db, 'notarizations', notarization.id)
      );

      const auditData: Audit = {
        ...audit.data,
        description,
        tags,
        verifiedNotarizations,
        status: 'pending',
      };
      const { value: updateAuditValue, error: updateAuditError } = await updateAuditDoc(
        audit.auditId,
        auditData
      );

      if (updateAuditError) {
        toast.error(t('create_audit.actions.failed') as string);
        return;
      }
      if (updateAuditValue) {
        setAudit(updateAuditValue);
      }
    }
  };

  const handleConcludeAudit = async (values: {
    fileList: FileList;
    name: string;
    description: string;
    tags: string[];
  }) => {
    const { fileList, name, description, tags } = values;
    handleSaveAuditDetails(values).then(async () => {
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('create_audit.actions.pending'),
        },
      });

      handleLoadingModal();

      if (audit && audit.auditId && audit.data && fileList.length > 0) {
        try {
          const { value: authTokenId } = await getUserAuthToken();
          const userWallet = new ethers.Wallet(wallet.privateKey);
          const formData = new FormData();
          const file = fileList[0];
          const hashes = [];

          const fileHash = await calculateFileHash(file, (hash: string) => hash);
          hashes.push(`0x${fileHash}`);

          const verifiedNotarizationsHashes = getVerifiedNotarizations().map(
            (notarization) => notarization.hash
          );
          hashes.push(...verifiedNotarizationsHashes);

          const merkleTree = new MerkleTree(hashes);
          const merkleRoot = merkleTree.getRoot().toString('hex');

          if (workspace == null) {
            throw new Error('Workspace not set');
          }

          formData.append('senderId', uid);
          formData.append('ownerId', workspace.owner);
          formData.append('ownerAddress', workspace.ownerAddress);
          formData.append('senderAddress', wallet.address);
          formData.append('workspaceId', workspaceId || '');
          if (groupId) {
            formData.append('groupId', groupId);
          }

          formData.append('auditId', audit.auditId);
          formData.append('name', name);
          formData.append('description', description);
          formData.append('tags', JSON.stringify(tags));
          formData.append('file', file);
          formData.append('hashList', JSON.stringify(hashes));
          formData.append('hash', `0x${merkleRoot}`);
          formData.append('signature', await signMessage(`0x${merkleRoot}`, userWallet));

          const { value: createAuditValue, error: createAuditError } = await createAudit(
            formData,
            authTokenId
          );

          if (createAuditError) {
            loadingStatusDispatch({
              type: 'SET_ERROR',
              payload: {
                message:
                  createAuditError.response.status === 510
                    ? t('create_audit.actions.max_notarizations_allowed_reached')
                    : t('create_audit.actions.failed'),
              },
            });
          }

          if (createAuditValue) {
            await updateAuditDoc(audit.auditId, { status: 'completed' });
            loadingStatusDispatch({
              type: 'SET_SUCCESS',
              payload: {
                title: t('create_audit.actions.success'),
              },
            });
          }
        } catch (error) {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('create_audit.actions.failed'),
            },
          });
        }
      }
    });
  };

  useEffect(() => {
    if (isLoading) {
      if (
        ((!auditId && subscriptionsState.length === 2) || subscriptionsState.length === 3) &&
        isLoading
      ) {
        setIsLoading(false);
      }
    }
  }, [subscriptionsState, auditId]);

  useEffect(() => {
    if (auditId && auditStep !== 1) {
      setAuditStep(1);
    }

    if (!auditId && auditStep !== 0) {
      setAuditStep(0);
    }
  }, [auditId]);
  const activeGroupId = workspace?.activeGroupId;
  useEffect(() => {
    let unsubscribeAudit = () => {};
    let auditData: any = null;

    if (auditId) {
      unsubscribeAudit = onSnapshot(doc(db, 'audits', auditId), async (doc) => {
        auditData = doc.data() as Audit;
        setAudit({ auditId, data: auditData });
        setSubscriptionsState((prevState) => [...prevState, true]);
      });
    } else {
      setAudit(null);
    }

    const receiptsQuery = query(collection(db, 'receipts'));

    const notarizationsQuery = query(
      collection(db, 'notarizations'),
      ...safeWhere(uid, workspace?.id, isSuperGroup === false ? undefined : activeGroupId)
    );

    const unsubscribeReceipts = onSnapshot(receiptsQuery, async (querySnapshot) => {
      const receipts: any[] = [];
      querySnapshot.forEach((doc) => {
        receipts.push(doc.data());
      });

      setReceipts(receipts);
      setSubscriptionsState((prevState) => [...prevState, true]);
    });

    const unsubscribeNotarizations = onSnapshot(notarizationsQuery, async (querySnapshot) => {
      let notarizations: any[] = [];

      querySnapshot.forEach((doc) => {
        notarizations.push({ ...doc.data(), id: doc.id });
      });

      if (auditId && auditData) {
        const verifiedNotarization = auditData.verifiedNotarizations.map(
          (verifiedNotarization: any) => verifiedNotarization.id
        );

        notarizations = notarizations.map((notarization) => {
          if (verifiedNotarization.includes(notarization.id)) {
            return { ...notarization, verified: true };
          }

          return notarization;
        });
      }

      setNotarizations(notarizations.sort((a, b) => compare(a, b, 'date', 'desc')));
      setSubscriptionsState((prevState) => [...prevState, true]);
    });

    return () => {
      if (auditId) {
        unsubscribeAudit();
      }

      unsubscribeReceipts();
      unsubscribeNotarizations();
    };
  }, [activeGroupId]);

  useEffect(() => {
    if (AuditStep.VERIFY_NOTARIZATIONS === auditStep) {
      handleSaveAuditVerifiedNotarizations();
    }
  }, [notarizations]);

  const orderByOptions: {
    value: string;
    label: string;
    disabled?: boolean;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[] = [
    {
      value: 'order',
      label: t('collection.order_by.order'),
      icon: IconInfo,
    },
    {
      value: 'date-asc',
      label: t('collection.order_by.date_oldest'),
      icon: IconCalendar,
    },
    {
      value: 'date-desc',
      label: t('collection.order_by.date_recent'),
      icon: IconCalendar,
    },
    {
      value: 'name-asc',
      label: 'A-Z',
      icon: IconFastForward,
    },
    {
      value: 'name-desc',
      label: 'Z-A',
      icon: IconFastBack,
    },
  ];

  const handleSetOrderBy = (value: string) => {
    if (value === 'order') {
      setOrderBy({ ...orderBy, type: 'date' });
      return;
    }

    const [type, direction] = value.split('-');
    setOrderBy({ type, direction });
  };

  return (
    <>
      <InnerPage>
        {AuditStep.VERIFY_NOTARIZATIONS === auditStep && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-row content-center justify-between">
              <div className="mr-4 flex">
                <Icon
                  icon={IconArrow}
                  onClick={() => navigate('/audit-db')}
                  size="40"
                  stroke="primary-500"
                  className="mb-3 cursor-pointer rounded-md"
                />
              </div>
              <Typography weight="medium" size="lg">
                {audit?.data.name}
              </Typography>
              <Tooltip content={t('create_audit.edit_name')}>
                {/* <Button type="ghost" action={() => handleShowSidebar('editAuditName', { audit })}> */}
                <Button type="ghost" action={() => handleOpenRenameAudit(audit, setAudit)}>
                  <Icon icon={IconEdit} className="!h-4 !w-4" stroke="secondary-500" />
                </Button>
              </Tooltip>
            </div>
            <div className="flex flex-row items-center justify-between">
              <CollectionHeader
                orderByOptions={orderByOptions}
                filtersState={filtersState}
                filtersDispatch={filtersDispatch}
                handleSetOrderBy={handleSetOrderBy}
                resetFilterField={resetFilterField}
              />
              {isSaving && (
                <div className="flex items-center justify-end space-x-2">
                  <Icon size="sm" className="!text-grey" loading />
                  <Typography weight="medium" size="xs" color="grey-600">
                    {t('create_audit.saving')}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}

        {!isLoading ? (
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className="flex size-full grow flex-col items-center space-y-12"
          >
            {({ fields, handleSubmit, errors, resetField, watch, setValue }) => {
              const [fileError, setFileError] = useState(false);

              const tags = watch('tags');
              const fileList = watch('fileList');
              const description = watch('description');
              const isFileListValid =
                fileList !== null && Array.isArray(fileList) && fileList.length > 0;
              return (
                <>
                  {AuditStep.NAME === auditStep && (
                    <>
                      <Typography size="lg" weight="medium">
                        {t('create_audit.insert_new_audit_name')}
                      </Typography>

                      <div className="w-[17rem] space-y-4">
                        <Field error={errors.name?.message}>
                          <Input
                            type="text"
                            placeholder={t('create_audit.labels.name')}
                            id={fields.name.name}
                            name={fields.name.name}
                            onBlur={fields.name.onBlur}
                            onChange={fields.name.onChange}
                            inputRef={fields.name.ref}
                            defaultValue={audit ? audit.data.name : ''}
                            error={errors.name?.message}
                            elementRight={
                              <Button
                                type="ghost"
                                icon={IconClose}
                                action={() => resetField(fields.name.name)}
                              />
                            }
                          />
                        </Field>

                        <Button
                          action={handleSubmit(handleCreateAuditFromName)}
                          loading={isLoading}
                        >
                          {t('create_audit.buttons.go_next')}
                        </Button>
                      </div>
                    </>
                  )}

                  {AuditStep.VERIFY_NOTARIZATIONS === auditStep && (
                    <div className="mt-12 flex w-full flex-col items-center space-y-12">
                      <div className="w-full space-y-4">
                        {!isLoading ? (
                          <>
                            <div className="flex flex-row items-center justify-between">
                              <Typography>{t('create_audit.notarizations_verified')}</Typography>
                              {/* <div className="w-[17rem]"> */}
                              {workspace?.permissions.audit.write && (
                                <Button
                                  action={() => setAuditStep(2)}
                                  disabled={getVerifiedNotarizations().length <= 0}
                                >
                                  {t('create_audit.buttons.finish_audit')}
                                </Button>
                              )}
                              {/* </div> */}
                            </div>
                            <div className="flex flex-wrap gap-5">
                              {getVerifiedNotarizations().length > 0 ? (
                                getVerifiedNotarizations().map((notarization, index) => {
                                  let isAudit = false;
                                  if (notarization.auditId) {
                                    isAudit = true;
                                  }

                                  return (
                                    <NotarizationCard
                                      key={notarization.id}
                                      item={notarization}
                                      action={() => openModalView({ notarization })}
                                    />
                                  );
                                })
                              ) : (
                                <div className="col-span-full mt-6 text-center">
                                  <Typography>
                                    {t('create_audit.no_notarizations_verified_found')}
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="flex grow items-center justify-center">
                            <IconLoading className="size-12 animate-spin text-primary-500" />
                          </div>
                        )}
                      </div>

                      <div className="w-full space-y-4">
                        {!isLoading ? (
                          <>
                            <Typography>{t('create_audit.notarizations_unverified')}</Typography>
                            <div className="flex flex-wrap gap-5">
                              {getUnverifiedNotarizations().length > 0 ? (
                                getUnverifiedNotarizations().map((notarization, index) => {
                                  let isAudit = false;
                                  if (notarization.auditId) {
                                    isAudit = true;
                                  }

                                  return (
                                    <NotarizationCard
                                      key={notarization.id}
                                      item={notarization}
                                      action={() =>
                                        openModalNotarize({ notarization, setNotarizations })
                                      }
                                    />
                                  );
                                })
                              ) : (
                                <div className="col-span-full mt-6 text-center">
                                  <Typography>
                                    {t('create_audit.no_notarizations_unverified_found')}
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="flex grow items-center justify-center">
                            <IconLoading className="size-12 animate-spin text-primary-500" />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {AuditStep.AUDIT_DETAIL === auditStep && (
                    <>
                      <div className="flex w-full grow ">
                        <div className="flex w-2/4 flex-col items-center space-y-2 border-r-[0.063rem] border-r-primary-400 p-12">
                          <Typography size="lg" weight="medium">
                            {t('create_audit.audit_data')}
                          </Typography>

                          <div className="flex w-64 flex-col gap-4">
                            <Field
                              error={
                                fileError
                                  ? t('form_validation.required', { ns: 'translation' })
                                  : ''
                              }
                            >
                              <FileUploader
                                label={t('create_audit.labels.upload_report_audit')}
                                onChange={(files) => setValue(fields.fileList.name, files)}
                                accept={allowedFileType}
                              />
                            </Field>

                            <Field error={errors.description?.message}>
                              <Input
                                type="text"
                                placeholder={t('create_audit.labels.description')}
                                id={fields.description.name}
                                name={fields.description.name}
                                onBlur={fields.description.onBlur}
                                onChange={fields.description.onChange}
                                inputRef={fields.description.ref}
                                error={errors.description?.message}
                                elementRight={
                                  <Button
                                    type="ghost"
                                    icon={IconClose}
                                    action={() => resetField(fields.description.name)}
                                  />
                                }
                              />
                            </Field>

                            <Field error={errors.tags?.message}>
                              <TagInput
                                type="text"
                                placeholder={t('create_audit.labels.tags')}
                                id={fields.tags.name}
                                name={fields.tags.name}
                                onTagsChange={(allTags) => setValue(fields.tags.name, allTags)}
                                error={errors.tags?.message}
                                elementRight={
                                  <Button
                                    type="ghost"
                                    icon={IconClose}
                                    action={() => resetField(fields.tags.name)}
                                  />
                                }
                                tags={tags}
                              />
                            </Field>
                          </div>
                        </div>

                        <div className="flex w-2/4 flex-col items-center space-y-4 p-12">
                          <Typography size="lg" weight="medium">
                            {t('create_audit.verified_notarizations_list')}
                          </Typography>

                          <div className="flex w-64 flex-col space-y-4">
                            {getVerifiedNotarizations().map((notarization, index) => (
                              <div
                                key={index}
                                className="border-light flex h-9 w-full items-center justify-center rounded-md border-[0.063rem] px-4 text-center shadow-md"
                              >
                                <Typography size="sm" weight="medium">
                                  {truncateText(notarization.name, {
                                    maxLength: 30,
                                    rightChars: 0,
                                  })}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row gap-7 ">
                        <Button
                          className="!w-[190px]"
                          action={() => setAuditStep(1)}
                          type="secondary"
                          loading={isLoading}
                        >
                          {t('create_audit.buttons.go_back')}
                        </Button>
                        <Button
                          className="!w-[190px]"
                          action={() => {
                            if (isFileListValid) {
                              setFileError(false);
                              handleSubmit(handleConcludeAudit)();
                            } else {
                              setFileError(true);
                            }
                          }}
                          loading={isLoading}
                          disabled={description === '' || fileList === null}
                        >
                          {t('create_audit.buttons.notarize_audit')}
                        </Button>
                      </div>
                    </>
                  )}
                </>
              );
            }}
          </Form>
        ) : (
          <div className="flex grow items-center justify-center">
            <IconLoading className="size-12 animate-spin text-primary-500" />
          </div>
        )}
      </InnerPage>
    </>
  );
};

export default CreateAudit;
