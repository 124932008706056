import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinkText from '../linkText/LinkText';

const terms = 'https://legals.bcode.cloud/bcode_platform/legals/terms';
const privacy = 'https://legals.bcode.cloud/bcode_platform/legals/privacy';
const noticeAndAction = 'https://legals.bcode.cloud/bcode_platform/notice_and_action';

const Item = ({ to, name }: { to: string; name: string }) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-[56px] flex-row items-center justify-start gap-x-4 rounded-lg px-4 hover:bg-primary-100">
      <LinkText
        textColor="black"
        textColorHover="primary-500"
        textSize="text-body-regular-16"
        noUnderline
        newPage
        to={to}
      >
        {t(name)}
      </LinkText>
    </div>
  );
};

const LegalDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative flex items-center gap-1">
      <div
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className={`absolute bottom-0 right-0 flex w-[210px] flex-col rounded-lg border border-grey-300 bg-white ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <Item name="legal_dropdown.terms" to={terms} />
        <Item name="legal_dropdown.privacy" to={privacy} />
        <Item name="legal_dropdown.notice_and_action" to={noticeAndAction} />
      </div>
      <p
        className="text-black underline cursor-pointer text-body-medium-16 decoration-black hover:text-black"
        onClick={() => setIsOpen(!isOpen)}
      >
        Legal
      </p>
    </div>
  );
};

export default LegalDropdown;
