import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImagePlaceholder } from '../../../../assets/images';
import { CustomizedText, Icon, Image, Typography } from '../../../../components';
import {
  IconFavoritesFilled,
  IconGaming,
  IconInfoTaker,
  IconQrCode,
  IconShare,
  IconTransfer,
  IconWebsite,
} from '../../../../icons';
import { formatDate } from '../../../../imports/utils';
import { NftCustomAttribute } from '../../imports/types';
import { handleOrderAttributes } from '../../imports/utils';
import FlipCard from '../flipCard/FlipCard';
import DetailItem from './DetailItem';
import RenderAttribute from './RenderAttribute';

type NftPreviewProps = {
  index: number;
  nft: any;
  nfts: any;
  values: any;
  doesTransfer: boolean;
  maxTokensPerUser: string;
  chain: string;
  doesExpire: boolean;
  expiration: number;
  dropDate: number | undefined;
  hasGame: boolean;
};

const NftPreview = ({
  index,
  nft,
  nfts,
  values,
  doesTransfer,
  maxTokensPerUser,
  chain,
  doesExpire,
  expiration,
  dropDate,
  hasGame,
}: NftPreviewProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const [flip, setFlip] = useState<boolean>(false);
  const img = nft.image ? nft.image[0]?.preview : ImagePlaceholder;

  return (
    <div className="flex h-[768px] max-h-[1300px] flex-col justify-center">
      <div
        id={`preview-${index}`}
        className="border-grey3 scrollbar-hide flex aspect-[7.5/16] h-full w-4/5 flex-col overflow-auto rounded-[20px] border-2 bg-grey-600 p-5 pt-[10%] text-white shadow-inner shadow-black"
      >
        <FlipCard
          front={
            <Image
              src={img || ImagePlaceholder}
              className="mx-auto aspect-square w-full rounded-[20px] object-scale-down "
            />
          }
          back={
            <div className="rounded-[20px] border object-contain p-4">
              <Typography size="body-bold-16" weight="bold">
                {t('nft.blockchain_info')}
              </Typography>
              <div className="mt-6 flex flex-wrap justify-between gap-y-2">
                <Typography size="body-regular-14" weight="bold" className="min-w-[50%] underline">
                  Tx Hash
                </Typography>
                {/* Transfer */}
                <div className="min-w-[50%]">
                  <DetailItem
                    left={t('collection_item.label_fields.does_transfer')}
                    right={t(`boolean.${doesTransfer?.toString()}`)}
                  />
                </div>
                {/* maxTokensPerUser */}
                <div className="min-w-[50%]">
                  <DetailItem
                    left={t('collection_item.label_fields.max_per_wallet')}
                    right={maxTokensPerUser}
                  />
                </div>
                {/* Chain */}
                <div className="min-w-[50%]">
                  <DetailItem left={t('collection_item.label_fields.chain')} right={chain} />
                </div>
                {/* Expiration */}
                {doesExpire ? (
                  <div className="min-w-[50%]">
                    <DetailItem
                      left={t('collection_item.label_fields.nft_expiration')}
                      right={
                        dropDate
                          ? typeof dropDate === 'number'
                            ? `${formatDate(dropDate * 1000 + expiration * 86400000)}`
                            : `${formatDate(new Date(dropDate).getTime() + expiration * 86400000)}`
                          : `${formatDate(new Date().getTime() + expiration * 86400000)}`
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {/* Quantity */}
                <div className="min-w-[50%]">
                  <DetailItem
                    left={t('nft.quantity')}
                    right={
                      nfts[index].isUnlimited
                        ? t('nft.unlimited')
                        : (nfts[index].quantity as string)
                    }
                  />
                </div>
              </div>
            </div>
          }
          always={
            <div className="absolute bottom-[10px] flex w-full justify-between">
              <div className="ml-[10px] flex size-10 items-center justify-center rounded-[10px] bg-white ">
                <IconQrCode stroke="#642eff" width={37} height={37} />
              </div>
              <div
                onClick={() => setFlip(!flip)}
                className="mr-[10px] flex size-10 items-center justify-center rounded-[10px] bg-white"
              >
                <IconInfoTaker stroke="#642eff" width={37} height={37} />
              </div>
            </div>
          }
          flip={flip}
        />
        <div className="flex flex-col gap-1">
          <div className="mt-4 flex max-w-full flex-row justify-between">
            <div className="flex flex-row gap-4">
              <Typography className="break-words" size="body-semibold-20">
                {nfts[index].name}
              </Typography>
            </div>
            <div className="flex flex-row gap-4">
              <Icon icon={IconFavoritesFilled} size="20" />
              <Icon icon={IconShare} size="20" stroke="white" />
            </div>
          </div>
          <Typography size="body-medium-14" className="underline">
            {values.name}
          </Typography>
          <div className="flex items-center space-x-2">
            <figure
              aria-label="status indicator"
              className={`size-3 rounded-full bg-success
          `}
            />
            <p className="text-body-regular-14">{t('collection.filters.state.redeem')}</p>
          </div>
        </div>
        <CustomizedText text={nft.description} />
        {/* </div> */}
        <Typography className="mt-4" size="body-medium-14" weight="semibold">
          {t('nft.features')}
        </Typography>
        <div className="mt-2 flex gap-2">
          {nft.external_url && (
            <div className="size-10 rounded-[10px] border border-white bg-white p-2">
              <Icon icon={IconWebsite} stroke="primary-500" size="24" />
            </div>
          )}
          {doesTransfer && (
            <div className="size-10 rounded-[10px] border border-white bg-white p-2">
              <Icon icon={IconTransfer} stroke="primary-500" size="24" />
            </div>
          )}
          {hasGame && (
            <div className="size-10 rounded-[10px] border border-white bg-white p-2">
              <Icon icon={IconGaming} stroke="primary-500" size="24" />
            </div>
          )}
        </div>
        {/* PREVIEW CUSTOM ATTRIBUTES*/}
        {nft.attributes.length > 0 && (
          <div className="mt-8 ">
            <Typography size="body-medium-16">
              {t('create_contract.form_labels.additional_custom_fields')}:
            </Typography>
            <div className="mt-4 flex flex-col justify-between gap-2 ">
              {handleOrderAttributes(index, nfts).map(
                (attribute: NftCustomAttribute[], i: number) => {
                  return (
                    <div key={i} className="flex gap-1 overflow-x-auto ">
                      {attribute.map((attr, j) => (
                        <div
                          key={`${i}-${j}`}
                          className={` inline-block h-full whitespace-nowrap rounded-[4px] ${
                            attr.type === 'partner' || attr.type === 'image'
                              ? '!min-w-[75%]'
                              : '!w-full'
                          }`}
                        >
                          <RenderAttribute attribute={attr} />
                        </div>
                      ))}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default NftPreview;
