import { useTranslation } from 'react-i18next';
import { Icon, Image, Tooltip, Typography } from '../../../../components';
import { IconCopy } from '../../../../icons';
import { Activity } from '../../../../imports/types';
import {
  convertFirestoreTs,
  copyToClipboard,
  formatDate,
  formatIPFSUri,
} from '../../../../imports/utils';
import { Contract, Nft } from '../../imports/types';

interface TrxTableProps {
  transactions: Activity[];
  contracts?: Contract[];
  image?: string;
}

const TrxTable = (props: TrxTableProps) => {
  const { transactions, contracts, image } = props;
  const { t } = useTranslation(['tokenCreator']);
  const handleCopyToClipboard = (value: any) => {
    copyToClipboard(value);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b shadow border-grey-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-grey-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.card.name')}
                    </Typography>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.card.user_address')}
                    </Typography>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.card.token_id')}
                    </Typography>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.card.timestamp')}
                    </Typography>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.card.qty')}
                    </Typography>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.card.status')}
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {transactions.map((log, index) => {
                  const findNameById = (
                    idToFind: string,
                    contracts: Contract[] | undefined
                  ): string => {
                    if (contracts) {
                      const foundContract = contracts.find((contract) => contract.id === idToFind);
                      return foundContract ? foundContract.name : idToFind;
                    }
                    return idToFind;
                  };
                  const nftName = findNameById(contracts![0].name, contracts);
                  const trxType = log.type;

                  const quantity = log.data.quantity ? log.data.quantity : 'n/d';
                  const trxHash = log.data.transaction_hash;
                  const trxAddress = log.data.customer_address || log.data?.to;
                  const tokenId = log.data.token_id;
                  const trxDate = log?.createdAt ? convertFirestoreTs(log?.createdAt) : 'n/d';
                  const trxAnalytics = t(`analytics.${log?.type}`);
                  const rowClass = index % 2 === 0 ? 'bg-white' : 'bg-grey-200';
                  let img = '';
                  if (contracts) {
                    const contract = contracts.find(
                      (contract: Contract) => contract.id === log.data.contract_id
                    );

                    const nft = contract?.nfts?.find((nft: Nft) => nft.id === log.data.token_id);

                    if (nft && nft.image && typeof nft.image === 'string') {
                      img = formatIPFSUri(nft.image);
                    }
                  }
                  if (image) img = formatIPFSUri(image);

                  return (
                    <tr key={index} className={rowClass}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-2">
                          {contracts || image ? (
                            <Image src={img} type="cover" className="h-[26px] w-auto rounded-lg" />
                          ) : (
                            '-'
                          )}

                          <Typography weight="light" size="sm">
                            {nftName}
                          </Typography>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Typography weight="light" size="sm">
                            {trxAddress}
                          </Typography>
                          {trxHash && trxAddress && (
                            <div className="flex align-center">
                              <Tooltip content={t('copy')}>
                                <>
                                  <Icon
                                    icon={IconCopy}
                                    onClick={() => handleCopyToClipboard(trxAddress)}
                                    className="cursor-pointer size-4"
                                    color="primary-500"
                                    stroke="white"
                                    size="20"
                                  />
                                </>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Typography weight="light" size="sm">
                            {tokenId}
                          </Typography>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Typography weight="light" size="sm">
                            {trxDate !== 'n/d' ? formatDate(trxDate) : trxDate}
                          </Typography>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Typography weight="light" size="sm">
                            {/*redeem does not have a quantity field at the moment so we default to 1 because redemption is always 1*/}
                            {trxType === 'redeem' ? 1 : quantity}
                          </Typography>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Typography weight="light" size="sm">
                            {trxAnalytics}
                          </Typography>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrxTable;
