import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { logOut } from '../../api/firebase';
import { IconAccountSwap, IconSignOut, IconUser } from '../../icons';
import { firebaseConfig } from '../../imports/constants';
import { logOutContracts } from '../../redux/contracts/contracts.slice';
import { logOutFunctionalities } from '../../redux/functionalities/functionalities.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logOutPolls, logOutSubmitions } from '../../redux/polls/polls.slice';
import { logOutSubscription } from '../../redux/subscription/subscription.slice';
import { logOutTeam } from '../../redux/team/team.slice';
import { logOutUser } from '../../redux/user/user.slice';
import Icon from '../icon/Icon';
import LinkText from '../linkText/LinkText';
import Typography from '../typography/Typography';

const UserIcon = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.user);
  const { email, name, company } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);
  const [isOpen, setIsOpen] = useState(false);

  const handleLogOut = () => {
    logOut().then(() => {
      dispatch(logOutUser());
      dispatch(logOutTeam());
      dispatch(logOutContracts());
      dispatch(logOutPolls());
      dispatch(logOutSubmitions());
      dispatch(logOutFunctionalities());
      dispatch(logOutSubscription());
      navigate('/sign-in'); // usefirebasequery breaks when you logout when subscribed to a collection
    });
  };
  const img =
    // publicCompany?.profilePic ||
    `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/images%2Fpropic.png?alt=media&token=cb6dd1d9-1188-48ba-8787-64c97bca0816`;
  const alt = t('avatar');
  return (
    <div className="size-[40px] rounded-lg">
      <div
        className={`flex !h-[40px] !w-[40px] cursor-pointer items-center justify-center rounded-[10px] ${
          isOpen ? 'bg-primary-400' : 'border border-primary-400 bg-white hover:bg-primary-100'
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon icon={IconUser} size="md" stroke={`${isOpen ? 'white' : 'primary-400'}`} />
      </div>
      <div
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className={`absolute right-0 flex w-[210px] flex-col rounded-lg border border-grey-300 bg-white ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <div
          key="mail"
          className="flex h-[56px] flex-col  justify-evenly	 gap-x-4 rounded-t-lg border-b border-b-grey-300  px-4"
        >
          <Typography size="body-regular-18" color="black">
            {name || company}
          </Typography>
          <Typography size="body-regular-12" color="black">
            {email}
          </Typography>
        </div>

        <div
          key="profile"
          className="flex h-[56px] flex-row items-center justify-start gap-x-4 rounded-lg  px-4 hover:bg-primary-100"
        >
          <Icon icon={IconUser}></Icon>
          <LinkText
            textColor="black"
            textColorHover="primary-500"
            textSize="text-body-regular-16"
            noUnderline
            to="/profile"
          >
            {t(`topbarMenu.sub_items.profile`)}
          </LinkText>
        </div>
        <div
          key="logOut"
          className="flex h-[56px] flex-row items-center justify-start gap-x-4 rounded-lg  px-4 hover:bg-primary-100"
          onClick={handleLogOut}
        >
          <Icon icon={IconSignOut}></Icon>
          <Typography size="lg" color="black" className="cursor-pointer hover:text-primary-500">
            {t(`sidebar.logout`)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default UserIcon;
