import React from 'react';

interface IStepper {
  currentStep: number;
  stepLabels: string[];
  id?: string;
  disableNextStep?: boolean;
  className?: string;
}

const Stepper: React.FC<IStepper> = ({
  currentStep,
  stepLabels,
  id,
  disableNextStep = true,
  className,
}) => {
  if (currentStep >= stepLabels.length) throw new Error('StepperError: active index out of bounds');

  const stepperPercentage =
    currentStep === 0
      ? 0
      : currentStep === stepLabels.length - 1
        ? 100
        : Math.ceil(100 / (stepLabels.length - 1)) * currentStep;
  return (
    <div id={id} className={`h-full ${className}`}>
      <div className="relative flex h-full w-[4px] flex-col justify-between bg-grey-200">
        <figure className=" w-[4px]  bg-primary-500" style={{ height: `${stepperPercentage}% ` }}>
          {stepLabels.map((step, i) => {
            const positionPercentage =
              i === 0
                ? -1
                : i === stepLabels.length - 1
                  ? 100
                  : Math.ceil(100 / (stepLabels.length - 1)) * i;

            return (
              <div
                className=" text-top absolute flex items-start justify-start gap-[30px]"
                style={{ top: `${positionPercentage}%` }}
                key={`${step}-${i}`}
              >
                <div
                  className={` size-3 rounded-[50%] ${
                    i > currentStep ? 'bg-grey-200' : 'bg-primary-500'
                  } `}
                ></div>
                <p
                  className={`text-top absolute -top-2 left-[30px] whitespace-nowrap text-body-medium-18 ${
                    disableNextStep ? (i > currentStep ? 'text-grey-400' : 'text-grey-600') : ''
                  }`}
                >
                  {step}
                </p>
              </div>
            );
          })}
        </figure>
      </div>
    </div>
  );
};

export default Stepper;
