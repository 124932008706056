import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

/* root imports */
import { db } from '../../../../imports/firebase';
import { compare, safeWhere } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';

import type {
  Filters,
  FiltersActionType,
  Folder as FolderType,
  Location,
} from '../../../../imports/types';

import { CollectionHeader, InnerPage, OverlaySidebar, SectionTitle } from '../../../../components';

/* nmotarization module imports */
import type { Receipt } from '../../../notarizations/imports/types';

/* tokencreator imports */
// TODO:mettere in comune?
import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import { ContractsList, DeleteContract } from '../../components';
import type { Contract } from '../../imports/types';

const initialState: {
  [key: string]: {
    filter: Filters;
    value: any;
  };
} = {
  inputFilterByName: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByTag: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByDate: { filter: 'NOTARIZATION_DATE_MATCH', value: '' },
};

const reducer = (state: typeof initialState, action: FiltersActionType) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        [action.filterFieldName]: { ...state[action.filterFieldName], value: action.payload },
      };
    default:
      return state;
  }
};

const Folder = () => {
  const { t } = useTranslation(['tokenCreator']);
  const { workspace } = useAppSelector((state) => state.team);

  const { uid } = useAppSelector((state) => state.user);

  const location = useLocation() as Location<{
    folder: string;
    folders: FolderType[];
  }>;
  const { state: folderState } = location;

  const orderByInitialState = { type: 'date', direction: 'desc' };
  const [orderBy, setOrderBy] = useState<{
    type: string;
    direction: string;
  }>(orderByInitialState);

  const [filtersState, filtersDispatch] = useReducer(reducer, initialState);

  // const showSidebarInitialState = { type: '', optionalProps: {} };
  // const [showSidebar, setShowSidebar] = useState<{ type: string; optionalProps?: any }>(
  //   showSidebarInitialState
  // );
  const showSidebarInitialState = { contractId: '', contractName: '' };
  const [showSidebar, setShowSidebar] = useState<{ contractId: string; contractName: string }>(
    showSidebarInitialState
  );
  const [isLoading, setIsLoading] = useState(true);
  const [folder, setFolder] = useState<FolderType>();
  const [folders, setFolders] = useState<Array<FolderType>>([]);

  const [contracts, setContracts] = useState<Array<Contract>>([]);
  const [receipts, setReceipts] = useState<Array<Receipt>>([]);

  const orderByOptions: {
    value: string;
    label: string;
    disabled?: boolean;
    bold?: boolean;
  }[] = [
    {
      value: 'order',
      label: t('collection.order_by.order'),
      bold: true,
    },
    {
      value: 'date-asc',
      label: t('collection.order_by.date_oldest'),
    },
    {
      value: 'date-desc',
      label: t('collection.order_by.date_recent'),
    },
    {
      value: 'name-asc',
      label: t('collection.order_by.name_asc'),
    },
    {
      value: 'name-desc',
      label: t('collection.order_by.name_desc'),
    },
  ];

  const handleSetOrderBy = (value: string) => {
    if (value === 'order') {
      setOrderBy({ ...orderBy, type: orderByInitialState.type });
      return;
    }
    const [type, direction] = value.split('-');
    setOrderBy({ type, direction });
  };

  // const handleShowSidebar = (type: SidebarType, optionalProps: any = {}) => {
  //   setShowSidebar({ type, optionalProps });
  // };

  const handleShowSidebar = (contractId: string, contractName: string) => {
    setShowSidebar({ contractId, contractName });
  };

  const handleCloseSidebar = () => {
    setShowSidebar(showSidebarInitialState);
  };

  const resetFilterField = (filterFieldName: FiltersActionType['filterFieldName']) => {
    filtersDispatch({
      type: 'SET_STATE',
      filterFieldName,
      payload: '',
    });
  };

  const {
    workspaceGroupObject: { groupId, isSuperGroup },
  } = useWorkspaceAndGroup();
  const activeGroupId = workspace?.activeGroupId;
  useEffect(() => {
    const folderDocRef = doc(db, 'folders', folderState.folder);
    const receiptsQuery = query(collection(db, 'receipts'));
    const foldersQuery = query(
      collection(db, 'folders'),
      ...safeWhere(uid, workspace?.id, isSuperGroup ? undefined : activeGroupId),
      where('type', '==', 'contract')
    );

    const unsubscribeFolders = onSnapshot(foldersQuery, async (querySnapshot) => {
      const folders: any[] = [];
      querySnapshot.forEach((doc) => {
        folders.push(doc.data());
      });

      setFolders(folders.sort((a, b) => (a.name > b.name ? 1 : -1)));
      setIsLoading(false);
    });

    const unsubscribeFolder = onSnapshot(folderDocRef, async (doc) => {
      const folderData = doc.data() as FolderType;

      if (folderData) {
        const contractsQuery = query(
          collection(db, 'contracts'),
          where('userId', '==', uid),
          where('folderId', '==', folderData.id)
        );

        onSnapshot(contractsQuery, async (querySnapshot) => {
          const contracts: any[] = [];

          querySnapshot.forEach((doc) => {
            contracts.push({ ...doc.data(), id: doc.id });
          });

          setFolder(folderData);
          setContracts(contracts.sort((a, b) => compare(a, b, 'date', 'desc')));
          setIsLoading(false);
        });
      }
    });

    const unsubscribeReceipts = onSnapshot(receiptsQuery, async (querySnapshot) => {
      const receipts: any[] = [];
      querySnapshot.forEach((doc) => {
        receipts.push(doc.data());
      });

      setReceipts(receipts);
    });

    return () => {
      unsubscribeFolder();
      unsubscribeReceipts();
    };
  }, [workspace?.id, activeGroupId]);

  return (
    <>
      <InnerPage>
        <CollectionHeader
          orderByOptions={orderByOptions}
          filtersState={filtersState}
          filtersDispatch={filtersDispatch}
          folder={folder}
          isFolder
          handleSetOrderBy={handleSetOrderBy}
          resetFilterField={resetFilterField}
          type="tokenCreator"
        />

        {folderState ? (
          <div className="mt-12 space-y-4">
            <SectionTitle title={t('collection.title_contracts')} />

            <ContractsList
              folders={folders}
              contracts={contracts}
              isLoading={isLoading}
              filtersState={filtersState}
              orderBy={orderBy}
            />
          </div>
        ) : (
          <div>{t('collection.no_folder_found')}</div>
        )}
      </InnerPage>

      <OverlaySidebar isOpen={!!showSidebar.contractId} handleClose={handleCloseSidebar}>
        {/* <OverlaySidebar isOpen={!!showSidebar.type} handleClose={handleCloseSidebar}> */}
        <div className="flex min-h-full w-full flex-col items-center space-y-4 pb-10">
          {showSidebar.contractId && (
            <DeleteContract
              handleCloseSidebar={handleCloseSidebar}
              contractId={showSidebar.contractId}
              contractName={showSidebar.contractName}
            />
          )}
        </div>
      </OverlaySidebar>
    </>
  );
};

export default Folder;
