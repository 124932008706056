import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { Button, Typography } from '../../../../components';

import { useLoadingStatusContext } from '../../../../context';
import { CHAIN_MODULES } from '../../../../imports/constants';
import { useAppSelector } from '../../../../redux/hooks';
import { grantPermission, handleBackendError } from '../../api/api';
import { WorkspaceMember } from '../../imports/types';
import { IconCheck, IconTicket } from '../../../../icons';

interface TeamMemberProps {
  member: WorkspaceMember;
  workspaceId: string;
  onClick?: () => void;
  pending?: boolean;
}
const TeamMember = ({ member, onClick, workspaceId, pending = false }: TeamMemberProps) => {
  const { t } = useTranslation(['team']);
  const { open: openLoadingModal } = useModal('LoadingModal');
  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();

  const {
    uid,
    wallet: { privateKey },
  } = useAppSelector((state) => state.user);

  const needsToBeVerified =
    member.permissions.notarization.write ||
    member.permissions.audit.write ||
    member.permissions.token_creator.write;

  const isPending = pending || member.status === 'pending';

  const isUnverified = !isPending && !member.verifiedOnChain && needsToBeVerified;

  const isGroupInvitation = member.inGroup === true;

  const namesplit = member.name?.split(' ');
  let nameInitials = 'AA';
  if (namesplit) {
    nameInitials = `${namesplit[0][0]}${namesplit[1] ? namesplit[1][0] : ''}`.toUpperCase();
  }

  /**
   * @description Function called after user accept invitation to the team
   * handle the blockchain function call
   * @param address
   * @param userId
   * @returns
   */
  const handleVerify = async (address: string, userId: string) => {
    try {
      openLoadingModal({});
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('manage.detail.pending'),
        },
      });
      if (workspaceId == null) throw new Error('Empty workspaceId');
      // First value needs to be setted, doesn't mind if is true or false beacuse index 0 is not releated to any modules
      await grantPermission(privateKey, workspaceId, address, userId, [
        false,
        ...CHAIN_MODULES.map(
          (mod: string) => member?.permissions[mod.toLowerCase()].write || false
        ),
      ]);
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('manage.detail.verify.success'),
        },
      });
    } catch (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('manage.detail.error'),
        },
      });
      handleBackendError(error);
    }
  };

  return (
    <div
      className="flex w-[300px] cursor-pointer items-center justify-between hover:opacity-75 active:opacity-100"
      // onClick={onClick}
    >
      <div className="flex items-center space-x-[12px]">
        {/* <Image src={ImageUser} alt="image of user" className="h-[30px] w-[30px]" /> */}
        {/* <div className=" h-[40px]">
          <UserIcon className="!h-[40px] !w-[40px]"></UserIcon>
        </div> */}

        <div className="flex size-[40px] items-center justify-center rounded-lg border border-primary-500 bg-primary-500">
          {/* <Image src={DEFAULT_USER_IMAGE} alt={t('avatar')} type="cover" /> */}
          {/* <img src={DEFAULT_USER_IMAGE} alt={t('avatar')} className="object-fill" /> */}

          <Typography color="white" size="body-regular-10">
            {nameInitials}
          </Typography>
        </div>
        <div className="flex flex-col justify-between">
          <Typography weight="medium" className="body-medium-18">
            {member.name}
          </Typography>
          <Typography className="body-regular-14">
            {/* {new Date(member.created_at).toLocaleDateString()} */}
            {member.email}
          </Typography>
        </div>
        <div className="flex items-center space-x-[8px]">
          {isPending && (
            <Typography weight="light" className="text-[12px] text-warning">
              {isGroupInvitation ? t('group_pending') : t('pending')}
            </Typography>
          )}
          {isUnverified && (
            <Button
              icon={IconCheck}
              iconStroke="white"
              iconSize="lg"
              action={() => handleVerify(member.address, member.user)}
              disabled={state.status === 'pending'}
            ></Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
