import { useTranslation } from 'react-i18next';
import { Icon, Typography } from '..';
import { IconNft } from '../../icons';
import { truncateText } from '../../imports/utils';

type NftBottomCardProps = {
  item: any;
};

const NftBottomCard = ({ item }: NftBottomCardProps) => {
  const { t } = useTranslation(['tokenCreator']);

  return (
    <>
      <div className="flex-column flex gap-2">
        <Icon icon={IconNft} size="lg" />
        <div className="flex flex-col items-start overflow-hidden truncate">
          <Typography size="body-medium-12">
            {truncateText(item.name, {
              maxLength: 20,
              rightChars: 0,
            })}
          </Typography>
          <div className="flex flex-row">
            {item.isUnlimited ? (
              <Typography size="body-regular-12" color="grey-400">
                {t('nft.unlimited') as string}
              </Typography>
            ) : (
              <>
                <Typography size="body-regular-12" color="grey-400">
                  {t('collection.quantity') + ': '}
                </Typography>
                <Typography size="body-regular-12" color="grey-400">
                  {item.quantity}
                </Typography>
              </>
            )}
          </div>
          <div className="flex flex-row">
            <Typography size="body-regular-12" color="grey-400">
              Token ID:{' '}
            </Typography>
            <Typography size="body-regular-12" color="grey-400">
              {item.id}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};
export default NftBottomCard;
