import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';

import { Button, Field, Icon, Input, Typography } from '../../../../components';
import StepperCard from '../../../../components/stepperCard/StepperCard';
import { useLoadingStatusContext } from '../../../../context';
import { usePrevious } from '../../../../hooks';
import { IconResearch } from '../../../../icons';
import { auth, db } from '../../../../imports/firebase';
import { useAppSelector } from '../../../../redux/hooks';
import { deleteUser, grantPermission, handleBackendError, isVerifiedOnChain } from '../../api/api';
import { orderTeamInvitations } from '../../common/utils';
import { Groups, TeamMember, Workspace } from '../../components';
import { WorkspaceInvitation, WorkspaceMember } from '../../imports/types';
import generateStepsForTable from './utils/generateStepsForTable';

import { useManageTeamsContext } from './ManageTeamsContext';
import { CHAIN_MODULES } from '../../../../imports/constants';

const ManageTeams = () => {
  const { t } = useTranslation(['team']);
  const [animate] = useAutoAnimate({ duration: 200 });
  const { open: openAddUser } = useModal('AddUserModal');

  const {
    data,
    handleAddUser,
    invited,
    loadInvitations,
    setSearchField,
    searchField,
    pendingRequest,
    setPendingRequest,
    currentSelectedUser,
    setCurrentSelectedUser,
  } = useManageTeamsContext();

  const {
    uid,
    wallet: { privateKey },
  } = useAppSelector((state) => state.user);
  const workspace = useAppSelector((state) => state.team.workspace);

  /**
   * Query to fetch members and unverified users
   */
  // const {
  //   data,
  //   isLoading: membersIsLoading,
  //   refetch: loadMembers,
  // } = useQuery(
  //   ['members', workspace?.id],
  //   async () => selectMember({ uid, searchField, workspace }),
  //   {
  //     enabled: false,
  //   }
  // );

  const iAmCurrentSelected = currentSelectedUser?.user === uid;
  const isMyTeam = workspace?.owner === uid;
  const canAddUsers = isMyTeam || workspace?.permissions?.team.write;
  const canDeleteUsers = (isMyTeam || workspace?.permissions?.team.delete) && !iAmCurrentSelected;

  const handleDeleteUser = async (user: WorkspaceMember) => {
    try {
      if (workspace == null) return;
      const isVerified = await isVerifiedOnChain(user?.address, workspace?.id);
      if (isVerified) {
        await grantPermission(
          privateKey,
          workspace?.id,
          user.address,
          user.user,
          new Array(CHAIN_MODULES.length).fill(false)
        );
      }
      await deleteUser(workspace?.id!, user.email);
      toast.success(t('manage.detail.delete.success') as string);
    } catch (error) {
      handleBackendError(error);
    }
  };

  // useFirestoreQuery has this problem so I will not use `{subscribe: true}`
  // https://github.com/invertase/react-query-firebase/issues/23

  return (
    <section ref={animate}>
      <div className="flex w-full items-end justify-between ">
        <div className="  w-[470px] min-w-[400px]">
          <Field label={t('manage.search_user')} textSize="body-regular-18">
            <Input
              value={searchField}
              type="text"
              placeholder={t('manage.search-placeholder')}
              onChange={({ target }) => setSearchField(target.value)}
              elementRight={<Icon icon={IconResearch} />}
              className="mt-[5px]"
            />
          </Field>
        </div>
        {canAddUsers && (
          <Button className="  max-w-[272px]" action={() => openAddUser({ handleAddUser })}>
            {t('manage.add-user')}
          </Button>
        )}
      </div>

      {!!invited?.length && !!workspace?.id && (
        <section className="mb-[60px] mt-[50px]">
          <Typography size="body-regular-20">{t('invites')}</Typography>

          <div className="mt-[20px] grid grid-cols-3 gap-[20px] px-[10px]">
            {invited?.map((doc) => (
              <TeamMember
                key={doc.email}
                workspaceId={workspace.id}
                member={doc as WorkspaceMember}
                pending
                // onClick={() => toggleSidebarUserDetail(doc as WorkspaceMember)}
                // onClick={() => {}}
              />
            ))}
          </div>
        </section>
      )}

      <section className="mt-5">
        <StepperCard
          steps={[
            {
              title: 'Workspace',
              component: <Workspace pendingRequest={pendingRequest} members={data?.members} />,
            },
            {
              title: t('groups'),
              component: (
                <Groups pendingRequest={pendingRequest} groupMembers={data?.groupMembers} />
              ),
            },
          ]}
        />
      </section>

      {/* {currentSelectedUser && (
        <UserDetailOverlay
          user={currentSelectedUser}
          handleClose={() => toggleSidebarUserDetail(undefined)}
          ctaAction={handleDeleteUser}
          canDeleteUsers={!!canDeleteUsers}
          isMyTeam={isMyTeam}
          verify={handleVerify}
          changePermissions={handleChangePermissions}
          workspaceId={workspace?.id}
        />
      )} */}
    </section>
  );
};

export default ManageTeams;
