import { omit } from 'lodash';
import { uploadFilesOnStorage } from '../modules/tokenCreator/api/ipfs';
import { Nft } from '../modules/tokenCreator/imports/types';
import { NFT_ATTRIBUTES, freeIpfsGateway } from './constants';
import { isFileTypeImage } from './utils';

export const cleanNftData = (nft: Nft) => ({
  ...omit(nft, ['isUnlimited', 'quantity', 'id']),
  attributes: nft.attributes
    ?.filter((attribute) => !attribute.private)
    .map((attribute) => ({ ...omit(attribute, ['file', 'private', 'id']) })),
});

export const cleanMetadataBeforeUpload = (nfts: Nft[]) => {
  return nfts.map((nft) => cleanNftData(nft));
};

/**
 * @description Function that takes in input Nft[] and handle the update of the attributes' images
 *
 * @param nfts
 * @param param1
 * @param workspaceId
 * @returns Nft[] with update attributes remove file and store image url in link field
 */
export const storeAttributedData = (
  nfts: Nft[],
  { name, contractId }: { name: string; contractId: string },
  workspaceId: string
) => {
  return nfts.map(async (nft, tokenIndex) => {
    if (nft.attributes) {
      return {
        ...nft,
        attributes: await Promise.all(
          nft.attributes.map(async (attribute, i) => {
            if (NFT_ATTRIBUTES.includes(attribute.type)) {
              if (attribute.file && attribute.file?.length > 0) {
                const value = (
                  await uploadFilesOnStorage({
                    file: attribute.file[0],
                    contractId,
                    tokenId: `${name}${tokenIndex + 1}`,
                    workspaceId,
                    storageType: attribute.private ? 'bcode' : 'ipfs',
                  })
                ).files[0];

                return {
                  ...omit(attribute, ['file', 'link']),
                  value:
                    attribute.type === 'partner'
                      ? attribute.value
                      : attribute.private
                        ? value
                        : `${freeIpfsGateway}/${value}`,
                  //   NOTE: 04/04/2023 Michele questo check é stato eliminato da link per far funzionare update!isFileTypeImage(attribute.file[0])
                  // ? ''
                  // :
                  link: attribute.private ? value : `${freeIpfsGateway}/${value}`,
                };
              }
              return {
                ...omit(attribute, ['file']),
                value:
                  attribute.type === 'partner'
                    ? attribute.value
                    : attribute.link || attribute.value,
              };
            }
            return omit(attribute, ['file', 'link']);
          })
        ),
      };
    }
    return nft;
  });
};
