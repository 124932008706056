import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Form } from 'react-router-dom';
import Modal, { useModalState } from 'react-simple-modal-provider';

import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { collection, doc, getDoc, onSnapshot, query } from 'firebase/firestore';
import { useQuery } from 'react-query';
import { queryClient } from '../..';
import { usePrevious } from '../../hooks';
import { auth, db } from '../../imports/firebase';
import { capitalizeFirstLetter } from '../../imports/utils';
import { getTeam, getWorkspaceCustomers } from '../../modules/team/api/api';
import { orderTeamInvitations, orderTeamMembers } from '../../modules/team/common/utils';
import { Workspace, WorkspaceInvitation, WorkspaceMember } from '../../modules/team/imports/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setWorkspace } from '../../redux/team/team.slice';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type ChangeAccountModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { t } = useTranslation(['team']);
  const { uid } = useAppSelector((state) => state.user);
  const [searchField, setSearchField] = useState('');
  const { workspace } = useAppSelector((state) => state.team);
  const prevWorkspaceId = usePrevious(workspace?.id);
  const {
    data: invited,
    error: invitationsError,
    refetch: loadInvitations,
  } = useFirestoreQuery(
    ['invitations', workspace?.id],
    collection(db, 'workspaces', workspace?.id!, 'invitations'),
    {},
    {
      enabled: false,
      select(snapshot) {
        return orderTeamInvitations(
          snapshot?.docs.map((doc) => doc.data() as WorkspaceInvitation) ?? [],
          searchField
        );
      },
      onError(error) {
        console.log('Error while fetching invitations', error);
      },
    }
  );
  const {
    data: teamsData,
    error: teamsError,
    isLoading: teamsIsLoading,
    refetch: loadTeams,
  } = useQuery(['teams', workspace?.id], getTeam, { enabled: false });

  const dispatch = useAppDispatch();

  const {
    data: members,
    error: membersError,
    isLoading: membersIsLoading,
    refetch: loadMembers,
  } = useFirestoreQuery(
    ['members', workspace?.id],
    collection(db, 'workspaces', workspace?.id!, 'members'),
    {},
    {
      enabled: false,
      select(snapshot) {
        let members = orderTeamMembers(
          snapshot?.docs?.map((doc) => doc.data() as WorkspaceMember) ?? [],
          ''
        );

        if (!workspace?.isOwner) {
          members = members?.filter((member) => member.user !== workspace?.owner);
        }

        return members
          ?.map((member) => (member.user === uid ? { ...member, name: t('you') } : member))
          .sort((member1, member2) => member1.created_at - member2.created_at);
      },
      onError(error) {
        console.log('Error while fetching members', error);
      },
    }
  );

  useEffect(() => {
    if (workspace?.id && prevWorkspaceId !== workspace?.id) {
      if (auth.currentUser) {
        loadTeams();
        loadMembers();
        loadInvitations();
      }

      const membersUnsub = onSnapshot(
        query(collection(db, 'workspaces', workspace?.id, 'members')),
        (snaps) => {
          queryClient.setQueryData(['members', workspace?.id], snaps);
        }
      );

      const invitationsUnsub = workspace?.permissions?.team?.read
        ? onSnapshot(query(collection(db, 'workspaces', workspace?.id, 'invitations')), (snaps) => {
            queryClient.setQueryData(['invitations', workspace?.id], snaps);
          })
        : () => {};

      return () => {
        membersUnsub();
        invitationsUnsub();
      };
    }
  }, [workspace?.id]);

  const changeWorkspace = async (
    workspace: Workspace,
    groupId?: string,
    isSuperGroup?: boolean
  ) => {
    if (workspace.id) {
      const memberResponse = await getDoc(doc(db, 'workspaces', workspace.id, 'members', uid));
      const member = memberResponse.data()!;
      const customers = await getWorkspaceCustomers(workspace?.owner || '');
      dispatch(
        setWorkspace({
          id: workspace?.id,
          owner: workspace?.owner,
          name: workspace?.name,
          permissions: member?.permissions,
          ownerAddress: workspace?.owner_address,
          isOwner: workspace?.owner === uid,
          templatePack: workspace?.templatePack || ['standard'],
          customers,
          activeGroupId: groupId ?? undefined,
          isSuperGroup: isSuperGroup ?? false,
          groupIds: workspace?.groupIds,
          inGroup: workspace?.inGroup,
        })
      );
    }
  };

  const teams =
    !!teamsData?.length && teamsData.length > 1
      ? teamsData?.filter((team) => team.inGroup !== true)
      : [];
  const teamsInGroup =
    !!teamsData?.length && teamsData.length > 1
      ? teamsData?.filter((team) => team.inGroup === true)
      : [];

  return (
    <ModalLayout
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      // classNameBg="pl-[17%]"
      // classNameLayout="w-[530px] h-[360px] overflow-auto "
      width="400px"
      height="400px"
      classNameLayout="overflow-auto"
    >
      <div className="mb-16 mt-[20px] flex flex-col justify-start gap-[16px] px-[50px] pt-[60px]">
        <Typography size="body-medium-18" weight="medium">
          {t('workspaces')}{' '}
        </Typography>

        {teams?.map((team, i) => {
          const name = team?.owner === uid ? `${team?.name}` : team.name;
          const style = team.id === workspace?.id ? 'primary' : 'secondary';

          return (
            // Setting the id will retrigger the useQuery in TeamWrapper,
            // causing the team switch
            <div key={team + '_' + i}>
              <Button
                id={`workspace-${team?.id}`}
                key={i}
                type={style}
                action={() => {
                  changeWorkspace(team);
                  setOpen(false);
                }}
                className="!h-[30px] w-full max-w-[350px] rounded-[6px]"
              >
                {name}
              </Button>
            </div>
          );
        })}
        {teamsInGroup && teamsInGroup.length > 0 && (
          <div className="mb-16 mt-[20px] flex flex-col justify-start gap-[16px]">
            <Typography size="body-medium-18" weight="medium">
              {capitalizeFirstLetter(t('groups'))}{' '}
            </Typography>
            {/* if I am in a group i want a list of buttons of the groups i am in */}
            {teamsInGroup?.map((team, i) => {
              const name = team?.owner === uid ? `${team?.name}` : team.name;
              const style = team.id === workspace?.id ? 'primary' : 'secondary';

              return team.groupIds?.map((groupId: string) => {
                const groupName = team.groupsDetails[groupId]?.name;
                const isSuperGroup = team.groupsDetails[groupId]?.isSuperGroup;
                return (
                  <Button
                    key={groupId + '_' + i}
                    type={style}
                    action={() => {
                      changeWorkspace(team, groupId, isSuperGroup);
                      setOpen(false);
                    }}
                    className="!h-[30px] w-full max-w-[350px] rounded-[6px]"
                  >
                    {`${name}: ${groupName}`}
                  </Button>
                );
              });
            })}
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

const ChangeAccountModal = ({ children }: ChangeAccountModalProps) => {
  const [isOpen, setOpen] = useModalState();
  const { t } = useTranslation();
  return (
    <Modal id="ChangeAccountModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};

export default ChangeAccountModal;
