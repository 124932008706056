import getUnixTime from 'date-fns/getUnixTime';
import omit from 'lodash/omit';

import {
  DocumentData,
  DocumentReference,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import axios from 'axios';
import { ref, remove } from 'firebase/database';
import type {
  Contract,
  FunctionalitiesNews,
  FunctionalitiesProduct,
  FunctionalitiesRestrictedArea,
  FunctionalitiesShop,
  FunctionalityData,
  Nft,
} from '../imports/types';

import { auth, database, db } from '../../../imports/firebase';

import { getSubscription } from '../../../api/firebase';
import { backendEndpoint } from '../../../imports/constants';
import { AvailableOn, Message, PollForm } from '../../../imports/types';

const result = ({ value, error }: { value?: any; error?: any }) => ({ value, error });

export const saveContract = async (
  contract: Omit<
    Contract,
    'address' | 'createdAt' | 'nfts' | 'qrCodes' | 'version' | 'updatedAt'
  > & {
    inGroup?: boolean;
    groupId?: string;
    workspace_id?: string;
  }
) => {
  const timestamp = getUnixTime(Date.now());

  await setDoc(doc(db, 'contracts', contract.id), {
    ...omit(contract, 'qrCodeDropVal'),
    qrCodeDrop: Number(contract.qrCodeDropVal),
    createdAt: timestamp,
    status: 'ready',
    version: 2,
  });
};

export const getContract = async (contractId: string) => {
  const contract = (await getDoc(doc(db, 'contracts', contractId))).data() as Contract;

  if (contract) {
    const nfts = (await getDocs(collection(db, `contracts/${contractId}/nfts`))).docs.map(
      (doc) => doc.data() as Nft
    );

    return { ...contract, nfts };
  }

  return contract;
};

export const updateContract = async (contractId: string, updatedData: object) => {
  try {
    await updateDoc(doc(db, 'contracts', `${contractId}`), {
      ...updatedData,
    });

    return result({ value: 'success' });
  } catch (error) {
    return result({ error });
  }
};

export const deleteContract = async (contractId: string) => {
  try {
    await deleteDoc(doc(db, 'contracts', `${contractId}`))
      .then(() => {
        remove(ref(database, contractId))
          .then(() => {
            //console.log('Document successfully deleted!');
          })
          .catch((error) => {
            console.error('Error removing document: ', error);
          });
        // console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });
    return result({ value: 'success' });
  } catch (error) {
    return result({ error });
  }
};

export const deleteFolder = async (folderId: string, notarizationsId: string[]) => {
  try {
    await Promise.all(
      notarizationsId.map(async (contractsId) => {
        const docRef = doc(db, 'contracts', contractsId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          await setDoc(docRef, { ...data, folderId: null });
        }
      })
    );

    await deleteDoc(doc(db, 'folders', folderId));

    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

export const getLeftContracts = async (userId: string) => {
  try {
    const { value: subscription } = await getSubscription(userId);
    const contractsLeft = subscription.maxContractsNumber - subscription.currentContractsNumber;

    return result({ value: contractsLeft });
  } catch (e) {
    return result({ error: e });
  }
};

export const getDraftContracts = async (address: string) => {
  try {
    const contractsQuery = query(
      collection(db, 'contracts'),
      where('owner', '==', address.toLowerCase())
    );

    const contracts = await getDocs(contractsQuery);

    const draftContracts = contracts.docs
      .map((contract) => contract.data())
      .filter((contract) => contract.status === 'created');

    return result({ value: draftContracts.length });
  } catch (e) {
    return result({ error: e });
  }
};

export const createFolder = async (uid: string, name: string) => {
  const timestamp = getUnixTime(Date.now());
  const folderId = `${name.replace(/\s/g, '')}${timestamp}`;

  try {
    await setDoc(doc(db, 'folders', folderId), {
      id: folderId,
      name,
      userId: uid,
      type: 'collection',
      collectionsId: [],
      createdAt: timestamp,
    });

    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

export const getContracts = async () => {
  const token = await auth.currentUser?.getIdToken();
  const { data } = await axios.get(`${backendEndpoint}/getAllNfts`, {
    headers: { authorization: token ?? '' },
  });
  return data as Contract[];
};

export const getMessages = async () => {
  const token = await auth.currentUser?.getIdToken();

  const { data } = await axios.get(`${backendEndpoint}/getMessages`, {
    headers: { authorization: token ?? '' },
  });

  return data as { [key: string]: Message };
};

/**
 * Returns a new functionality reference in the database.
 * @param edit
 * @returns new functionality reference
 */
export const getFunctionalityRef = (
  edit: FunctionalitiesRestrictedArea | FunctionalitiesShop | null
): DocumentReference<DocumentData> => {
  let newFunctionalityRef: DocumentReference<DocumentData>;
  if (edit) {
    newFunctionalityRef = doc(collection(db, 'functionalities'), edit.id);
  } else {
    newFunctionalityRef = doc(collection(db, 'functionalities'));
  }
  return newFunctionalityRef;
};

/**
 * Returns a new collection reference in the database.
 * @param collectionPath
 * @param edit
 * @returns new collection reference
 */
export const getCollectionRef = (
  collectionPath: string,
  edit:
    | FunctionalitiesRestrictedArea
    | FunctionalitiesShop
    | FunctionalitiesNews
    | FunctionalitiesProduct
    | null
): DocumentReference<DocumentData> => {
  let newCollectionRef: DocumentReference<DocumentData>;
  if (edit) {
    newCollectionRef = doc(collection(db, collectionPath), edit.id);
  } else {
    newCollectionRef = doc(collection(db, collectionPath));
  }
  return newCollectionRef;
};

/**
 * Returns a poll reference in the database.
 * @param edit
 * @returns poll reference
 */
export const getPollRef = (edit: PollForm): DocumentReference<DocumentData> => {
  return doc(collection(db, 'polls'), edit.id);
};

/**
 * Set functionality in the database.
 * @param functionalityRef
 * @param functionality
 * @param isMerge
 * @returns value or error
 */
export const setFunctionality = async (
  functionalityRef: DocumentReference<DocumentData>,
  functionality:
    | FunctionalitiesRestrictedArea
    | FunctionalitiesShop
    | FunctionalitiesNews
    | FunctionalitiesProduct,
  isMerge: boolean
) => {
  try {
    await setDoc(functionalityRef, functionality, { merge: isMerge });
    return result({ value: true });
  } catch (e) {
    return result({ error: e });
  }
};

/**
 * Set data to an Nft in the database.
 * Data is added at first level of the Nft for example (shop):
 * const myNft = {
 *  name: 'myNft',
 *  id: '1',
 *  shop: {
 *   value : 'myShop',
 *   id: 'cn456d,
 *  },...
 * @param db
 * @param contractId
 * @param nft
 * @param data
 * @param isMerge
 * @returns value or error
 */
export const setNftData = async (contractId: string, nft: Nft, data: FunctionalityData) => {
  try {
    const docId = `${nft.name || ''}${nft.id.toString()}`.replaceAll(' ', '');
    const docRef = doc(db, `contracts/${contractId}/nfts`, docId);

    await setDoc(docRef, { ...data, updatedAt: Date.now() }, { merge: true });
    return result({ value: true });
  } catch (e) {
    return result({ error: e });
  }
};

/**
 * Remove a specific field from an Nft in the database.
 * FYI: Field should be a first level field of the Nft and have type keyof Nft.
 * @param contractId
 * @param nft
 * @param data
 * @returns value or error
 */
export const removeFieldFromNft = async (
  contractId: string,
  nft: Nft,
  fieldToRemove: keyof Nft
) => {
  try {
    const docId = `${nft.name || ''}${nft.id.toString()}`.replaceAll(' ', '');
    const docRef = doc(db, `contracts/${contractId}/nfts`, docId);
    const updateData = {
      [fieldToRemove]: deleteField(),
    };
    await updateDoc(docRef, updateData);
    return result({ value: true });
  } catch (e) {
    return result({ error: e });
  }
};

/**
 * Updates functionalities - availableOn field in the database.
 * @param newAttributesRef
 * @param availableOn
 * @returns value or error
 */
export const updateAvailableOn = async (
  newAttributesRef: DocumentReference,
  availableOn: AvailableOn
) => {
  try {
    await updateDoc(newAttributesRef, {
      availableOn,
    });

    return result({ value: true });
  } catch (e) {
    return result({ error: e });
  }
};

/**
 * Deletes the functionality doc from database
 * @param functionalityId
 * @returns value or error
 */
export const deleteFunctionalityDoc = async (functionalityId: string) => {
  try {
    await deleteDoc(doc(db, 'functionalities', functionalityId));

    return result({ value: true });
  } catch (e) {
    return result({ error: e });
  }
};

/**
 * set poll in the database.
 * @param newPollRef
 * @param poll
 * @returns value or error
 */
export const setPoll = async (newPollRef: DocumentReference, poll: PollForm) => {
  try {
    await setDoc(newPollRef, poll, { merge: true });
    return result({ value: true });
  } catch (e) {
    return result({ error: e });
  }
};

/**
 * Set poll availableOn field in the database.
 * @param newPollRef
 * @param availableOn
 * @returns value or error
 */
export const setPollAvailableOn = async (
  newPollRef: DocumentReference<DocumentData>,
  availableOn: AvailableOn
) => {
  try {
    await updateDoc(newPollRef, {
      availableOn,
    });

    return result({ value: true });
  } catch (e) {
    return result({ error: e });
  }
};

/**
 * Deletes element from db
 * @param functionalityId
 * @returns value or error
 */
export const deleteDocument = async (docId: string, collection: string) => {
  try {
    await deleteDoc(doc(db, collection, docId));

    return result({ value: true });
  } catch (e) {
    return result({ error: e });
  }
};

/**
 * @description Utility function to update with merge NFT document on contracts collection
 * @param nft
 * @param param1
 * @returns Nft object
 */
export const updateNft = async (nft: Nft, contractId: string) => {
  await updateDoc(
    doc(db, `contracts/${contractId}/nfts`, `${nft.name.replaceAll(' ', '')}${nft.id}`),
    nft
  );
  return nft;
};
