import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import {
  Button,
  Checkbox,
  CustomTooltip,
  Field,
  Select,
  Typography,
} from '../../../../../components';
import useWorkspaceAndGroup from '../../../../../hooks/useWorkspaceAndGroup';
import { db } from '../../../../../imports/firebase';
import { Folder } from '../../../../../imports/types';
import { safeWhere } from '../../../../../imports/utils';
import { useAppSelector } from '../../../../../redux/hooks';
import { CustomFieldsProps } from '../../../imports/types';
import i18n from '../../../../../i18n';

const NotarizationsCustomField = ({
  errors,
  category,
  index,
  values,
  setValue,
  options,
  fieldType,
  fieldPrivate,
  howMany = 0,
}: CustomFieldsProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const [folders, setFolders] = useState<Array<Folder>>([]);
  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const template = useAppSelector((state) => state.template);
  const { nfts } = values;
  const { uid, workspace } = useAppSelector(({ user, team }) => ({
    ...user,
    workspace: team.workspace,
  }));
  const attributeCustomType = nfts?.[category]?.attributes?.[index]?.customType;
  const templateField = template.customFields.find(
    (field: any) => field.value === attributeCustomType
  );
  const { open: onpenSelectFolderModal } = useModal('SelectFolderModal');

  const {
    workspaceGroupObject: { groupId, isSuperGroup },
  } = useWorkspaceAndGroup();
  const activeGroupId = workspace?.activeGroupId;
  useEffect(() => {
    setSelectedFolder(
      folders?.find(
        (folder: Folder) => folder.id === values.nfts?.[category]?.attributes?.[index]?.value
      )
    );
  }, [values.nfts?.[category]?.attributes?.[index]?.value, folders]);

  useEffect(() => {
    const foldersQuery = query(
      collection(db, 'folders'),
      ...safeWhere(uid, workspace?.id, isSuperGroup === true ? undefined : activeGroupId),
      where('type', '==', 'notarization')
    );
    const unsubscribeFolders = onSnapshot(foldersQuery, async (querySnapshot) => {
      const folders: any[] = [];
      querySnapshot.forEach((doc) => {
        folders.push(doc.data());
      });

      setFolders(folders.sort((a, b) => (a.name > b.name ? 1 : -1)));
    });

    setValue(
      `nfts[${category}].attributes[${index}].trait_type`,
      templateField?.fields.trait_type?.label?.[i18n.language] || 'Notarizations'
    );

    return () => {
      unsubscribeFolders();
    };
  }, [workspace?.id, activeGroupId]);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="felx-row flex gap-2 ">
        <Field
          error={errors.nfts?.[category]?.attributes?.[index]?.type?.message}
          label={t('create_contract.form_labels.attribute')}
          className="!w-1/2"
        >
          <Select
            options={options}
            error={errors.nfts?.[category]?.attributes?.[index]?.type?.message}
            name={fieldType.name}
            // onBlur={fieldType.onBlur}
            onChange={(change) => {
              const customFieldFound = template.customFields.find(
                (field: any) => field.value === change.target.value
              );

              setValue(`nfts[${category}].attributes[${index}].customType`, change.target.value);

              setValue(`nfts[${category}].attributes[${index}].type`, customFieldFound?.type);
              if (customFieldFound?.fields.trait_type?.value) {
                setValue(
                  `nfts[${category}].attributes[${index}].trait_type`,
                  customFieldFound?.fields.trait_type?.value
                );
              } else {
                setValue(`nfts[${category}].attributes[${index}].trait_type`, '');
              }
              // setValue(`nfts[${category}].attributes[${index}].trait_type`, '');
              setValue(`nfts[${category}].attributes[${index}].value`, '');
            }}
            inputRef={nfts?.[category]?.attributes?.[index]?.type.ref}
            defaultValue={
              options.find(
                (option) => option.value === nfts?.[category]?.attributes?.[index]?.customType
              )?.value
            }
          />
        </Field>
        <div className="mb-2 flex w-1/2 items-end justify-between">
          <div className="flex items-center">
            <Typography>{t('create_contract.form_labels.private_field') as string}</Typography>
            <CustomTooltip>
              <Typography size="xs">
                {t('create_contract.form_tooltips.private_field') as string}
              </Typography>
            </CustomTooltip>
          </div>

          <Checkbox
            name={fieldPrivate.name}
            id={fieldPrivate.name}
            onChange={fieldPrivate.onChange}
            inputRef={fieldPrivate.ref}
            onBlur={fieldPrivate.onBlur}
            isChecked={values.nfts?.[category]?.attributes?.[index]?.private}
          />
        </div>
      </div>
      {/* SECOND ROW */}
      {howMany > 1 && (
        <Typography color="error">
          {t('create_contract.form_labels.only_one_notarization_field')}
        </Typography>
      )}

      <div className="mt-2 flex items-center justify-between gap-2">
        <Button
          className="!w-1/2 rounded-[4px]"
          type="secondary"
          action={() => onpenSelectFolderModal({ folders, category, index, setValue, values })}
          disabled={howMany > 1}
        >
          {t('create_contract.form_labels.select_notarizations_folder')}
        </Button>

        <div className="!w-1/2">
          <Field
            error={
              !selectedFolder?.name && errors.nfts?.[category]?.attributes?.[index]?.value?.message
            }
          >
            <Typography color="grey-600">
              {t('create_contract.form_labels.selected_folder')}:
            </Typography>
            <Typography color="grey-600" size="body-regular-14" className="ml-px">
              {selectedFolder?.name || t('create_contract.form_placeholders.no_folder_selected')}
            </Typography>
          </Field>
        </div>
      </div>
    </div>
  );
};

export default NotarizationsCustomField;
