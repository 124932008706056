import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Typography } from '..';
import { IconLock } from '../../icons';
import { Template } from '../../modules/tokenCreator/imports/types';
import Image from '../image/Image';
import { addDocument } from '../../imports/firestore.utils';
import i18n from '../../i18n';

type TemplateCardProps = {
  template: Template;
  onClick: any;
  isSelected: boolean;
  disabled?: boolean;
};
const TemplateCard = ({ template, onClick, isSelected, disabled = false }: TemplateCardProps) => {
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation(['tokenCreator']);
  const handleonClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div className="relative ">
      <button
        type="button"
        data-cy={template?.name.replaceAll(' ', '').toLowerCase()}
        onClick={() => handleonClick()}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={` flex h-[200px] w-[173px] flex-col  rounded-lg border    
       ${isSelected && 'border-blue border-2'}
       ${disabled && 'bg-grey4'}
      ${isHover && 'border-blue  bg-indaco2'}  shadow-md `}
        disabled={disabled}
      >
        <div className="flex size-full cursor-pointer flex-col place-items-center gap-3 rounded-lg">
          <Image
            src={template?.image}
            type="cover"
            className="mt-3 h-[100px] w-[150px] rounded-lg"
          />
          <div>
            <Typography className="max-h-[80px]  max-w-[150px] overflow-hidden break-words text-center">
              {/* {template?.type !== 'standard'
                ? template?.name
                : (t(`create_contract.template.${template?.name}`) as string)} */}
              {template?.labels?.name[i18n.language] ??
                (t(`create_contract.template.${template?.name}`) as string)}
            </Typography>
            {template?.by && (
              <Typography
                size="body-regular-10"
                className="max-h-[80px]  max-w-[150px] overflow-hidden break-words text-center"
              >
                {t('create_contract.by', { creator: template?.by })}
              </Typography>
            )}
          </div>
        </div>
      </button>
      {disabled && (
        <div className="absolute inset-0 z-20 flex size-full cursor-pointer flex-col place-items-center gap-3 rounded-lg bg-grey-600/75">
          <div className="flex h-full flex-col ">
            <div className="mt-3 flex h-[100px] w-[150px] items-center justify-center rounded-lg">
              <Icon icon={IconLock} size="xl" stroke="white" />
            </div>
            <Typography
              size="body-medium-14"
              className="max-h-[80px]  max-w-[150px] overflow-hidden break-words text-center"
              color="white"
            >
              {
                t(
                  `create_contract.template.${
                    template?.name === 'standard' ? 'lockedStandard' : 'lockedCustom'
                  }`
                ) as string
              }
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
export default TemplateCard;
