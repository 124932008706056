import { useMemo, useReducer } from 'react';
import { getGamingLeaderboards } from '../imports/analyticsParser';
import { Contract } from '../modules/tokenCreator/imports/types';

const useGamingAnalyticsFilters = ({
  contractId,
  contractsList,
}: {
  contractId: string;
  contractsList: Contract[];
}) => {
  const initialState = {
    gamingNameFilter: '',
    gamingDateFilter: NaN,
    gamingPage: 0,
    filterMin: -1,
    filterMax: -1,
  };

  const reducer = (state: any, action: { type: any; payload: any }) => {
    switch (action.type) {
      case 'SET_GAMING_NAME_FILTER':
        return { ...state, gamingNameFilter: action.payload };
      case 'SET_GAMING_DATE_FILTER':
        return { ...state, gamingDateFilter: action.payload };
      case 'SET_GAMING_PAGE':
        return { ...state, gamingPage: action.payload };
      case 'SET_FILTER_MIN':
        return { ...state, filterMin: action.payload };
      case 'SET_FILTER_MAX':
        return { ...state, filterMax: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { gamingNameFilter, gamingDateFilter, gamingPage, filterMin, filterMax } = state;

  const setGamingNameFilter = (value: string) => {
    dispatch({ type: 'SET_GAMING_NAME_FILTER', payload: value });
  };

  const setGamingDateFilter = (value: any) => {
    dispatch({ type: 'SET_GAMING_DATE_FILTER', payload: value });
  };

  const setGamingPage = (value: number) => {
    dispatch({ type: 'SET_GAMING_PAGE', payload: value });
  };

  const setFilterMin = (value: number) => {
    dispatch({ type: 'SET_FILTER_MIN', payload: value });
  };

  const setFilterMax = (value: number) => {
    dispatch({ type: 'SET_FILTER_MAX', payload: value });
  };

  const gaming = useMemo(() => {
    return getGamingLeaderboards(contractsList, contractId || '')
      .filter((game) => gamingNameFilter === '' || game.name.includes(gamingNameFilter))
      .filter(
        (game) => Number.isNaN(gamingDateFilter) || game.endTimestamp * 1000 >= gamingDateFilter
      )
      .filter((game) => (filterMin > -1 ? Math.ceil(game.score) >= filterMin : true))
      .filter((game) => (filterMax > -1 ? Math.ceil(game.score) <= filterMax : true));
  }, [contractsList, contractId, gamingNameFilter, gamingDateFilter, filterMin, filterMax]);

  return {
    gaming,
    gamingNameFilter,
    gamingDateFilter,
    setGamingNameFilter,
    setGamingDateFilter,
    setGamingPage,
    setFilterMin,
    setFilterMax,
    gamingPage,
    filterMin,
    filterMax,
  };
};

export default useGamingAnalyticsFilters;
