import type { ChangeEvent } from 'react';
import { useState } from 'react';

import type { InputType } from '../../imports/types';

import Typography from '../typography/Typography';

import './textarea.css';

type TextareaProps = InputType & {
  rows?: number;
  cols?: number;
  maxLength?: number;
  resize?: boolean;
  className?: string;
};

const Textarea = ({
  id,
  name,
  placeholder,
  disabled,
  error,
  success,
  onBlur,
  onChange,
  inputRef,
  rows,
  cols,
  value,
  maxLength,
  className,
  resize = false,
}: TextareaProps) => {
  const [valueLength, setValueLength] = useState(0);

  const handleValueLength = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValueLength(event.target.value.length);
  };

  const getTextareaStatus = () => {
    let status = '';

    if (error) {
      status = 'error';
    }
    if (success) {
      status = 'success';
    }
    if (disabled) {
      status = 'disabled';
    }

    return status;
  };

  const getTextareaSize = () => {
    let size = '';

    if (!rows) {
      size = `${size} full-width`;
    }

    if (!cols) {
      size = `${size} full-height`;
    }

    return size;
  };

  return (
    <div className={`textarea-container ${getTextareaSize()} ${className} `}>
      <textarea
        className={`textarea ${getTextareaStatus()} ${
          resize ? 'resize' : 'resize-none'
        } rounded-[10px] border-DEFAULT !border-grey-500`}
        id={id}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        onChange={(e) => {
          if (onChange) onChange(e);
          if (maxLength) handleValueLength(e);
        }}
        ref={inputRef}
        maxLength={maxLength}
        rows={rows}
        cols={cols}
        value={value}
      />

      {maxLength && (
        <div className="length-container">
          <Typography size="body-regular-16">
            {valueLength}/{maxLength}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Textarea;
