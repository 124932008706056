import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, ShowMore, Typography } from '../../../../components';
import { ExportedProps } from '../../../../components/form/Form';
import { EMPTY_NFT } from '../../imports/constants';
import { Nft, NftAttribute, Template } from '../../imports/types';
import NftComponent from './NftComponent';
import { useAppSelector } from '../../../../redux/hooks';
import i18n from '../../../../i18n';

interface INftsStep
  extends Omit<
    ExportedProps,
    'trigger' | 'reset' | 'getValues' | 'watch' | 'clearErrors' | 'isValid'
  > {
  isLoading: boolean;
  watch: any;
  contractsLeft: number;
  values: any;
  onBack: () => void;
  topBarElements?: (nft: Nft) => ReactNode;
  quantityModifiable?: boolean;
  title: string;
  ctaLabel: string;
  templates: Template[];
  onCompleted: () => void;
}

const NftsStep: React.FC<INftsStep> = ({
  contractsLeft,
  quantityModifiable,
  title,
  errors,
  templates,
  handleSubmit,
  values,
  ctaLabel,
  resetField,
  control,
  fields,
  topBarElements,
  setValue,
  isLoading,
  register,
  onBack,
  watch,
  onCompleted,
  dirtyFields,
}) => {
  const { t } = useTranslation(['tokenCreator']);
  const { doesExpire, nfts, numberOfCategories } = values;
  const template = useAppSelector((state) => state.template);
  const [isImageValid, setIsImageValid] = useState<boolean>(true);
  const language = i18n.language;
  const [howManyNotarizations, setHowManyNotarizations] = useState(0);
  const [isUnlimited, setIsUnlimited] = useState(false);
  useEffect(() => {
    if (template.fields?.isUnlimited?.hidden) {
      setValue('nfts[0].isUnlimited', template.fields?.isUnlimited?.value);
    }
  }, []);

  const addCategory = () => {
    if (!isUnlimited) {
      const newNfts = nfts;
      newNfts.push(EMPTY_NFT);
      setValue('nfts', newNfts);
      setValue('numberOfCategories', numberOfCategories + 1);
    }
  };
  useEffect(() => {
    let howMany: number = 0;
    nfts.forEach((nft: Nft) => {
      let partialHowMany = 0;
      if (nft.attributes) {
        nft.attributes.forEach((attribute: NftAttribute) => {
          if (attribute.type === 'notarizations') {
            partialHowMany += 1;
          }
        });
      }
      if (partialHowMany > howMany) {
        howMany = partialHowMany;
      }
    });
    setHowManyNotarizations(howMany);
  }, [values]);

  const maxSizeReached = (maxSize: number) => {
    let totalSize = 0;
    totalSize += values.contractImage[0].size;
    nfts.forEach((nft: Nft) => {
      if (nft.image[0] && typeof nft.image[0] !== 'string') {
        totalSize += nft.image[0].size;
      }
      nft.attributes?.forEach((attribute: NftAttribute) => {
        if (attribute.file && attribute.file?.length > 0) {
          totalSize += attribute.file[0].size;
        }
      });
    });
    if (totalSize > maxSize) toast.warning(t('create_contract.max_size_reached') as string);
    return totalSize > maxSize;
  };
  useEffect(() => {
    const isUnlimited = values.nfts.some((nft: Nft) => nft.isUnlimited);
    setIsUnlimited(isUnlimited);
  }, [values.qrCodeDropVal]);

  return (
    <>
      <div className="space-y-4">
        <Typography weight="semibold">{t('create_contract.titles.nft')}</Typography>
        <div className="flex flex-col gap-8">
          <ShowMore localMax={10} data={nfts} buttonLabel={t('show_more')}>
            {(nft, index) => (
              <NftComponent
                values={values}
                nft={nft}
                index={index}
                templates={templates}
                quantityModifiable={quantityModifiable}
                errors={errors}
                resetField={resetField}
                register={register}
                setValue={setValue}
                control={control}
                key={index}
                dirtyFields={dirtyFields}
                fields={fields}
                watch={watch}
                doesExpire={doesExpire}
              />
            )}
          </ShowMore>
          <Button action={addCategory} disabled={isUnlimited}>
            {template.labels?.add_category[language] ||
              t('create_contract.form_labels.add_category')}
          </Button>
        </div>
      </div>

      <div className="mt-20 flex justify-center space-x-16">
        <div className="w-[17rem]">
          <Button id="cc-step-1" action={onBack} type="secondary">
            {t('create_contract.form_labels.back') as string}
          </Button>
        </div>
        <div className="w-[17rem]">
          <Button
            id="cc-step-1"
            action={() => {
              if (!maxSizeReached(125000000)) {
                onCompleted();
              }
            }}
            disabled={isLoading || contractsLeft < 1 || !isImageValid || howManyNotarizations > 1}
          >
            {t('create_contract.form_labels.next') as string}
          </Button>
        </div>
      </div>
    </>
  );
};

export default NftsStep;
