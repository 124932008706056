import { Button, Icon } from '../../../../components';
import { IconFastForward, IconLeft } from '../../../../icons';

interface PageSliderProps {
  page: number;
  numberOfPages: number;
  setPage: (value: number) => void;
}

const PageSlider = ({ page, numberOfPages, setPage }: PageSliderProps) => {
  //const pageToDisplay = Array.from({ length: numberOfPages }, (v, i) => i);
  //const pageToDisplay = Array.from({ length: 10 }, (v, i) => i);
  // handle page numbers in page selection
  const pageToDisplay = Array.from({ length: numberOfPages <= 5 ? numberOfPages : 5 }, (_, i) =>
    page <= 3
      ? i + 1
      : page + 2 <= numberOfPages
        ? i + page - 2
        : page < 5
          ? i + numberOfPages - 3
          : i + numberOfPages - 4
  );
  // console.log('page', page);
  // console.log('numberOfPages', numberOfPages);
  // console.log('pageToDisplay', pageToDisplay);

  return (
    <div className="flex w-fit flex-row items-center justify-between gap-2">
      {numberOfPages > 5 && page !== 0 && (
        <div className="">
          <Button
            action={() => setPage(0)}
            disabled={page <= 0}
            height="25px"
            width="25px"
            className="rounded-[5px] "
            classNameText="!p-0"
          >
            <Icon icon={IconFastForward} size="24" className="rotate-180" stroke="white" />
          </Button>
        </div>
      )}
      <div className="">
        <Button
          action={() => setPage(page - 1)}
          disabled={page <= 0}
          height="25px"
          width="25px"
          className="rounded-[5px] "
          classNameText="!p-0"
        >
          <Icon icon={IconLeft} size="24" stroke="white" />
        </Button>
      </div>
      {pageToDisplay.map((i) => (
        <Button
          key={i}
          action={() => setPage(i - 1)}
          disabled={page === i - 1}
          className="rounded-[5px] "
          classNameText="!p-0"
          height="25px"
          width="25px"
        >
          {i}
        </Button>
      ))}
      <div className="">
        <Button
          action={() => setPage(page + 1)}
          disabled={page >= numberOfPages - 1}
          height="25px"
          width="25px"
          className="rounded-[5px] "
          classNameText="!p-0"
        >
          <Icon icon={IconLeft} size="24" className="rotate-180" stroke="white" />
        </Button>
      </div>
      {numberOfPages > 5 && page !== numberOfPages - 1 && (
        <div className="">
          <Button
            action={() => setPage(numberOfPages - 1)}
            disabled={page >= numberOfPages - 1}
            height="25px"
            width="25px"
            className="rounded-[5px] "
            classNameText="!p-0"
          >
            <Icon icon={IconFastForward} size="24" stroke="white" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default PageSlider;
