import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { Button, Typography } from '..';
import { useLoadingStatusContext } from '../../context/loading/LoadingContext';
import { deleteFolder as deleteNotarizationsFolder } from '../../modules/notarizations/api';
import { deleteFolder as deleteTCFolder } from '../../modules/tokenCreator/api';
import ModalLayout from '../layouts/ModalLayout';

type DeleteFolderModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { folder, folderType } = useModalProps('DeleteFolderModal');
  const { t } = useTranslation(['notarization']);
  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();

  const handleDeleteFolder = async () => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('delete_folder.actions.pending'),
      },
    });

    await (
      folderType === 'notarization'
        ? deleteNotarizationsFolder(folder.id, folder.notarizationsId)
        : deleteTCFolder(folder.id, folder.notarizationsId)
    )
      .then(() => {
        loadingStatusDispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('delete_folder.actions.success'),
          },
        });
      })
      .catch((e) => {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            title: t('delete_folder.actions.failed'),
            message: e.message,
          },
        });
      });
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex size-full flex-col gap-4 px-[20px] pt-[50px]">
        <div data-cy="deleteFolder" className="mt-7">
          <Typography weight="medium" size="body-medium-30" color="primary-500" className="pb-7">
            {t('delete_folder.title')}
          </Typography>
          <Typography size="body-regular-18" color="grey-600">
            {t('delete_folder.delete_folder', { name: folder.name })}
          </Typography>
          <Typography size="body-regular-18" color="grey-600">
            {t('delete_folder.disclaimer')}
          </Typography>
        </div>
        <div className="mt-[20px] flex flex-row justify-end ">
          <Button
            id="deleteFolder"
            type="warning"
            action={() => handleDeleteFolder()}
            loading={state.status === 'pending'}
          >
            {t('delete_folder.submit_data')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const DeleteFolderModal = ({ children }: DeleteFolderModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="DeleteFolderModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default DeleteFolderModal;
